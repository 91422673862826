// Application url routes are contained here.
export const HOME = "/"
export const SEARCH = "/search"
export const INSTRUCTIONS = "/docs"
export const OVERVIEW = "/overview"
export const BIRDS = "/birds"
export const FISH = "/fish"
export const HYDRO = "/hydro"
export const GEO = "/geo"
export const MITIGATION = "/mitigation"
export const TOOLS = "/tools"
export const HFT = "/hft"
export const LAB = "/lab"
export const CONTACT = "/contact"
