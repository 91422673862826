import * as ids from "constants/ids"
import * as paths from "constants/paths"
import * as icons from "constants/icons"

export type ContextBarItemConfig = {
    badge?: {
        color?: string
        content: string
    }
    position?: string
    icon:
        | React.ReactNode
        | {
              default: React.ReactNode
              active?: React.ReactNode
          }
    id: string
    label?: string
    name?: string
    link?: string

    isActive: ((...args: Array<any>) => any) | boolean

    isEnabled?: boolean
    devOnly?: boolean
    betaOnly?: boolean
    userOnly?: boolean
}

/**
 * Returns true if the url matches the item's link.
 *
 * If the item doesn't have a link, check's the item's active status.
 */
function isActive(
    location: { path: string },
    item: ContextBarItemConfig,
    exactMatch: boolean = false
) {
    if (item.link) {
        return exactMatch
            ? location.path === item.link
            : location.path.startsWith(item.link)
    }
    return false
}

// Use us as constants
const devOnly = true
// eslint-disable-next-line no-unused-vars
const betaOnly = true
const userOnly = true

const contextBarItems: ContextBarItemConfig[] = [
    {
        icon: { default: icons.Docs },
        id: ids.HOME,
        label: "Documentation",
        link: paths.INSTRUCTIONS,
        isActive,
    },
    {
        icon: { default: icons.Overview },
        id: ids.OVERVIEW,
        label: "At-a-Glance",
        link: paths.OVERVIEW,
        isActive,
    },
    {
        icon: { default: icons.Geo },
        id: ids.GEO,
        label: "Geographic Scope",
        link: paths.GEO,
        isActive,
    },
    {
        icon: { default: icons.Hydro },
        id: ids.HYDRO,
        label: "Hydrologic Conditions",
        link: paths.HYDRO,
        isActive,
    },
    {
        icon: { default: icons.Birds, active: icons.BirdsActive },
        id: ids.BIRDS,
        label: "Piping Plover & Least Tern",
        link: paths.BIRDS,
        isActive,
    },
    {
        icon: { default: icons.Fish, active: icons.FishActive },
        id: ids.FISH,
        label: "Pallid Sturgeon",
        link: paths.FISH,
        isActive,
    },
    {
        icon: { default: icons.Mitigation },
        id: ids.MITIGATION,
        label: "Mitigation Project Activities",
        link: paths.MITIGATION,
        isActive,
    },
    {
        icon: { default: icons.Tools },
        id: ids.TOOLS,
        label: "Tools",
        link: paths.TOOLS,
        isActive,
    },
    {
        icon: { default: icons.Search },
        id: ids.SEARCH,
        label: "Search",
        link: paths.SEARCH,
        isActive,
    },
    {
        icon: { default: icons.Mail },
        id: ids.MAIL,
        label: "Contact",
        position: "bottom",
        link: paths.CONTACT,
        isActive,
        userOnly,
    },
]

export default contextBarItems
