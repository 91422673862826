// @flow
const styles = {
    available: {},
    notAvailable: {},
    querySection: {
        padding: 6,
    },
    modelessLayoutTable: {
        width: "100%",
        borderCollapse: "collapse",
    },
    statHeaderCell: {
        textAlign: "left",
        paddingBottom: "5px",
        borderBottom: "1px solid #ccc",
    },
    modelessHeader: {
        textTransform: "uppercase",
        margin: "-10px -10px 10px -10px",
        backgroundColor: "#12A4B6",
        color: "#FFF",
        textAlign: "center",
        padding: "10px",
        fontWeight: "bold",
        fontSize: "15px",
    },
    modelessButtonLeft: {
        position: "absolute",
        fontSize: "14px",
        bottom: "0",
        left: "0",
    },
    modelessButtonRight: {
        position: "absolute",
        fontSize: "14px",
        bottom: "0",
        right: "0",
    },
    menuBox: {
        verticalAlign: "top",
    },
    menu: {
        maxHeight: "300px",
        overflowY: "scroll",
        width: "150px",
    },
    middleCell: {
        width: "50px",
    },
    radioGroup: {
        marginLeft: "23px",
    },
    rightSide: {
        marginLeft: "40px",
    },
    menuItem: {
        paddingLeft: "0px",
    },
    menuItemText: {
        fontSize: "13px",
        fontFamily: '"Roboto Condensed", sans-serif',
    },
    radioLabel: {
        marginLeft: "10px",
    },
    radioSecondary: {
        color: "#12A4B6",
    },
}

export default styles
