import * as globalColors from "./colors"
import addColumn from "./add_column"
import attributeTable from "./attribute_table"
import baselayers from "./baselayers"
import breadcrumbs from "./breadcrumbs"
import calculator from "./calculator"
import componentHelp from "./component_help"
import contextBar from "./context_bar"
import contextBarItem from "./context_bar_item"
import digitize from "./geo_bar_digitize"
import geoBar from "./geo_bar"
import geoBarPanel from "./geo_bar_panel"
import geoBarSearch from "./geo_bar_search"
import geoBarTool from "./geo_bar_tool"
import globals from "./globals"
import header from "./header"
import identify from "./identify"
import layers from "./layers"
import layerProperties from "./layer_properties"
import mapToolbar from "./map_toolbar"
import mapToolbarItem from "./map_toolbar_item"
import mapToolbarPanel from "./map_toolbar_panel"
import publicLayer from "./public_layer"
import root from "./root"
import statistics from "./statistics"
import tabbedPanel from "./tabbed_panel"
import queryBuilder from "./query_builder"
import user from "./user"
import userlayers from "./userlayers"

export const colors = globalColors

const styles = {
    ...globals,
    ...root,
    ...addColumn,
    ...attributeTable,
    ...baselayers,
    ...breadcrumbs,
    ...calculator,
    ...componentHelp,
    ...contextBar,
    ...contextBarItem,
    ...digitize,
    ...geoBar,
    ...geoBarPanel,
    ...geoBarSearch,
    ...geoBarTool,
    ...header,
    ...identify,
    ...layers,
    ...layerProperties,
    ...mapToolbar,
    ...mapToolbarItem,
    ...mapToolbarPanel,
    ...publicLayer,
    ...statistics,
    ...tabbedPanel,
    ...queryBuilder,
    ...userlayers,
    ...user,
}

// Key check
const styleObjects = [
    globals,
    root,
    addColumn,
    attributeTable,
    baselayers,
    breadcrumbs,
    calculator,
    contextBar,
    contextBarItem,
    digitize,
    geoBar,
    geoBarPanel,
    geoBarSearch,
    geoBarTool,
    header,
    layers,
    layerProperties,
    mapToolbar,
    mapToolbarItem,
    mapToolbarPanel,
    publicLayer,
    statistics,
    tabbedPanel,
    userlayers,
    user,
]

styleObjects.forEach((so) => {
    Object.keys(so).forEach((k) => {
        // Search for this key
        styleObjects
            .filter((testSO) => testSO !== so)
            .forEach((testSO) => {
                Object.keys(testSO).forEach((testKey) => {
                    if (k === testKey) {
                        console.error(`Found duplicate key ${k}`)
                    }
                })
            })
    })
})

export default styles
