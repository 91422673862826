// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit as fasEdit } from "@fortawesome/free-solid-svg-icons"
import { faEdit as farEdit } from "@fortawesome/free-regular-svg-icons"
import { useDropzone } from "react-dropzone"
import request from "superagent"
import {
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
} from "@material-ui/core"
import buffer from "@turf/buffer"
import OlGeoJSON from "ol/format/GeoJSON"
import OlFeature from "ol/Feature"
import * as OlPolygon from "ol/geom/Polygon"
import OlVectorLayer from "ol/layer/Vector"
import OlVectorSource from "ol/source/Vector"
import { olUtils } from "@owsi/catena/er2_ui"
import stringify from "json-stringify-safe"
import ErrorBoundary from "@owsi/catena/er2_map_userlayers/js/components/error_boundary"
import { nullFunc } from "@owsi/catena/er2_map_userlayers/js/utils"
import Theme from "@owsi/catena/er2_styles"
import type { GeoJSONType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"

// Dropzone styles
const baseStyle = {
    display: "inline-block",
    transition: "border .24s ease-in-out",
}
const activeStyle = { borderColor: "#2196f3" }
const acceptStyle = { borderColor: "#00e676" }
const rejectStyle = { borderColor: "#ff1744" }

export type FilterType = {
    filter: string,
    // If the user must provide a value
    required: boolean,
    value: any,
}

export type AOIstate = {
    aoiFeature: GeoJSONType,
    method: string,
    knownBoundaries: string[],
    knownBoundaryType: string,
    knownBoundaryId: string,
}

type Props = {
    er2Map: any,
    // Additional string entries that add to search
    extraFilters?: FilterType[],
    // Set to true if the UI should be hidden but the current feature should still be rendered
    hidden?: boolean,
    includeKnownBoundaries?: boolean,
    initialState?: AOIstate,
    // pair of known boundary type and display string
    knownBoundaryOptions?: [string, string][],
    olstyle?: {},
    onCancel?: Function,
    onSelect: Function,
    shapes?: string[],
    theme: Theme,
    toolLabel?: string,
}

function getDrawPromptMessage(msgType) {
    switch (msgType) {
        case "DRAW_POINT":
            return "Click to draw a point on the map"
        case "DRAW_LINE":
            return "Click to begin drawing a line on the map"
        case "DRAW_POLYGON":
            return "Click to begin drawing a polygon on the map"
        case "DRAW_RECTANGLE":
            return "Click to begin drawing a rectangle on the map"
        default:
            return "Click to draw a point on the map"
    }
}

function AOISelect(props: Props) {
    const [bufferRadius, setBufferRadius] = React.useState(1)
    const [bufferUnits, setBufferUnits] = React.useState("miles")
    const [drawInteraction, setDrawInteraction] = React.useState()
    const [drawPromptRendered, setDrawPromptRendered] = React.useState(true)
    const [extraFilters, setExtraFilters] = React.useState({})
    const [features, setFeatures] = React.useState([])
    const [fetchError, setFetchError] = React.useState(false)
    const [
        fetchingKnownBoundaries,
        setFetchingKnownBoundaries,
    ] = React.useState(false)
    const [knownBoundaries, setKnownBoundaries] = React.useState([])
    const [knownBoundaryId, setKnownBoundaryId] = React.useState("")
    const [knownBoundaryType, setKnownBoundaryType] = React.useState("")
    const [method, setMethod] = React.useState("")
    const [vectorLayer, setVectorLayer] = React.useState()

    const onDrawShape = React.useCallback(
        (evt) => {
            vectorLayer.getSource().clear()
            let feat = evt.feature
            if (
                (method === "DRAW_POINT" || method === "DRAW_LINE") &&
                bufferRadius
            ) {
                // Replace geometry with buffered value
                const geojson = new OlGeoJSON()
                const featGeo = geojson.writeFeatureObject(feat, {
                    featureProjection: "EPSG:3857",
                    dataProjection: "EPSG:4326",
                })
                const buffered = buffer(featGeo, bufferRadius, {
                    units: bufferUnits,
                })
                feat = geojson.readFeatureFromObject(buffered, {
                    featureProjection: "EPSG:3857",
                    dataProjection: "EPSG:4326",
                })
            }
            setFeatures([feat])
            setDrawInteraction(null)
        },
        [bufferRadius, bufferUnits, vectorLayer],
    )

    function activateDrawTool(drawToolSelected) {
        if (drawInteraction && drawToolSelected === method) {
            // Assume this is a deactivate
            setDrawInteraction(null)
        } else {
            if (drawToolSelected === "DRAW_POINT") {
                setDrawInteraction(olUtils.makeDrawPointInteraction())
            } else if (drawToolSelected === "DRAW_LINE") {
                setDrawInteraction(olUtils.makeDrawLineInteraction())
            } else if (drawToolSelected === "DRAW_POLYGON") {
                setDrawInteraction(olUtils.makeDrawPolygonInteraction())
            } else if (drawToolSelected === "DRAW_BOX") {
                setDrawInteraction(olUtils.makeDrawBoxInteraction())
            } else if (drawToolSelected === "DRAW_RECTANGLE") {
                // Aliasing RECTANGLE and BOX since rectangle seems like something nobody would really want to use.
                setDrawInteraction(olUtils.makeDrawBoxInteraction())
                // setDrawInteraction(olUtils.makeDrawRectangleInteraction())
            } else if (drawToolSelected === "DRAW_CIRCLE") {
                setDrawInteraction(olUtils.makeDrawCircleInteraction())
            }
            setMethod(drawToolSelected)
        }
    }

    function fetchKnownBoundaries(boundaryType) {
        if (boundaryType) {
            const extent = props.er2Map.olmap
                .getView()
                .calculateExtent(props.er2Map.olmap.getSize())
            const extentFeat = new OlFeature({
                geometry: OlPolygon.fromExtent(extent),
            })
            setFetchingKnownBoundaries(true)

            let error = false
            const payload = {
                extent: olUtils.writeFeature(extentFeat),
                boundaryType,
            }
            fetch("/er2_map/api/v1/known-boundaries/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: stringify(payload),
            })
                .then((response) => {
                    error = !response.ok
                    return response.json()
                })
                .then((json) => {
                    setFetchingKnownBoundaries(false)
                    if (error) {
                        setFetchError(true)
                    } else {
                        const valueField = boundaryType
                        setKnownBoundaries(
                            json.items
                                .map((item) => ({
                                    ...item,
                                    value: item[valueField] || item.name,
                                }))
                                .sort(),
                        )
                    }
                })
        } else {
            setKnownBoundaries([])
        }
    }

    function fetchBoundary(boundaryId) {
        if (vectorLayer && boundaryId) {
            const vs = vectorLayer.getSource()
            let error = false
            const url = `/er2_map/api/v1/known-boundary/${knownBoundaryType}/${boundaryId}/`
            fetch(url)
                .then((response) => {
                    error = !response.ok
                    return response.json()
                })
                .then((json) => {
                    if (error) {
                        setFeatures([])
                        setFetchError(true)
                    } else {
                        const olFeats = olUtils.readFeatures(json.geojson)
                        setFeatures(olFeats)
                    }
                })
        }
    }

    function getAOIState() {
        return {
            // limit precision to 10 because there is a slight difference between the OL features coordinate
            //   values and the geojson writer.
            aoiFeature: olUtils.writeFeatures(features, { decimals: 10 }),
            method,
            knownBoundaries,
            knownBoundaryType,
            knownBoundaryId,
        }
    }

    React.useEffect(() => {
        if (props.initialState) {
            if (props.initialState.aoiFeature) {
                setFeatures(olUtils.readFeatures(props.initialState.aoiFeature))
            }
            if (props.initialState.knownBoundaries) {
                setKnownBoundaries([...props.initialState.knownBoundaries])
            }
            if (props.initialState.knownBoundaryId) {
                setKnownBoundaryId(props.initialState.knownBoundaryId)
            }
            if (props.initialState.knownBoundaryType) {
                setKnownBoundaryType(props.initialState.knownBoundaryType)
            }
            if (props.initialState.method) {
                setMethod(props.initialState.method)
            }
        }
    }, [props.initialState])

    React.useEffect(() => {
        setExtraFilters(props.extraFilters)
    }, [props.extraFilters])

    React.useEffect(() => {
        const vectorSource = new OlVectorSource({
            projection: "EPSG:3857",
        })
        const vl = new OlVectorLayer({
            name: "AOIselect",
            source: vectorSource,
            style: olUtils.getOlStyles(props.olstyle),
            zIndex: 1,
        })

        props.er2Map.olmap.addLayer(vl)
        props.er2Map.olmap.render()
        setVectorLayer(vl)

        return () => {
            props.er2Map.olmap.removeLayer(vl)
        }
    }, [])

    React.useEffect(() => {
        if (vectorLayer) {
            const vs = vectorLayer.getSource()
            vs.clear()
            if (features.length > 0) {
                vs.addFeatures(features)
                const extent = vs.getExtent()
                props.er2Map.olmap
                    .getView()
                    .fit(extent, props.er2Map.olmap.getSize())
            }
        }
    }, [features, vectorLayer])

    React.useEffect(() => {
        if (features.length > 0) {
            const aoiState = getAOIState()
            // Make sure state has actually changed
            if (
                JSON.stringify(aoiState) !== JSON.stringify(props.initialState)
            ) {
                props.onSelect(aoiState)
            }
        }
    }, [features])

    React.useEffect(() => {
        if (drawInteraction) {
            drawInteraction.on("drawend", (evt) => onDrawShape(evt))
            props.er2Map.olmap.addInteraction(drawInteraction)
            return () => {
                props.er2Map.olmap.removeInteraction(drawInteraction)
            }
        }
        return nullFunc
    }, [drawInteraction, props.er2Map, onDrawShape])

    React.useEffect(() => {
        // If no shapes, then this is boundary only mode
        if (!props.shapes) {
            setMethod("KNOWN_BOUNDARY")
        }
    }, [])

    const onChangeBoundary = (e) => {
        setKnownBoundaryId(e.target.value)
        fetchBoundary(e.target.value)
    }

    const onReloadBoundaries = () => {
        fetchKnownBoundaries(knownBoundaryType)
    }

    const onSetMethod = (evt) => {
        const newMethod = evt.target.value
        vectorLayer.getSource().clear()
        setMethod(newMethod)
        activateDrawTool(newMethod)
    }

    const onSetBoundaryType = (e) => {
        setKnownBoundaryType(e.target.value)
        fetchKnownBoundaries(e.target.value)
    }

    function updateFilter(filter, value) {
        setExtraFilters({ ...extraFilters, [filter]: value })
    }

    function renderNotifications() {
        const drawMessage = method ? getDrawPromptMessage(method) : ""
        return (
            <div>
                <Snackbar
                    open={!drawPromptRendered}
                    autoHideDuration={6000}
                    onClose={() => setDrawPromptRendered(true)}
                    message={<span id="message-id">{drawMessage}</span>}
                />
                <Snackbar
                    open={fetchError}
                    autoHideDuration={6000}
                    onClose={() => setFetchError(false)}
                    message={
                        <span id="message-id">Error performing operation</span>
                    }
                />
            </div>
        )
    }

    function renderButtons() {
        return (
            <React.Fragment>
                <div>
                    {props.onCancel && (
                        <Button
                            classes={{
                                root: props.theme.buttonSubmit,
                            }}
                            onClick={() => props.onCancel()}
                        >
                            <span>Cancel</span>
                        </Button>
                    )}
                    {fetchingKnownBoundaries && (
                        <span>
                            <FontAwesomeIcon icon="spinner" spin /> Searching...
                        </span>
                    )}
                </div>
            </React.Fragment>
        )
    }

    function renderKnownBoundaryControls() {
        const hasKnownBoundaries = knownBoundaries.length > 0
        const sort = (a, b) => {
            if (a.name < b.name) return -1
            else if (a.name > b.name) return 1
            return 0
        }

        if (method === "KNOWN_BOUNDARY") {
            return (
                <React.Fragment>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel htmlFor={"KNOWN_BOUNDARY_TYPE"}>
                                Type of Boundary
                            </InputLabel>
                            <Select
                                autoWidth
                                value={knownBoundaryType}
                                inputProps={{
                                    name: "KNOWN_BOUNDARY_TYPE",
                                    id: "KNOWN_BOUNDARY_TYPE",
                                }}
                                onChange={onSetBoundaryType}
                            >
                                {props.knownBoundaryOptions.map(
                                    ([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {hasKnownBoundaries && (
                        <div
                            style={{
                                display: "flex",
                                flexFlow: "row nowrap",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel htmlFor={"KNOWN_BOUNDARIES"}>
                                    Boundary
                                </InputLabel>
                                <Select
                                    value={knownBoundaryId}
                                    inputProps={{
                                        name: "KNOWN_BOUNDARIES",
                                        id: "KNOWN_BOUNDARIES",
                                    }}
                                    onChange={onChangeBoundary}
                                >
                                    {knownBoundaries
                                        .slice()
                                        .sort(sort)
                                        .map((bnd) => (
                                            <MenuItem
                                                key={bnd.oid}
                                                value={bnd.oid}
                                            >
                                                {bnd.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <IconButton
                                onClick={onReloadBoundaries}
                                size={"small"}
                                title={
                                    "Reload list of boundaries based on the current map extent."
                                }
                            >
                                <FontAwesomeIcon icon={"redo"} />
                            </IconButton>
                        </div>
                    )}
                </React.Fragment>
            )
        }
        return null
    }

    const handleBufferRadiusChange = (evt) => {
        setBufferRadius(evt.target.value)
        activateDrawTool(method)
    }

    function renderDrawFeatureControls() {
        return (
            <div>
                {(method === "DRAW_POINT" || method === "DRAW_LINE") && (
                    <div>
                        <FormControl
                            style={{
                                marginBottom: 10,
                                marginRight: 30,
                                width: 100,
                            }}
                        >
                            <TextField
                                id="buffer"
                                label="buffer"
                                onChange={handleBufferRadiusChange}
                                value={bufferRadius}
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor={"buffer-units"}>
                                Units
                            </InputLabel>
                            <Select
                                autoWidth
                                inputProps={{
                                    name: "buffer-units",
                                    id: "buffer-units",
                                }}
                                onChange={(evt) =>
                                    setBufferUnits(evt.target.value)
                                }
                                value={bufferUnits}
                            >
                                <MenuItem value={"miles"}>Miles</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                )}
            </div>
        )
    }

    const onDrop = (acceptedFiles) => {
        // Get the features and the envelope
        const req = request.post("/er2_map/api/v1/get_geojson/")
        acceptedFiles.forEach((file) => req.attach(file.name, file))
        req.then((resp) => {
            const json = JSON.parse(resp.text)
            const olFeats = olUtils.readFeatures(json.geojson)
            const vs = vectorLayer.getSource()
            vs.clear()
            setFeatures(olFeats)
            setDrawInteraction(null)
        })
    }
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
    })

    function renderUpload() {
        const style = {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }

        return (
            <div
                style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: method === "UPLOAD" ? "block" : "none",
                    margin: 6,
                }}
            >
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <Button variant={"contained"}>Import</Button>
                </div>
            </div>
        )
    }

    function renderTool() {
        const { theme } = props
        const titleCase = (s) =>
            `${s[0].toUpperCase()}${s.substr(1).toLowerCase()}`
        const hasDrawTool = method !== "KNOWN_BOUNDARY" && method !== "UPLOAD"
        return (
            <div
                style={{
                    display: props.hidden ? "none" : "block",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "space-between",
                    }}
                >
                    {props.shapes && (
                        <FormControl fullWidth>
                            <InputLabel htmlFor={"digitize"}>
                                {props.toolLabel}
                            </InputLabel>
                            <Select
                                inputProps={{
                                    name: "digitize",
                                    id: "digitize",
                                }}
                                onChange={onSetMethod}
                                value={method}
                            >
                                {props.shapes.map((s) => (
                                    <MenuItem
                                        key={`DRAW_${s}`}
                                        value={`DRAW_${s}`}
                                    >
                                        Draw a {titleCase(s)}
                                    </MenuItem>
                                ))}
                                {props.includeKnownBoundaries && (
                                    <MenuItem
                                        key={"KNOWN_BOUNDARY"}
                                        value={"KNOWN_BOUNDARY"}
                                    >
                                        Known Boundaries
                                    </MenuItem>
                                )}
                                <MenuItem value={"UPLOAD"}>
                                    User-Supplied Layer
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    {hasDrawTool && method && (
                        <IconButton
                            onClick={() => activateDrawTool(method)}
                            size={"small"}
                        >
                            <FontAwesomeIcon
                                icon={drawInteraction ? fasEdit : farEdit}
                            />
                        </IconButton>
                    )}
                </div>
                {renderUpload()}
                {props.includeKnownBoundaries &&
                    renderKnownBoundaryControls(theme)}
                {method !== "KNOWN_BOUNDARY" &&
                    renderDrawFeatureControls(theme)}
                {props.extraFilters.map((f) => (
                    <TextField
                        id={f.filter}
                        key={f.filter}
                        label={f.filter}
                        type="search"
                        margin="normal"
                        onChange={(e) => updateFilter(f.filter, e.target.value)}
                        value={extraFilters[f.filter]}
                    />
                ))}
                {renderButtons(theme)}
            </div>
        )
    }

    return (
        <ErrorBoundary>
            <div className={props.theme.geoBarCtr}>
                {renderTool()}
                {renderNotifications()}
            </div>
        </ErrorBoundary>
    )
}

AOISelect.defaultProps = {
    extraFilters: [],
    hidden: false,
    includeKnownBoundaries: true,
    initialState: {
        knownBoundaryType: "",
        knownBoundaryId: "",
        knownBoundaries: [],
        method: "",
    },
    knownBoundaryOptions: [
        ["states", "States"],
        ["counties", "Counties"],
        ["cities", "Cities"],
        ["huc8", "HUC 8-digit Watersheds"],
        ["huc10", "HUC 10-digit Watersheds"],
        ["huc12", "HUC 12-digit Watersheds"],
    ],
    olstyle: {},
    onCancel: false,
    shapes: ["RECTANGLE", "LINE", "POINT", "POLYGON", "CIRCLE"],
    toolLabel: "Select Area of Interest",
}

export default AOISelect
