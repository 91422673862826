// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import CalcStyles from "@owsi/catena/er2_styles/calculator"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

type PropsType = {
    column: string,
    onCalculate: Function,
    onClose: Function,
    open: boolean,
    schema: {
        properties: {},
    },
    theme: CalcStyles,
}

type StateType = {
    formula: string,
    functionType: string,
}

class Calculator extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props)
        this.state = {
            functionType: "math",
            formula: "",
        }
    }

    clickFunction = (func) => {
        const el = document.getElementById(
            `calc-formula-input-${this.props.column}`,
        )
        const startIndex = el.selectionStart
        const endIndex = el.selectionEnd

        const prefix = this.state.functionType === "string" ? "." : "Math."
        const funcString = `${prefix + func}(`
        const newFormula = `${
            this.state.formula.substr(0, startIndex) +
            funcString +
            this.state.formula.substr(startIndex, endIndex)
        })${this.state.formula.substr(endIndex)}`

        this.setState({
            formula: newFormula,
        })
    }

    clickAttribute = (attr) => {
        const el = document.getElementById(
            `calc-formula-input-${this.props.column}`,
        )
        const startIndex = el.selectionStart
        const endIndex = el.selectionEnd
        const attrString = `[${attr}]`
        const newFormula =
            this.state.formula.substr(0, startIndex) +
            attrString +
            this.state.formula.substr(endIndex)
        this.setState({
            formula: newFormula,
        })
    }

    addOperator = (op) => {
        const el = document.getElementById(
            `calc-formula-input-${this.props.column}`,
        )
        const startIndex = el.selectionStart
        const endIndex = el.selectionEnd
        const newFormula =
            this.state.formula.substr(0, startIndex) +
            op +
            this.state.formula.substr(endIndex)
        this.setState({
            formula: newFormula,
        })
    }

    handleFunctionChange = (event) => {
        this.setState({
            functionType: event.target.value,
        })
    }

    handleFormulaChange = (event) => {
        this.setState({
            formula: event.target.value,
        })
    }

    onAccept = () => {
        this.props.onCalculate(this.state.formula)
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {
        const mathFunctions = [
            "abs",
            "acos",
            "asin",
            "atan",
            "ceil",
            "cos",
            "exp",
            "floor",
            "log",
            "min",
            "max",
            "pow",
            "random",
            "round",
            "sin",
            "sqrt",
            "tan",
        ]
        const stringFunctions = [
            "substr",
            "toLowerCase",
            "toString",
            "toUpperCase",
            "trim",
        ]

        const functions =
            this.state.functionType === "string"
                ? stringFunctions
                : mathFunctions

        const funcList = functions.map((f) => (
            <ListItem
                key={f}
                button
                classes={{
                    root: this.props.theme.menuItem,
                }}
                onClick={() => {
                    this.clickFunction(f)
                }}
            >
                <ListItemText
                    inset
                    primary={f}
                    className={this.props.theme.menuItem}
                    classes={{
                        root: this.props.theme.menuItem,
                        primary: this.props.theme.menuItemText,
                    }}
                />
            </ListItem>
        ))

        const colList = Object.entries(this.props.schema.properties).map(
            ([k, v]) => (
                <ListItem
                    key={k}
                    button
                    classes={{
                        root: this.props.theme.menuItem,
                    }}
                    onClick={() => {
                        this.clickAttribute(k)
                    }}
                >
                    <ListItemText
                        inset
                        primary={k}
                        className={this.props.theme.menuItem}
                        classes={{
                            root: this.props.theme.menuItem,
                            primary: this.props.theme.menuItemText,
                        }}
                    />
                </ListItem>
            ),
        )

        return (
            <Dialog open={this.props.open} onClose={this.onClose}>
                <DialogTitle>
                    Calculator for Column {this.props.column}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <table className={this.props.theme.modelessLayoutTable}>
                            <tbody>
                                <tr>
                                    <td>Layer Attributes</td>
                                    <td
                                        className={this.props.theme.middleCell}
                                    />
                                    <td>Functions</td>
                                </tr>
                                <tr>
                                    <td className={this.props.theme.menuBox}>
                                        <List
                                            component="nav"
                                            className={this.props.theme.menu}
                                        >
                                            {colList}
                                        </List>
                                    </td>
                                    <td />
                                    <td className={this.props.theme.menuBox}>
                                        <List
                                            component="nav"
                                            className={this.props.theme.menu}
                                        >
                                            {funcList}
                                        </List>
                                    </td>
                                </tr>
                                <tr>
                                    <td />
                                    <td />
                                    <td>
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("+")
                                            }}
                                        >
                                            +
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("-")
                                            }}
                                        >
                                            -
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("*")
                                            }}
                                        >
                                            *
                                        </IconButton>
                                        <br />
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("/")
                                            }}
                                        >
                                            /
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("^")
                                            }}
                                        >
                                            ^
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                this.addOperator("()")
                                            }}
                                        >
                                            ()
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td />
                                    <td />
                                    <td>
                                        <FormControl
                                            component="fieldset"
                                            required
                                        >
                                            <RadioGroup
                                                aria-label="function"
                                                className={
                                                    this.props.theme.radioGroup
                                                }
                                                value={this.state.functionType}
                                                onChange={
                                                    this.handleFunctionChange
                                                }
                                            >
                                                <FormControlLabel
                                                    classes={{
                                                        label: this.props.theme
                                                            .radioLabel,
                                                    }}
                                                    value="math"
                                                    control={
                                                        <Radio
                                                            classes={{
                                                                colorSecondary: this
                                                                    .props.theme
                                                                    .radioSecondary,
                                                            }}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Math"
                                                />
                                                <FormControlLabel
                                                    classes={{
                                                        label: this.props.theme
                                                            .radioLabel,
                                                    }}
                                                    value="string"
                                                    control={
                                                        <Radio
                                                            classes={{
                                                                colorSecondary: this
                                                                    .props.theme
                                                                    .radioSecondary,
                                                            }}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="String"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <TextField
                                            id="full-width"
                                            label={`Formula for Field ${this.props.column}`}
                                            InputProps={{
                                                id: `calc-formula-input-${this.props.column}`,
                                            }}
                                            placeholder="Formula"
                                            fullWidth
                                            multiline
                                            margin="normal"
                                            onChange={this.handleFormulaChange}
                                            value={this.state.formula}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onAccept}>Accept</Button>
                    <Button onClick={this.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default Calculator
