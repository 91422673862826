// @flow
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import {
    getUniqueLayerName,
    getVectorLayers,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import RenderNotifications from "./tool_notifications"
import { getActiveTool } from "../reducers/geo_bar"
import MultiLayerSelect from "@owsi/catena/er2_ui/components/geo_bar_muilti_layer_selection"

type Props = {
    actions: {
        onClickInlineHelp: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitUnion: Function,
        onGeoBarSelectLayer: Function,
    },
    mapsources: object,
    theme: {},
}

function isValid(tool) {
    return tool.layers.length > 1
}

function GeoBarUnion(props: Props) {
    const tool = getActiveTool(props)
    const theme = props.theme

    const [outputName, setOutputName] = React.useState("union.json")

    const union = () => {
        const [basename, ext] = splitExt(outputName)
        const unionOutputName = getUniqueLayerName(props.mapsources, basename)
        props.actions.onGeoBarSubmitUnion(tool.layers, unionOutputName)
    }

    const onFocus = (e) => e.target.select()

    function renderTool() {
        const layers = getVectorLayers(props.mapsources).filter(
            (l) => l.row_count > 0,
        )
        return (
            <React.Fragment>
                <div className={theme.toolRow}>
                    <MultiLayerSelect
                        layers={layers}
                        inputLabel={"Layers to union"}
                        {...props}
                    />
                </div>
                <div className={theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <Button
                        color={"primary"}
                        disabled={!isValid(tool) || tool.isFetching}
                        onClick={union}
                        variant={"contained"}
                    >
                        {!tool.isFetching && <span>Union</span>}
                        {tool.isFetching && (
                            <span>
                                Unioning&nbsp;
                                <FontAwesomeIcon icon={"spinner"} spin />
                            </span>
                        )}
                    </Button>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className={theme.geoBarCtr}>
            {renderTool(theme)}
            <RenderNotifications {...props} />
        </div>
    )
}

export default GeoBarUnion
