export const USER_ERROR = "USER_ERROR"
export const USER_ERROR_HANDLED = "USER_ERROR_HANDLED"
export const USER_MESSAGE = "USER_MESSAGE"
export const USER_MESSAGE_HANDLED = "USER_MESSAGE_HANDLED"
export const CHANGE_INPUT = "CHANGE_INPUT"
export const CLICK_HELP_INLINE = "CLICK_HELP_INLINE"
export const CLICK_GEO_BAR_ITEM = "CLICK_GEO_BAR_ITEM"
export const FETCH_ERROR = "FETCH_ERROR"
export const POST_CLICK_GEO_BAR_ITEM = "POST_CLICK_GEO_BAR_ITEM"
export const CHANGE_MAPTOOLBAR_OPACITY = "CHANGE_MAPTOOLBAR_OPACITY"
export const CLICK_MAP_TOOLBAR_ITEM = "CLICK_MAP_TOOLBAR_ITEM"
export const GEO_BAR_CLICK = "GEO_BAR/CLICK"
export const GEO_BAR_CHANGE_SEARCH_VALUE = "GEO_BAR/CHANGE_SEARCH_VALUE"
export const GEO_BAR_FETCH_ERROR_RENDERED = "GEO_BAR/FETCH_ERROR_RENDERED"
export const GEO_BAR_SELECT_LAYER = "GEO_BAR/SELECT_LAYER"
export const GEO_BAR_SELECT_TOOL = "GEO_BAR/SELECT_TOOL"
export const GEO_BAR_SET_SEARCH_COMPONENT = "GEO_BAR/SET_SEARCH_COMPONENT"
export const GEO_BAR_FETCHING_OPERATION = "GEO_BAR/FETCHING_OPERATION"
export const GEO_BAR_FETCHED_OPERATION = "GEO_BAR/FETCHED_OPERATION"
export const GEO_BAR_SUGGESTIONS_CLEAR_REQUESTED =
    "GEO_BAR/SUGGESTIONS_CLEAR_REQUESTED"
export const GEO_BAR_SUGGESTIONS_FETCH_REQUESTED =
    "GEO_BAR/SUGGESTIONS_FETCH_REQUESTED"
export const GEO_BAR_RESULT_RENDERED = "GEO_BAR/RESULT_RENDERED"
export const POST_CLICK_MAP_TOOLBAR_ITEM = "POST_CLICK_MAP_TOOLBAR_ITEM"
export const REGISTRATION_RECEIVE = "REGISTRATION/RECEIVE"
export const REMOVE_RECEIVE = "REMOVE/RECEIVE"
export const USER_UPDATE = "USER/UPDATE"
export const VALIDATION_ERROR = "VALIDATION_ERROR"
export const VALIDATION_ERROR_INVALID_FIELD = "VALIDATION_ERROR_INVALID_FIELD"
