// @flow
import moment from "moment"
import type { AttributeTableType } from "../attribute_table"

/**
 * Keeps track of table state when an edit is applied or undone.
 */
class EditManager {
    /**
     *
     * @param rows Table rows
     * @param schema Table properties
     * @param header Defines column order.
     */
    constructor(rows: AttributeTableType[], schema, header) {
        // Create new references to each row's items.
        this.rows = rows.map((r) => ({ ...r }))
        // This could take lots of memory, so I am disabling this for now.
        // this.rows = rows
        this.schema = schema
        this.header = header

        // Current list of edits. State reflects the application of all the edits.
        this.edits = []
        this.redo_edits = []
    }

    /**
     * Add the cell edit to the list of edits that will be sent when the table is saved.
     */
    addCellEdit(id, columnName, value) {
        // Make sure that the new value is valid
        let saveValue = value
        if (this.schema.properties[columnName].indexOf("int") === 0) {
            saveValue = value !== "" ? parseInt(value) : ""
        } else if (this.schema.properties[columnName].indexOf("float") === 0) {
            saveValue = value !== "" ? parseFloat(value) : ""
        } else if (this.schema.properties[columnName].indexOf("date") === 0) {
            let fmt = "YYYY-MM-DD"
            if (value.indexOf("/") >= 0) {
                fmt = "M/D/YYYY"
            }
            const mdate = moment.utc(value, fmt)
            saveValue = mdate.format("YYYY-MM-DD")
        }

        let edit
        const row = this.rows.find((r) => r.id === id)
        if (row) {
            const prevValue = row[columnName]
            if (prevValue !== saveValue) {
                edit = this.pushEdit(
                    "change_value",
                    {
                        id,
                        columnName,
                        prevValue,
                        value: saveValue,
                    },
                    this.rows,
                )
            }
        }
        return edit
    }

    addColumn(name, datatype) {
        return this.pushEdit("add_column", {
            name,
            datatype,
        })
    }

    deleteColumn(name) {
        return this.pushEdit("delete_column", {
            name,
        })
    }

    deleteRows(deletedIds) {
        const edit = this.pushEdit(
            "delete_rows",
            {
                deletedIds,
            },
            this.rows,
        )
        return edit
    }

    editColumn(oldName, newName, datatype) {
        return this.pushEdit("edit_column", {
            oldName,
            newName,
            datatype,
        })
    }

    renameColumn(name, newName) {
        return this.pushEdit("rename_column", {
            name,
            newName,
        })
    }

    fieldCalculate(columnName, formula) {
        return this.pushEdit("field_calculate", {
            formula,
            columnName,
        })
    }

    cancelEdits() {
        this.edits = []
    }

    hasEdits() {
        return this.edits.length > 0
    }

    /**
     * Edit object factory
     * @param operation: defines the type of edit
     * @param properties: properties of the edit
     * @param rows: Current row data
     * @param schema
     * @param header
     * @returns {*}
     */
    pushEdit(operation, properties) {
        // We want to expose the editManager object to the edit item because the editManager will always have
        //   the current table state.
        const editManager = this
        const getCellEditItem = () => ({
            // Change the row_data to show this edit.  Return true if the edit was made to the current page.
            //   if row_data is not provided, then use the model's row_data
            apply() {
                this.setEdit(properties.value)
            },
            // Set the row_data corresponding to this item's position to its original value
            undo() {
                this.setEdit(properties.prevValue)
            },
            setEdit(value) {
                const row = editManager.rows.find((f) => f.id === properties.id)
                if (row) {
                    row[properties.columnName] = value
                }
            },
        })

        const getDeleteRowsItem = () => ({
            deleteRow(rowId) {
                const irow = this.rows.findIndex((f) => f.id === rowId)
                if (irow >= 0) {
                    const deletedRows = editManager.rows.splice(irow, 1)
                    this.deletedRow = deletedRows[0]
                    this.id = rowId
                } else {
                    console.error(`Cannot find row ${rowId} to delete`)
                }
            },

            undeleteRows() {
                console.error(`undeleting row ${this.deletedRow.id}`)
            },

            // Change the row_data to show this edit.  Return true if the edit was made to the current page.
            //   if row_data is not provided, then use the model's row_data
            apply() {
                properties.deletedIds.forEach((r) => this.deleteRow(r))
            },
            // Set the row_data corresponding to this item's position to its original value
            undo() {
                this.undeleteRows()
            },
            id: null,
            deletedRow: null,
        })

        const getAddColumnItem = () => ({
            apply() {
                const newProps = { ...editManager.schema.properties }
                newProps[properties.name] = properties.datatype
                editManager.schema = {
                    ...editManager.schema,
                    properties: {
                        ...newProps,
                    },
                }
                editManager.header = [...editManager.header, properties.name]
            },
            undo() {
                alert("not implemented")
            },
        })

        const getDeleteColumnItem = () => ({
            apply() {
                const newProps = { ...editManager.schema.properties }
                delete newProps[properties.name]
                editManager.schema = {
                    ...editManager.schema,
                    properties: {
                        ...newProps,
                    },
                }
                editManager.header = editManager.header.filter(
                    (h) => h !== properties.name,
                )
            },
            undo() {
                alert("not implemented")
            },
        })

        const getEditColumnItem = () => ({
            apply() {
                if (properties.newName !== properties.oldName) {
                    const newProps = { ...editManager.schema.properties }
                    newProps[properties.newName] = properties.datatype
                    delete newProps[properties.oldName]
                    editManager.schema = {
                        ...editManager.schema,
                        properties: {
                            ...newProps,
                        },
                    }
                    const i = editManager.header.indexOf(properties.oldName)
                    editManager.header.splice(i, 1, properties.newName)
                }
                editManager.schema.properties[properties.newName] =
                    properties.datatype
                editManager.rows.forEach((r) => {
                    let val = r[properties.oldName]
                    if (val !== undefined && val !== null) {
                        if (properties.datatype === "float") {
                            val = parseFloat(r[properties.oldName])
                        } else if (properties.datatype === "int") {
                            val = parseInt(r[properties.oldName])
                        } else if (properties.datatype === "str") {
                            val = r[properties.oldName].toString()
                        }
                    }
                    r[properties.newName] = val
                    if (properties.newName !== properties.oldName) {
                        delete r[properties.oldName]
                    }
                })
            },
            undo() {
                alert("not implemented")
            },
        })

        const getRenameColumnItem = () => ({
            apply() {
                const newProps = { ...editManager.schema.properties }
                newProps[properties.newName] = newProps[properties.name]
                delete newProps[properties.name]
                editManager.schema = {
                    ...editManager.schema,
                    properties: {
                        ...newProps,
                    },
                }
                editManager.header = editManager.header.map((h) =>
                    h === properties.name ? properties.newName : h,
                )
                editManager.rows.forEach((r) => {
                    r[properties.newName] = r[properties.name]
                    delete r[properties.name]
                })
            },
            undo() {
                alert("not implemented")
            },
        })
        const getFieldCalculationItem = () => ({
            apply() {
                const formula = properties.formula
                    .replace(/\[/g, 'r["')
                    .replace(/\]/g, '"]')
                const isInt = editManager.schema.properties[
                    properties.columnName
                ].includes("int")
                const isFloat = editManager.schema.properties[
                    properties.columnName
                ].includes("float")
                const showError = true
                editManager.rows = editManager.rows.map((r) => {
                    let newValue
                    try {
                        newValue = eval(formula)
                    } catch (error) {
                        if (showError) {
                            alert(error)
                            showError = false
                        }
                        return r
                    }
                    if (isFloat) newValue = parseFloat(newValue)
                    else if (isInt) newValue = parseInt(newValue)
                    r[properties.columnName] = newValue
                    return r
                })
            },
            undo() {
                alert("not implemented")
            },
        })

        let edit
        if (operation === "change_value") {
            edit = getCellEditItem()
        } else if (operation === "add_column") {
            edit = getAddColumnItem()
        } else if (operation === "delete_column") {
            edit = getDeleteColumnItem()
        } else if (operation === "edit_column") {
            edit = getEditColumnItem()
        } else if (operation === "rename_column") {
            edit = getRenameColumnItem()
        } else if (operation === "delete_rows") {
            edit = getDeleteRowsItem()
        } else if (operation === "field_calculate") {
            const formula = properties.formula
                .replace(/\[/g, 'r["')
                .replace(/\]/g, '"]')
            const isInt = editManager.schema.properties[
                properties.columnName
            ].includes("int")
            const isFloat = editManager.schema.properties[
                properties.columnName
            ].includes("float")
            let showError = true
            editManager.rows.forEach((r, i) => {
                let newValue
                try {
                    newValue = eval(formula)
                } catch (error) {
                    if (showError) {
                        alert(error)
                        showError = false
                    }
                }
                if (isFloat) newValue = parseFloat(newValue)
                else if (isInt) newValue = parseInt(newValue)
                this.addCellEdit(i, properties.columnName, newValue)
            })
        } else {
            alert(`Warning: ${operation} is not handled`)
        }

        if (edit) {
            Object.assign(edit, properties)
            edit.operation = operation

            // Apply the edit to the current data.
            const event = edit.apply()
            this.edits.push(edit)
        }
        return edit
    }

    saveEdits() {
        const ret = [...this.edits]
        this.edits = []
        return ret
    }

    undoLastEdit() {
        const lastEdit = this.edits.pop()
        if (lastEdit) {
            lastEdit.undo()
            this.redo_edits.push(lastEdit)
        }
    }

    undoEdits() {
        this.edits.forEach((e) => e.undo())
        this.redo_edits = this.edits
    }
}

export default EditManager
