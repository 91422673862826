const styles = {
    contextBarItemCtr: {
        cursor: "pointer",
    },
    contextBarItem: {
        "&:hover": {
            backgroundColor: (props) =>
                props.colors?.secondaryColor || props.secondaryColor,
            color: (props) =>
                props.colors?.contrastTextColor || props.contrastTextColor,
        },
        "&:focus": {
            outline: "none",
        },
        color: (props) => props.colors?.textColor || props.textColor,
        cursor: "pointer",
        display: "block",
        fontSize: "20px",
        padding: 10,
        textAlign: "center",
        textDecoration: "none",
    },
    disabled: {
        opacity: 0.3,
    },
}

const extendedStyles = {
    ...styles,
    contextBarItemActive: {
        ...styles.contextBarItem,
        backgroundColor: (props) =>
            props.colors?.secondaryColor || props.secondaryColor,
    },
    contextBarItemDisabled: {
        ...styles.contextBarItem,
        opacity: 0.5,
    },
}

export default extendedStyles
