// @flow
import * as types from "../constants/action_types"

export const onChangeMapToolbarOpacity = (opacity) => ({
    type: types.CHANGE_MAPTOOLBAR_OPACITY,
    opacity,
})

export const onPostClickMapToolbarItem = (id: string, state) => ({
    type: types.POST_CLICK_MAP_TOOLBAR_ITEM,
    id,
    state,
})

export const onClickMapToolbarItem = (id: string) => (dispatch, getState) => {
    dispatch({ type: types.CLICK_MAP_TOOLBAR_ITEM, id })
    dispatch(onPostClickMapToolbarItem(id, getState()))
}
