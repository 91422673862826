import { combineParameters } from "@storybook/client-api"
import React from "react"

import { useConfiguration } from "./ConfigurationProvider"
import type { StoreItem, DecoratorFunction, StoryFn } from "./_types"

export const StoryRenderer: React.FC<{
    story: StoryFn | StoreItem
    extraDecorators?: DecoratorFunction[]
    [key: string]: any
}> = React.memo(({ story, extraDecorators, ...rest }) => {
    const { defaultDecorators, decorateStoryFn } = useConfiguration()
    const [baseContext, setBaseContext] = React.useState<Object | null>(null)

    if (!story) {
        throw new Error("The story property of StoryRenderer is missing")
    }

    const unboundStoryFn =
        typeof story === "function" ? story : story.unboundStoryFn

    const BoundStory = React.useMemo(
        () =>
            decorateStoryFn(unboundStoryFn, [
                ...(extraDecorators || []),
                ...defaultDecorators,
            ]),
        [unboundStoryFn, defaultDecorators, extraDecorators, decorateStoryFn]
    )

    const renderContext = React.useMemo(
        () => (baseContext ? combineParameters(baseContext, rest) : null),
        [baseContext, rest]
    )

    React.useEffect(() => {
        let current = true

        load()

        return () => {
            current = false
        }

        async function load() {
            let context: Object

            console.debug(
                `[Folio] loading story "${
                    (story as StoreItem).id || "legacy"
                }" ...`
            )

            if (typeof story === "function") {
                // story should already be bound
                context = {}
            } else {
                context = await story.applyLoaders()
            }

            if (current) {
                setBaseContext(context)
            }
        }
    }, [story])

    if (renderContext) {
        console.debug(
            `[Folio] rendering story "${
                (story as StoreItem).id || "legacy"
            }" ...`
        )
    }

    return renderContext
        ? React.createElement(
              BoundStory as React.FunctionComponent,
              renderContext as any
          )
        : null
})

export default StoryRenderer
