// @flow
import * as React from "react"
import { withRouter } from "react-router-dom"
import Badge from "@material-ui/core/Badge"
import { parseLocation } from "@owsi/catena/er2_map_userlayers/js/utils"
import TokenNavLink from "./token_nav_link"

export type ContextBarItemType = {
    // badge takes second priority
    badge?: {
        content: string,
        color?: string,
        title?: string,
    },
    // Content takes priority
    content?: any,
    // Last is icon.
    icon: { active?: string, default: string },
    id: string,
    isActive?: Function,
    isEnabled?: boolean,
    link?: string,
    name: string,
}

function generateClassNames(props, renderedTheme, isActive) {
    return `${props.theme[props.item.id] ? props.theme[props.item.id] : ""}
        ${
            isActive
                ? props.theme.contextBarItemActive
                : props.theme.contextBarItem
        }`
}

type Props = {
    enabled: boolean,
    idx: number,
    item: ContextBarItemType,
    location: { pathname: string, search: string },
    onClick: Function,
    theme: { active: string, contextBarItemCtr: string },
    token?: { value: string },
}

// Represents a button or link in the Context Bar
function ContextBarItem(props: Props) {
    const renderedTheme = props.theme
    const parsedLoc = parseLocation(props.location)
    const isActive =
        props.item.isActive && props.item.isActive(parsedLoc, props.item)

    // Builds css class definitions for the TokenNavLink
    const classNames = generateClassNames(props, renderedTheme, isActive)
    let iconClass
    if (props.item.icon) {
        iconClass =
            isActive && props.item.icon.active
                ? props.item.icon.active
                : props.item.icon.default
    }

    // Returns true if the provided string is a Font Awesome or Mapskin icon
    const isIconClass = (str) =>
        str &&
        typeof str === "string" &&
        ["fa", "ms"].includes(str.substr(0, 2))

    const getIcon = () =>
        isIconClass(iconClass) ? (
            <i className={iconClass} aria-hidden="true" />
        ) : (
            iconClass
        )

    // Check for font-awesome icon in badge. Otherwise just use the provided string.
    const badgeIcon = (badge) =>
        isIconClass(badge) ? (
            <i className={badge} style={{ fontSize: "larger" }} />
        ) : (
            badge
        )

    const getBadge = () =>
        props.item.badge && props.item.badge.content ? (
            <Badge
                badgeContent={badgeIcon(props.item.badge.content)}
                color={props.item.badge.color || "primary"}
                title={props.item.badge.title}
            >
                {getIcon()}
            </Badge>
        ) : (
            <span>{getIcon()}</span>
        )

    // ----------
    const getContent = () => {
        if (props.item.content) {
            return props.item.content
        }
        return getBadge()
    }

    const linkActive = props.item.link && props.enabled

    return (
        <div
            className={`${renderedTheme.contextBarItemCtr} ${
                props.theme[props.item.containerTheme]
            }`}
            role="button"
            tabIndex={props.idx}
            title={props.item.label}
            onClick={() => props.onClick(props.item.id, isActive)}
        >
            {linkActive && (
                <TokenNavLink
                    className={classNames}
                    to={props.item.link}
                    token={props.token}
                >
                    {getContent()}
                </TokenNavLink>
            )}
            {!linkActive && (
                <div
                    className={`${props.theme.iconCtr} ${
                        props.item.link ? props.theme.disabled : ""
                    }`}
                >
                    {getContent()}
                </div>
            )}
        </div>
    )
}

export default withRouter(ContextBarItem)
