import * as mapActionTypes from "@owsi/catena/er2_map_userlayers/js/constants/action_types"
import * as actionTypes from "constants/action_types"
import { mapLayers } from "app/components/MapUtils"
import { getInMemoryLayers, getLayerMetadata } from "app/x/geoMap/MatchLayers"

export type ProjectDataType = {
    IMS_SITE: string
    images: string
    loaded: Boolean
    map: {}
    mapLayer: string[]
}

const initialState: ProjectDataType = {
    IMS_SITE: "loading",
    images: "",
    loaded: false,
    map: {},
    mapLayer: [mapLayers.MissouriRiverInvert.id, mapLayers.MajorDams.id],
}

export default function project(state = initialState, action: any) {
    let ret = state
    if (action.type === actionTypes.SET_FETCHED_STATE) {
        if (
            action.state.appState &&
            action.state.appState.IMS_SITE !== undefined
        ) {
            ret = { ...state, IMS_SITE: action.state.appState.IMS_SITE }
        }
    } else if (action.type === mapActionTypes.UPDATE_MAP_STATE) {
        const inMemoryLayers = getInMemoryLayers(action.state.mapsources)

        const visibleLayers = [] as string[]
        inMemoryLayers.forEach((inMemoryLayer) => {
            const metadata = getLayerMetadata(inMemoryLayer)
            if (metadata && metadata.id && inMemoryLayer.visible) {
                visibleLayers.push(metadata.id)
            }
        })
        ret = { ...state, mapLayer: visibleLayers, loaded: true }
    } else if (action.type === actionTypes.SET_IMAGE) {
        ret = { ...state, images: action.images }
    } else if (action.type === actionTypes.SET_MAP_DATA) {
        ret = { ...state, map: action.map_data }
    } else if (action.type === actionTypes.SET_MRRP_MAP_LAYER_LIST) {
        ret = { ...state, mapLayer: action.mrrpMapLayer }
    }
    return ret
}
