import * as actions from "app/store/actions"
import { parseLocation } from "er2_web_utils"
import React, { useCallback, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { selectCurrentToken, selectIsFetching } from "app/store/selectors"
import { useActions } from "./index"
import { simpleFetch, simpleFetchAction } from "../store/actions/simple_fetch"
import { CurrentUser } from "../components/UserContext"
import { Snack } from "app/components/SnackContext"
import { toArray } from "app/services/arrayTools"

/**
 * Fetch the application state once, on initial load.
 * i.e. when dispatch or history instance changes for
 * a new store or new window.
 */
const useSynchronizedState = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const parsedLoc = parseLocation(history.location)
        dispatch(actions.fetchState(parsedLoc))
    }, [dispatch, history])
}

/**
 * Django has SESSION_EXPIRE_AT_BROWSER_CLOSE that will logout users,
 * but chrome can save session data when closed and prevent this from
 * happening. To workaround this, listen for browser close and trigger
 * a logout.
 */
const useBrowserCloseToLogout = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            const logout = (event) => {
                window.removeEventListener("beforeunload", logout)
                dispatch(actions.onLogout())
            }
            return () => {
                console.log("removing beforeunload")
                window.removeEventListener("beforeunload", logout)
            }
        }
    }, [dispatch])
}

/**
 * When fetching, the next token, or history changes
 * make sure history has the token as a param
 * @param nextToken the token value
 */
const useTokenAsUrlParam = (nextToken) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const fetching = useSelector(selectIsFetching)

    useEffect(() => {
        if (!fetching && nextToken) {
            const parsedLoc = parseLocation(history.location)
            const search = new URLSearchParams(parsedLoc.params)
            search.set("token", nextToken)
            history.replace({ ...history.location, search: "?" + search })
            dispatch(actions.filterContextBarItems())
        }
    }, [history, fetching, nextToken, dispatch])
}

/**
 * Whenever called, parse whatever the current history instance's location is.
 * Does not listen for location changes.
 */
export const useParsedLocation = () => {
    const history = useHistory()
    return parseLocation(history.location)
}

/**
 * Get the current server session and make it sure
 * it gets picked.
 * @returns The current token value
 */
export const useSessionState: () => { value: string } = () => {
    const token = useSelector(selectCurrentToken)
    useSynchronizedState()
    useTokenAsUrlParam(token)
    useBrowserCloseToLogout()

    return token
}

/**
 * Create a callback to build URLs with server state token.
 * Changes if the location parameters are different
 * or the token value has updated
 */
export const useUrlBuilder = () => {
    const storedToken = useSelector(selectCurrentToken)

    return useCallback(
        (location?: string | object, hash: string | undefined = undefined) => {
            const nextParams = new URLSearchParams((location as any)?.search)
            nextParams.set("token", storedToken)
            const search = "?" + nextParams
            return typeof location === "object"
                ? { ...location, hash, search }
                : { pathname: location, hash, search }
        },
        [storedToken]
    )
}

export function checkRole(role: string | string[], userContext) {
    const myRoles = userContext.roles
    if (!myRoles || !myRoles.length) return false

    const allowed = toArray(role)
    const authorized =
        myRoles.some((r) => allowed.includes(r)) ||
        myRoles.includes("superuser")

    return authorized
}
