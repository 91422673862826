import { applyMiddleware, compose, createStore } from "redux"
import thunkMiddleware from "redux-thunk"
import * as storybook from "@owsi/catena-storybook"
import rootReducer from "app/store/reducers"

const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createEnhancer = (enhancers) => {
    if (process.env.NODE_ENV !== "production") {
        enhancers.push(storybook.reduxEnhancer)
    }
    return composeEnhancers(...enhancers)
}

const configureStore = () => {
    const middleware = applyMiddleware(thunkMiddleware)

    const store = createStore(rootReducer, createEnhancer([middleware]))

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("app/store/reducers", () => {
                store.replaceReducer(rootReducer)
            })
        }
    }

    return store
}

const store = configureStore()

export default store
