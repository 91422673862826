/**
 * Contains identifiers for every redux action type in the application
 */

export const CLICK_CONTEXT_BAR_ITEM = "CLICK_CONTEXT_BAR_ITEM"
export const CHANGE_CONTEXT_BAR_ITEMS = "CHANGE_CONTEXT_BAR_ITEMS"
export const TOGGLE_CONTEXT_BAR_VISIBILITY = "TOGGLE_CONTEXT_BAR_VISIBILITY"
export const SET_CONTEXT_BAR_VISIBILITY = "SET_CONTEXT_BAR_VISIBILITY"
export const SET_FETCHED_STATE = "STATE/SET_FETCHED"
export const SET_FETCHED_STATE_POST = "STATE/POST_FETCHING"
export const SET_FETCHING_STATE = "STATE/SET_FETCHING"
export const SET_SHOW_CATENA = "STATE/SET_SHOW_CATENA"
export const UPDATE_META = "STATE/UPDATE_META"

export const SET_IMAGE = "SET_IMAGE"
export const SET_MAP_DATA = "SET_MAP_DATA"
export const SET_MRRP_MAP_LAYER_LIST = "SET_MRRP_MAP_LAYER_LIST"

export const SET_BIRDS_ACTIVESTEP = "SET_BIRDS_ACTIVESTEP"
export const SET_FISH_ACTIVESTEP = "SET_FISH_ACTIVESTEP"
export const SET_ATG_ACTIVESTEP = "SET_ATG_ACTIVESTEP"
export const SET_HC_ACTIVESTEP = "SET_HC_ACTIVESTEP"

export const SET_ATG_OBSERVATIONS_DATA = "SET_ATG_OBSERVATIONS_DATA"
export const SET_ATG_HYDROLOGIC_CONDITIONS_DATA =
    "SET_ATG_HYDROLOGIC_CONDITIONS_DATA"

export const SET_BIRD_FETCH_STATUS = "SET_BIRD_FETCH_STATUS"
export const SET_BIRDS_OVERVIEW_DATA = "SET_BIRDS_OVERVIEW_DATA"
export const SET_BIRDS_SNT_DATA = "SET_BIRDS_SNT_DATA"
export const SET_BIRDS_MANAGEMENT_DATA = "SET_BIRDS_MANAGEMENT_DATA"
export const SET_BIRDS_MODELPROJECTIONS_DATA = "SET_BIRDS_MODELPROJECTIONS_DATA"

export const SET_FISH_FETCH_STATUS = "SET_FISH_FETCH_STATUS"
export const SET_FISH_SNT_DATA = "SET_FISH_SNT_DATA"
export const SET_FISH_MANAGEMENT_DATA = "SET_FISH_MANAGEMENT_DATA"
export const SET_FISH_TARGETS_DATA = "SET_FISH_TARGETS_DATA"
export const SET_ATG_PS_TARGETS_DATA = "SET_ATG_PS_TARGETS_DATA"
export const SET_DATA_EXPLORER_DATA = "SET_DATA_EXPLORER_DATA"

export const STORE_BIRD_MODEL_WEB_RUNS = "STORE_BIRD_MODEL_WEB_RUNS"
export const REQUEST_BIRD_MODEL_WEB_RUN = "REQUEST_BIRD_MODEL_WEB_RUN"
export const ACK_BIRD_MODEL_WEB_RUN = "ACK_BIRD_MODEL_WEB_RUN"
