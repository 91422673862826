export * from "@owsi/catena/er2_map_userlayers/js/actions"
export * from "@owsi/catena/er2_map_services/js/actions"
export {
    onChangeInput,
    onClickGeoBarItem,
    onClickMapToolbarItem,
    onGeoBarChangeSearchValue,
    onGeoBarFetchClassifications,
    onGeoBarFetchErrorRendered,
    onGeoBarFetchKnownBoundaries,
    onGeoBarFetchKnownBoundary,
    onGeoBarResultRendered,
    onGeoBarSelectLayer,
    onGeoBarSelectTool,
    onGeoBarSubmitBuffer,
    onGeoBarSubmitCalculate,
    onGeoBarSubmitClip,
    onGeoBarSubmitDigitize,
    onGeoBarSubmitDissolve,
    onGeoBarSubmitIntersect,
    onGeoBarSubmitReclassify,
    onGeoBarSubmitUnion,
    onGeoBarSubmitZonalStats,
    onGeoBarSuggestionsClearRequested,
    onGeoBarSuggestionsFetchRequested,
    setGeoBarFetchedOperation,
    setGeoBarFetchingOperation,
    setGeoBarFetchedOperationError,
} from "@owsi/catena/er2_ui"
export {
    onClickContextBarItem,
    filterContextBarItems,
} from "app/store/actions/context_bar"
export {
    fetchState,
    onLogout,
    updateAppState,
} from "app/store/actions/server_state"

export { onBirdsCBStepChange } from "app/store/actions/birds"
export { onFishCBStepChange } from "app/store/actions/fish"
export {
    onHydroStepChange,
    onOverviewStepChange,
} from "app/store/actions/overview"
export { setEnabledLayers, onTabIndexClick } from "app/store/actions/utils"
export * from "app/store/actions/bird_model"

export const noop = (..._args) => ({ type: "noop" })
