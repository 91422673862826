// @flow
import React from "react"
import {
    useTable,
    useGroupBy,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
} from "react-table"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import Theme from "@owsi/catena/er2_styles"
import {
    getScrollbarSize,
    range,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import ErrorBoundary from "@owsi/catena/er2_map_userlayers/js/components/error_boundary"

export type TableProps = {
    columns: [],
    csvKey?: string,
    data: [],
    // Ending table column index to freeze
    freezeCol?: number,
    height: number,
    onSetCSV?: Function,
    printMode?: boolean,
    theme: Theme,
    BodyCell?: React.Node,
    HeaderCell?: React.Node,
}

const useStyles = makeStyles({
    "sorted-up": {
        borderTop: "1px solid black",
    },
    "sorted-down": {
        borderBottom: "1px solid black",
    },
    "table-wrapper": {
        overflowX: "auto",
        width: "100%",
    },
})

const RTBodyCell = styled(TableCell)({
    overflow: "hidden",
    padding: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
})

const RTHeaderCell = styled(TableCell)({
    overflow: "hidden",
    padding: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
})

const buildRow = (selType) => (arr, currRow) => {
    // concats individual cells into csv format row.
    const c = Array.from(currRow.querySelectorAll(selType))
    const cells = c.map((item) => {
        const ret = [item.innerText].concat(
            range(1, parseInt(item.colSpan)).map((v) => ""),
        )
        return ret
    })
    return [...arr, cells.flat()]
}

export function tableToCSV(tableEl) {
    const thead = tableEl.querySelectorAll("thead")[0]
    const headers = Array.from(thead.querySelectorAll("tr")).reduce(
        buildRow("th"),
        [],
    )

    const tbody = tableEl.querySelectorAll("tbody")[0]
    const rows = Array.from(tbody.querySelectorAll("tr")).reduce(
        buildRow("td"),
        [],
    )

    return [headers, rows]
}

type PrintableTableProps = {
    columns: [],
    data: [],
}

const PrintableTable = React.forwardRef((props: PrintableTableProps, ref) => {
    const instance = useTable({
        columns: props.columns,
        data: props.data,
    })

    const { getTableProps, headerGroups, prepareRow, rows } = instance

    return (
        <div ref={ref}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {rows.map(
                        (row, i) =>
                            prepareRow(row) || (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            ),
                    )}
                </tbody>
            </table>
        </div>
    )
})

function SplitTable(props) {
    const startCol = props.startCol || 0
    const endCol = props.freezeCol !== undefined ? props.freezeCol : 100000
    const { HeaderCell, BodyCell } = props
    return (
        <Table
            {...props.instance.getTableProps()}
            className={props.theme.attributeTable}
            stickyHeader
        >
            <TableHead style={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
                {props.instance.headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers
                            .slice(startCol, endCol + 1)
                            .map((column) => (
                                <HeaderCell
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}
                                    className={`${props.classes.td} ${
                                        column.isSorted
                                            ? column.isSortedDesc
                                                ? props.classes["sorted-down"]
                                                : props.classes["sorted-up"]
                                            : ""
                                    }`}
                                >
                                    {column.render("Header")}
                                </HeaderCell>
                            ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {props.instance.page.map(
                    (row, i) =>
                        props.instance.prepareRow(row) || (
                            <TableRow
                                {...row.getRowProps()}
                                style={{
                                    backgroundColor:
                                        i % 2 === 0
                                            ? "white"
                                            : "rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                {row.cells
                                    .slice(startCol, endCol + 1)
                                    .map((cell) => (
                                        <BodyCell
                                            {...cell.getCellProps([
                                                {
                                                    className:
                                                        cell.column.className,
                                                    style: cell.column.style,
                                                },
                                            ])}
                                            className={props.classes.td}
                                        >
                                            {cell.render("Cell")}
                                        </BodyCell>
                                    ))}
                            </TableRow>
                        ),
                )}
            </TableBody>
        </Table>
    )
}

function ResizableTable(props: TableProps) {
    const printableRef = React.useRef()
    const classes = useStyles()
    const [containerEl, setContainerEl] = React.useState()
    const [headerHeight, setHeaderHeight] = React.useState(0)
    const [paginationHeight, setPaginationHeight] = React.useState(0)
    const [scrollbarHeight, setScrollbarHeight] = React.useState(0)

    const rowHeight = calculateRowHeight()
    const { csvKey, onSetCSV } = props

    const instance = useTable(
        {
            columns: props.columns,
            data: props.data,
            autoResetPage: false,
            autoResetSortBy: false,
            initialState: { pageIndex: 0, pageSize: 1 },
        },
        useSortBy,
        usePagination,
    )

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = instance

    function calculateRowHeight() {
        if (containerEl) {
            const tbody = containerEl.getElementsByTagName("TBODY")[0]
            const containerRows = tbody.getElementsByTagName("TR")
            // If no rows present, use a reasonable default
            return containerRows.length
                ? containerRows[0].getBoundingClientRect().height
                : 30
        }
        return 30
    }

    function calculateTableHeight() {
        if (containerEl) {
            const theads = containerEl.getElementsByTagName("THEAD")
            const h = Math.max(
                ...Array.from(theads).map(
                    (thead) => thead.getBoundingClientRect().height,
                ),
            )
            if (h) {
                setScrollbarHeight(getScrollbarSize().height)
                setHeaderHeight(h)
                const pagination = containerEl.getElementsByClassName(
                    "pagination",
                )
                setPaginationHeight(
                    pagination[0].getBoundingClientRect().height,
                )
            }
        }
    }

    React.useEffect(() => {
        if (printableRef.current) {
            const [columns, rows] = tableToCSV(printableRef.current)
            onSetCSV(csvKey, columns, rows)
        }
    }, [csvKey, onSetCSV])

    React.useEffect(() => {
        let rowsPerPage = 1
        if (props.height && headerHeight && rowHeight) {
            rowsPerPage = Math.floor(
                (props.height -
                    headerHeight -
                    paginationHeight -
                    scrollbarHeight) /
                    rowHeight,
            )
        }
        if (rowsPerPage !== pageSize && rowsPerPage > 0) {
            setPageSize(rowsPerPage)
            gotoPage(0)
        }
    }, [
        gotoPage,
        pageSize,
        props.height,
        headerHeight,
        paginationHeight,
        scrollbarHeight,
        setPageSize,
        rowHeight,
        props.data,
    ])

    React.useEffect(() => {
        calculateTableHeight()
    }, [containerEl])

    if (!headerHeight) {
        calculateTableHeight()
    }

    let tableHeight = headerHeight + scrollbarHeight + pageSize * rowHeight
    // TODO: still having problems getting a good initial set of table height data since it depends on the initial render
    if (tableHeight <= rowHeight) {
        tableHeight += 80
    }

    function renderPrintableTable() {
        return (
            <PrintableTable
                ref={printableRef}
                columns={props.columns}
                data={props.data}
            />
        )
    }

    return (
        <ErrorBoundary>
            <div
                ref={(el) => setContainerEl(el)}
                className={props.theme.attributeTableContainer}
            >
                <div
                    style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        height: tableHeight,
                    }}
                >
                    <div>
                        {props.freezeCol >= 0 && (
                            <SplitTable
                                freezeCol={props.freezeCol}
                                instance={instance}
                                classes={classes}
                                theme={props.theme}
                                BodyCell={props.BodyCell}
                                HeaderCell={props.HeaderCell}
                            />
                        )}
                    </div>
                    <div className={classes["table-wrapper"]}>
                        <div>
                            <SplitTable
                                startCol={props.freezeCol + 1}
                                instance={instance}
                                classes={classes}
                                theme={props.theme}
                                BodyCell={props.BodyCell}
                                HeaderCell={props.HeaderCell}
                            />
                        </div>
                    </div>
                </div>
                <TablePagination
                    className={"pagination"}
                    component="div"
                    count={props.data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onChangePage={(event, newPage) => {
                        gotoPage(newPage)
                    }}
                    rowsPerPageOptions={[pageSize]}
                />
            </div>
            {props.printMode && renderPrintableTable()}
        </ErrorBoundary>
    )
}

ResizableTable.defaultProps = {
    csvKey: undefined,
    freezeCol: -1,
    onSetCSV: undefined,
    printMode: false,
    HeaderCell: RTHeaderCell,
    BodyCell: RTBodyCell,
}

export default ResizableTable
