import ReactGA from "react-ga"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect } from "react"

const GA_UID = process.env.REACT_APP_ANALYTICS_TOKEN ?? "UA-37319903-2"

export const useAnalyticsPageview = (enabled) => {
    const location = useLocation()
    const project = useSelector((it) => it.project)

    useEffect(() => {
        if (enabled) {
            ReactGA.initialize(GA_UID)
        }
    }, [enabled])

    useEffect(() => {
        if (enabled) {
            ReactGA.event({
                category: project.IMS_SITE,
                action: "pageview",
                label: location.pathname,
            })
            ReactGA.pageview(location.pathname + location.search)
        }
    }, [enabled, location, project.IMS_SITE])
}
