// @flow
import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fab, Tooltip } from "@material-ui/core"
import Theme from "@owsi/catena/er2_styles"

export const windowStates = {
    minimized: "minimized",
    opened: "opened",
    maximized: "maximized",
}

export type WindowStateType = {
    windowState: "minimized" | "opened" | "maximized",
}

type Props = {
    windowState: WindowStateType,
    onChangeWindowState: Function,
    theme: Theme,
    tocVisible: boolean,
    type: "contextBar" | "south" | "header",
}

function WindowState(props: Props) {
    const rootClassNames = []
    let closerClass1
    let closerClass2
    let title1
    let title2

    let buttonPlacement
        if (props.windowState === windowStates.minimized) {
            buttonPlacement = {top: -25}
        } else if (props.windowState === windowStates.maximized) {
            buttonPlacement = {top: +5}
        } else {
            buttonPlacement = {}
        }

    const onChangeState = (clickedCloserClass) => {
        let newState
        if (clickedCloserClass.indexOf("minus") >= 0)
            newState = windowStates.opened
        else if (clickedCloserClass.indexOf("caret-down") >= 0)
            newState = windowStates.minimized
        else if (clickedCloserClass.indexOf("caret-up") >= 0)
            newState = windowStates.maximized
        if (!newState)
            console.error(`Invalid window state for ${clickedCloserClass}`)
        props.onChangeWindowState(newState)
    }

    if (props.type === "south") {
        rootClassNames.push(props.theme.closerHorizontal)
        rootClassNames.push(
            props.tocVisible
                ? props.theme.closerContextBarOpen
                : props.theme.closerContextBarClosed,
        )

        if (props.windowState === windowStates.minimized) {
            rootClassNames.push(props.theme.closerMinimized)
            closerClass1 = "minus"
            closerClass2 = "caret-up"
            title1 = "Open panel"
            title2 = "Maximize panel"
        } else if (props.windowState === windowStates.opened) {
            rootClassNames.push(props.theme.closerNormal)
            closerClass1 = "caret-down"
            closerClass2 = "caret-up"
            title1 = "Minimize panel"
            title2 = "Maximize panel"
        } else if (props.windowState === windowStates.maximized) {
            rootClassNames.push(props.theme.closerMaximized)
            closerClass1 = "caret-down"
            closerClass2 = "minus"
            title1 = "Minimize panel"
            title2 = "Open Panel"
        }
    } else if (props.type === "contextBar") {
        rootClassNames.push(props.theme.closerVertical)
        if (props.windowState === windowStates.opened) {
            closerClass1 = "caret-left"
        } else {
            closerClass1 = "caret-right"
            rootClassNames.push(props.theme.startLeft)
        }
    } else if (props.type === "header") {
        rootClassNames.push(
            props.theme.closerVertical,
            styles.closerVerticalRight,
        )
        if (props.windowState === windowStates.opened) {
            closerClass1 = "caret-up"
        } else {
            closerClass1 = "caret-down"
            rootClassNames.push(props.theme.startUp)
        }
    }

    return (
        <div>
            <div
                role="button"
                tabIndex="-1"
                className={rootClassNames.join(" ")}
                style={buttonPlacement}
            >
                <Tooltip title={title1}>
                    <Fab
                        className={props.theme.closerButton}
                        color={"default"}
                        onClick={() => onChangeState(closerClass1)}
                        size={"small"}
                        style={{ marginRight: 5 }}
                    >
                        <FontAwesomeIcon icon={closerClass1} />
                    </Fab>
                </Tooltip>
                {closerClass2 && (
                    <Tooltip title={title2}>
                        <Fab
                            className={props.theme.closerButton}
                            color={"default"}
                            onClick={() => onChangeState(closerClass2)}
                            size={"small"}
                        >
                            <FontAwesomeIcon icon={closerClass2} />
                        </Fab>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

WindowState.defaultProps = {
    theme: {},
}

export default WindowState
