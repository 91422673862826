// @flow
import { createReducer } from "@reduxjs/toolkit"
import { actionTypes as uiActionTypes } from "@owsi/catena/er2_ui"
import { ExtentType } from "@owsi/catena/er2_map/src/map"
import * as actionTypes from "../constants/action_types"

export type LayerExtentType = {
    bbox_3857: ExtentType,
    bbox_4326: ExtentType,
}

export type ActionsType = {}

export type ViewType = {
    center: Array<number>,
    resolution: number,
    rotation: number,
    extent: ExtentType,
    // Extend er2_map's ViewType to store the mapScale.
    mapScale: number,
}

export type MapType = {
    apiKeys: {},
    baseLayer: String,
    error: string,
    extent?: ExtentType,
    icons: {},
    mapView: ViewType,
    message: string,
    needsMapExtentUpdate: boolean,
    needsMapViewUpdate: boolean,
    showMapToolbar: boolean,
}

type MapActionType = {
    type: string,
    id: string,
    extent?: ExtentType,
    mapView?: ViewType,
    showMapToolbar?: boolean,
}

const initialMapView = {
    center: [0, 0],
    // default to CONUS
    extent: [-13847031, 453778, -7451726, 8862612],
    resolution: 0,
    rotation: 0,
    mapScale: 0,
}

export const initialState = {
    apiKeys: {},
    baseLayer: "OpenStreetMap",
    mapView: initialMapView,
    needsMapExtentUpdate: false,
    needsMapViewUpdate: false,
    showMapToolbar: true,
}

const map = createReducer((initialState: MapType), {
    [actionTypes.UPDATE_MAP_STATE]: (state, action) => {
        const newstate = { ...state, ...action.state.map }
        if (action.state.map.mapView) {
            newstate.needsMapViewUpdate = true
        } else if (action.state.map.extent) {
            newstate.needsMapExtentUpdate = true
        }
        return newstate
    },
    [actionTypes.CHANGE_MAPSCALE]: (state, action) => {
        state.mapView.mapScale = action.mapScale
    },
    [actionTypes.CHANGE_BASELAYER]: (state, action) => {
        state.baseLayer = action.baseLayer
    },
    [actionTypes.MAP_ERROR_CLEAR]: (state, action) => {
        state.error = null
    },
    [actionTypes.MAP_ERROR_SET]: (state, action) => {
        state.error = action.error
    },
    [actionTypes.MAP_MESSAGE_CLEAR]: (state, action) => {
        state.message = null
    },
    [actionTypes.MAP_MESSAGE_SET]: (state, action) => {
        state.message = action.message
    },
    [actionTypes.SET_MAP_EXTENT]: (state, action) => {
        state.extent = action.extent
        state.needsMapExtentUpdate = true
    },
    [actionTypes.SET_MAP_EXTENT_HANDLED]: (state, action) => {
        state.needsMapExtentUpdate = false
    },
    [actionTypes.SET_MAP_VIEW_HANDLED]: (state, action) => {
        state.needsMapViewUpdate = false
    },
    [actionTypes.SHOW_MAP_TOOLBAR]: (state, action) => {
        state.showMapToolbar = action.show
    },
    [actionTypes.UPDATE_MAP_VIEW]: (state, action) => {
        // Unset extent to prevent the map from trying to keep zooming to the extent.
        state.extent = null
        state.mapView = action.mapView
        state.needsMapViewUpdate = true
    },
})

export default map
