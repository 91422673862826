import * as actionTypes from "constants/action_types"

export const storeRuns = (list) => ({
    type: actionTypes.STORE_BIRD_MODEL_WEB_RUNS,
    payload: list,
})

export const requestRunStart = (model) => async (dispatch, getState) => {
    const { meta, token } = getState()
    const query = token ? `?token=${token.value}` : ""

    // TODO(srichard): I changed this back to sending the whole modelInput
    // in lue of adding another DB statement to CSIP.
    // Is that ideal?

    console.log("Request Run Start in bird_model.ts", {
        model,
        modelInput: model.modelInput,
    })
    if (!model) {
        throw new Error("Web Run has not been fetched!")
    }

    dispatch({
        type: actionTypes.REQUEST_BIRD_MODEL_WEB_RUN,
        payload: model.pk,
    })

    await fetch(`/${meta.name}/api/v1/run_bird_model/${query}`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            WRID: model.pk,
            BirdModel: model.modelInput,
        }),
    })

    dispatch({
        type: actionTypes.ACK_BIRD_MODEL_WEB_RUN,
        payload: model.pk,
    })
}
