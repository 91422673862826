// @flow
import { colors as defaultColors } from "@owsi/catena/er2_styles"
import { createTheme } from '@material-ui/core/styles'

const muiTheme = (colors, primaryColor, overrides, palette = {}) =>
    createTheme({
        palette: {
            primary: {
                light: colors.primaryOneLighten5,
                main: primaryColor || colors.primaryOne,
                dark: colors.primaryOneDarken5,
            },
            secondary: {
                light: colors.blueLighten5,
                main: colors.blue,
                dark: colors.blueDarken5,
            },
            action: {
                light: colors.primaryOneLighten5,
                main: primaryColor || colors.secondaryTwo,
                dark: colors.primaryOneDarken5,
            },
            error: {
                light: colors.dangerLighten5,
                main: colors.danger,
                dark: colors.dangerDarken5,
            },
            ...palette,
        },
        typography: {
            fontFamily: "'Roboto Condensed', sans-serif",
            useNextVariants: true,
        },
        overrides: overrides || {},
    })

export function addCatenaTypography(partialConfig: Object) {
    const catenaTypography = {
        fontFamily: "'Roboto Condensed', sans-serif",
        "@media (min-width: 1px)": {
            fontFamily: "'Roboto Condensed', sans-serif",
        },
    }

    return {
        ...partialConfig,
        typography: {
            ...partialConfig.typography,
            ...catenaTypography,
        },
    }
}

export function addCatenaPalette(partialConfig: Object, partialColors: Object) {
    const colors = { ...defaultColors, ...partialColors }
    const catenaPalette = {
        type: "light",
        primary: {
            light: colors.primaryLightColor,
            main: colors.primaryColor,
            dark: colors.primaryDarkColor,
            contrastText: "#fff",
        },
        secondary: {
            light: colors.secondaryLightColor,
            main: colors.secondaryColor,
            dark: colors.secondaryDarkColor,
            contrastText: "#000",
        },
        action: {
            light: colors.primaryOneLighten5,
            main: colors.secondaryTwo,
            dark: colors.primaryOneDarken5,
        },
        error: {
            light: colors.dangerLighten5,
            main: colors.danger,
            dark: colors.dangerDarken5,
        },
    }

    return {
        ...partialConfig,
        palette: {
            ...partialConfig.palette,
            ...catenaPalette,
        },
    }
}

export type OptionsType = {
    theme: {},
    palette: {},
    colors: {},
    fontSize: number,
}

export function customMuiTheme(options: OptionsType = {}) {
    if (!options.colors) options.colors = {}
    const colors = { ...defaultColors, ...options.colors }
    const palette = options.palette || {}
    const overrides = options.overrides || {}
    const fontSize = options.fontSize || 14
    const customProps = options.props || {}
    const props = {
        helpComponent: {
            variant: "above",
        },
        ...customProps,
    }

    const themePalette = {
        type: "light",
        primary: {
            light: colors.primaryLightColor,
            main: colors.primaryColor,
            dark: colors.primaryDarkColor,
            contrastText: "#fff",
        },
        secondary: {
            light: colors.secondaryLightColor,
            main: colors.secondaryColor,
            dark: colors.secondaryDarkColor,
            contrastText: "#000",
        },
        action: {
            light: colors.primaryOneLighten5,
            main: colors.secondaryTwo,
            dark: colors.primaryOneDarken5,
        },
        error: {
            light: colors.dangerLighten5,
            main: colors.danger,
            dark: colors.dangerDarken5,
        },
        ...palette,
    }

    const themeData = {
        typography: {
            fontFamily: "'Roboto Condensed', sans-serif",
            "@media (min-width: 1px)": {
                fontFamily: "'Roboto Condensed', sans-serif",
            },
            fontSize,
            useNextVariants: true,
        },
        overrides: {
            MuiAutocomplete: {
                popper: {
                    border: "1px solid lightgray",
                },
            },
            MuiAccordion: {
                root: {
                    "&$expanded": {
                        margin: "0 0 0 0",
                    },
                    "&.expand$expanded:last-child": {
                        marginBottom: 6,
                        marginTop: 6,
                    },
                },
            },
            MuiAccordionDetails: {
                root: {
                    padding: 0,
                },
            },
            MuiAccordionSummary: {
                content: {
                    margin: "0 0 0 0",
                    "&$expanded": {
                        margin: "0 0 0 0",
                    },
                },
                root: {
                    // Fixes increase in height when opened
                    minHeight: "48px",
                    padding: "0 24px 0 10px",
                    "&$expanded": {
                        margin: "0 0 0 0",
                        minHeight: "48px",
                    },
                },
            },
            MuiInput: {
                input: {
                    fontSize,
                },
            },
            MuiInputBase: {
                root: {
                    fontSize,
                },
            },
            MuiInputLabel: {
                formControl: {
                    // transform: "translate(0, 14px) scale(1)",
                },
            },
            MuiMenuItem: {
                root: {
                    fontFamily: "'Roboto Condensed', sans-serif",
                    fontSize,
                    "@media (min-width: 2px)": {
                        fontFamily: "'Roboto Condensed', sans-serif",
                    },
                },
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: "initial",
                    // border: '1px solid gray',
                },
            },
            MuiTab: {
                root: {
                    "@media (min-width: 600px)": {
                        fontSize: "1em",
                    },
                    textTransform: "initial",
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: 12,
                },
            },
            MuiTypography: {
                // For titles in layer expansion panels
                button: {
                    fontSize: "1em",
                    textTransform: "initial",
                },
            },
            ...overrides,
        },
    }

    return createTheme({
        palette: themePalette,
        props,
        ...themeData,
    })
}

export default muiTheme
