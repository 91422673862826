import * as actionTypes from "constants/action_types"

const initialState = {
    activeStep: 0,
    status: false,
    overview: {
        status: false,
        retrieveFile: [],
        annualRunoffs: [],
        RTBirdObjs: [],
        RTBirdHabitat: [],
        RTBirdHypotheses: [],
        StandardizedESH: [],
        AvailableESHA75: [],
        AvailableESHA50: [],
        AvailableESHA25: [],
        AvailableESHA10: [],
        RTBirdMgmtActions: [],
    },
    statusAndTrends: {
        status: false,
        availableESHAcresNorth: [],
        availableESHAcresSouth: [],
        dischargeGarrisonSpecific: [],
        dischargeGarrisonAny: [],
        eshPopulationStatusTable: [],
        eshPopulationStatusResults: [],
        ESHAvailableArea: [],
        ESHSTDAreaImagery: [],
        nestsMoved: [],
        nestsRaised: [],
        nestSuccessFailure: [],
        northernPloverGrowthRate: [],
        northernRatioTargets: [],
        plover2012: [],
        ploverCounts: [],
        ploverGrowthRateTitle: "",
        ploverFledgeNorth: [],
        ploverFledgeSouth: [],
        ploverFledgeType: [],
        ploverNesting: [],
        ploverNestingYearList: [],
        ploverPopNorth: [],
        ploverPopSouth: [],
        ploverPopType: [],
        StandardizedESHAcresNorth: [],
        StandardizedESHAcresSouth: [],
        southernPloverGrowthRate: [],
        southernRatioTargets: [],
        ternCounts: [],
        ternFledgeType: [],
        ploverFledgeRatio: [],
        ternFledgeRatio: [],
        ternNesting: [],
        ternNestingYearList: [],
        ternPopNorth: [],
        ternPopSouth: [],
        ternPopType: [],
        northPiperNestFailure: [],
        southPiperNestFailure: [],
        northTernNestFailure: [],
        southTernNestFailure: [],
        mitigationAcquisitionKansas: [],
        mitigationAcquisitionOmaha: [],
        mitigationAcquisitionTotals: [],
        mitigationAuthorityLands: [],
        mitigationBiennialKansas: [],
        mitigationBiennialIowa: [],
        mitigationBiennialMissouri: [],
        mitigationBiennialNebraska: [],
        mitigationBiennialTotalsKansas: [],
        mitigationBiennialTotalsOmaha: [],
        ModelFitTablePlover: [],
        ModelFitTableTern: [],
        PloverFledgeReservoirAIC: [],
        TernFledgeReservoirAIC: [],
        ploverFledgeWaterElevDataPoints: [],
        ploverFledgeWaterElevModelFit: [],
        ploverFledgeShorelineDataPoints: [],
        PloverFledgeShorelineModelFit: [],
        ternFledgeShorelineDataPoints: [],
        ternFledgeShorelineModelFit: [],
        ShorelineHabitatSakakawea: [],
        ShorelineHabitatOahe: [],
    },
    managementActions: {
        status: false,
        ESHSpraying: [],
        pipingPlover: [],
        leastTern: [],
        humanRestriction: [],
        nestsCaged: [],
        ESHList: [],
        ESHTotal: [],
        PredatorsRemoved: [],
        PredatorManagement: [],
        GarrisonFlowESH: [],
        FortRandallFlowESH: [],
        GavinsPointFlowESH: [],
        standardESHData: [],
        availableESHData: [],
    },
    modelProjections: {
        status: false,
        annualStorage: [],
        ploverDemographics: [],
        ternDemographics: [],
        ESHModel: [],
        InitialConditionsModel: [],
        RTDischargeAreaParameters: [],
        annualRunoffs: [],
        ESHParameterizationData: [],
        modelYearList: [],
        RTBirdScenariosYearList: [],
    },
}

export type BirdType = typeof initialState

export default function birds(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_BIRDS_ACTIVESTEP:
            return {
                ...state,
                activeStep: action.activeStep,
            }
        case actionTypes.SET_BIRDS_OVERVIEW_DATA:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    ...action.output,
                },
            }
        case actionTypes.SET_BIRDS_SNT_DATA:
            return {
                ...state,
                statusAndTrends: {
                    ...state.statusAndTrends,
                    ...action.output,
                },
            }
        case actionTypes.SET_BIRDS_MANAGEMENT_DATA:
            return {
                ...state,
                managementActions: {
                    ...state.managementActions,
                    ...action.output,
                },
            }
        case actionTypes.SET_BIRDS_MODELPROJECTIONS_DATA:
            return {
                ...state,
                modelProjections: {
                    ...state.modelProjections,
                    ...action.output,
                },
            }
        default:
            return state
    }
}
