// Manages state for metadata for the application
import * as actionTypes from "constants/action_types"

export type MetaType = {
    name: string
    version: string
    user: {}
}

const initialState = {
    name: "er2_mrrp",
    // The server will override this in the meta data that comes from the config file.
    version: "",
    user: false,
}

export default function meta(state: MetaType = initialState, action) {
    switch (action.type) {
        case actionTypes.UPDATE_META:
            return {
                ...state,
                version: action.version,
                // Currently the way to check for guest user access is to check the id
                user:
                    action.user && action.user.id !== "anonymous"
                        ? action.user
                        : undefined,
            }

        default:
            return state
    }
}
