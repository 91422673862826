// @flow
import BingMaps from "ol/source/BingMaps"
import XYZ from "ol/source/XYZ"
import OSM from "ol/source/OSM"
import Tile from "ol/layer/Tile"
import * as imageSrc from "../../../er2_map_baselayers/images"
import * as actionTypes from "../constants/action_types"

export type MapBaseLayerType = {
    // Save copy of API keys from map state
    apiKeys: {},
    // Current base layer (value)
    baseLayer: string,
    baseLayerChoices: [
        {
            // Name to display in component and must be unique
            name: string,
            // Icon src
            imageSrc: string,
            // Return an OpenLayers Layer
            create: Function,
        },
    ],
    opacity: number,
}

export type ActionType = {
    type: string,
    baseLayer?: string,
    state: {
        map: {
            baseLayer: string,
        },
    },
}

const makeUSGS = (layerName: string) => {
    const baseURL = "https://basemap.nationalmap.gov/arcgis/rest/services/"
    const url = baseURL + layerName
    return new Tile({
        source: new XYZ({
            url: `${url}/MapServer/tile/{z}/{y}/{x}`,
            crossOrigin: "anonymous",
        }),
    })
}

const makeBingLayer = (layerType: string, apiKey: string) =>
    new Tile({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
            key: apiKey,
            imagerySet: layerType,
        }),
    })

export const initialState = {
    apiKeys: {},
    baseLayer: null,
    baseLayerChoices: [
        {
            name: "OpenStreetMap",
            imageSrc: imageSrc.osmIcon,
            create: () =>
                new Tile({
                    source: new OSM({
                        // Test.
                        // url: 'http://{a-c}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png',
                    }),
                }),
        },
        {
            name: "OpenStreetMap Humanitarian",
            imageSrc: imageSrc.osmIcon,
            create: () =>
                new Tile({
                    source: new OSM({
                        url:
                            "http://{a-b}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
                    }),
                }),
        },
        {
            name: "USGS Imagery",
            imageSrc: imageSrc.usgsImagery,
            create: () => makeUSGS("USGSImageryOnly"),
        },
        {
            name: "USGS Imagery Topo",
            imageSrc: imageSrc.usgsTopo,
            create: () => makeUSGS("USGSImageryTopo"),
        },
        {
            name: "USGS Hydro-NHD",
            imageSrc: imageSrc.usgsHydro,
            create: () => makeUSGS("USGSHydroCached"),
        },
        {
            name: "USGS Shaded Relief",
            imageSrc: imageSrc.usgsShaded,
            create: () => makeUSGS("USGSShadedReliefOnly"),
        },
        {
            name: "Bing Aerial",
            imageSrc: imageSrc.bingAerialIcon,
            create: (apiKeys) => makeBingLayer("Aerial", apiKeys.bing),
        },
        {
            name: "Bing Aerial/Labels",
            imageSrc: imageSrc.bingAerialLabelsIcon,
            create: (apiKeys) =>
                makeBingLayer("AerialWithLabels", apiKeys.bing),
        },
        {
            name: "Bing Road",
            imageSrc: imageSrc.bingRoadIcon,
            create: (apiKeys) => makeBingLayer("Road", apiKeys.bing),
        },
        {
            name: "None",
            imageSrc: imageSrc.noneIcon,
            create: () => null,
        },
    ],
    opacity: 1,
}

function createBaseLayer(baseLayerChoice, apiKeys) {
    let layer
    if (baseLayerChoice) {
        layer = baseLayerChoice.create(apiKeys)
        if (layer) {
            layer.set("baseLayerName", baseLayerChoice.name)
        }
    }
    return layer
}

export default function mapBaseLayer(
    state: MapBaseLayerType = initialState,
    action: ActionType,
) {
    let newstate = state
    if (action.type === actionTypes.UPDATE_MAP_STATE) {
        if (action.state.map) {
            let baseLayerChoices = state.baseLayerChoices
            // Remove bing layer options if no API key is provided.
            if (!action.state.map.apiKeys.bing) {
                baseLayerChoices = baseLayerChoices.filter(
                    (b) => !b.name.includes("Bing"),
                )
            }
            const baseLayerChoice =
                baseLayerChoices.find(
                    (c) => c.name === action.state.map.baseLayer,
                ) || newstate.baseLayerChoices[0]
            newstate = {
                ...state,
                apiKeys: action.state.map.apiKeys,
                baseLayer: createBaseLayer(
                    baseLayerChoice,
                    action.state.map.apiKeys,
                ),
                baseLayerChoices,
                opacity: action.state.map.opacity || 1.0,
            }
        }
    } else if (action.type === actionTypes.CHANGE_BASELAYER) {
        const baseLayerChoice = state.baseLayerChoices.find(
            (c) => c.name === action.baseLayer,
        )
        newstate = {
            ...state,
            baseLayer: createBaseLayer(baseLayerChoice, newstate.apiKeys),
        }
    } else if (action.type === actionTypes.CHANGE_BASELAYER_OPACITY) {
        newstate = {
            ...state,
            opacity: action.opacity,
        }
    }
    if (newstate.baseLayer) {
        newstate.baseLayer.set("opacity", newstate.opacity)
    }
    return newstate
}
