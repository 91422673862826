export const WS_BOOT_USER = "WEBSOCKETS/BOOT_USER"
export const WS_CLOSE_ROOM = "WEBSOCKETS/CLOSE_ROOM"
export const WS_CREATE_ROOM = "WEBSOCKETS/CREATE_ROOM"
export const WS_JOIN_ROOM = "WEBSOCKETS/JOIN_ROOM"
export const WS_LEAVE_ROOM = "WEBSOCKETS/LEAVE_ROOM"
export const WS_MAP_VIEW = "WEBSOCKETS/MAP_VIEW"
export const WS_ROOMS_STATE = "WEBSOCKETS/ROOMS_STATE"
export const WS_RECEIVE_CHAT = "WEBSOCKETS/RECEIVE_CHAT"
export const WS_SEND_CHAT = "WEBSOCKETS/SEND_CHAT"
export const WS_SHARE_LAYERS = "WEBSOCKETS/SHARE_LAYERS"
export const WS_UNSHARE_LAYERS = "WEBSOCKETS/UNSHARE_LAYERS"

// Lifecycle action types
export const WS_PRE_DISPATCH = "WEBSOCKETS/PRE_DISPATCH"
export const WS_POST_DISPATCH = "WEBSOCKETS/POST_DISPATCH"

export const SET_SHARING_LAYERS_SHARABLE = "SHARING/SET_LAYERS_SHARABLE"
export const SET_SHARING_SET_PUSH_MAP_VIEW = "SHARING/SET_PUSH_MAP_VIEW"
