// @flow
// Actions
import * as geoBarActions from "./actions/geo_bar"
import * as mapToolbarActions from "./actions/map_toolbar"
import * as formActions from "./actions/form"
import * as userActions from "./actions/user"
// Components
import Breadcrumbs from "./components/breadcrumbs"
import ContextBar from "./components/context_bar"
import ContextBar2 from "./components/context_bar2"
import GeoBar from "./components/geo_bar"
import GeoSearch from "./components/geo_bar_search"
import type { GeoBarType } from "./reducers/geo_bar"
// Reducers
import geoBarReducer, {
    getActiveTool,
    initialState as geoBarInitialState,
} from "./reducers/geo_bar"
import Header from "./components/header"
import MapToolbar from "./components/map_toolbar"
import MapToolbarPanel from "./components/map_toolbar_panel"
import TokenNavLink from "./components/token_nav_link"
import UserSettings from "./components/user_settings"
// Constants
import * as ids from "./constants/ids"
import * as actionTypes from "./constants/action_types"
// Types
import type {
    MapToolbarActionType,
    MapToolbarItemType,
    MapToolbarType,
} from "./reducers/map_toolbar"
import mapToolbarReducer, {
    initialState as mapToolbarInitialState,
} from "./reducers/map_toolbar"
import type { UserType } from "./reducers/user"
import userReducer from "./reducers/user"
// Utilities
import * as olUtils from "./util/ol"

// Grab what we need from each module
// Actions
const onChangeInput = formActions.onChangeInput
const onChangeMapToolbarOpacity = mapToolbarActions.onChangeMapToolbarOpacity
const onClickInlineHelp = formActions.onClickInlineHelp
const onClickMapToolbarItem = mapToolbarActions.onClickMapToolbarItem
const onClickGeoBarItem = geoBarActions.onClickGeoBarItem
const onGeoBarFetchClassifications = geoBarActions.onGeoBarFetchClassifications
const onGeoBarFetchKnownBoundaries = geoBarActions.onGeoBarFetchKnownBoundaries
const onGeoBarFetchKnownBoundary = geoBarActions.onGeoBarFetchKnownBoundary
const onGeoBarSubmitBuffer = geoBarActions.onGeoBarSubmitBuffer
const onGeoBarSubmitDigitize = geoBarActions.onGeoBarSubmitDigitize
const onGeoBarSubmitDigitizeSave = geoBarActions.onGeoBarSubmitDigitizeSave
const onGeoBarSubmitDissolve = geoBarActions.onGeoBarSubmitDissolve
const onGeoBarSubmitCalculate = geoBarActions.onGeoBarSubmitCalculate
const onGeoBarSubmitClip = geoBarActions.onGeoBarSubmitClip
const onGeoBarSubmitIntersect = geoBarActions.onGeoBarSubmitIntersect
const onGeoBarSubmitReclassify = geoBarActions.onGeoBarSubmitReclassify
const onGeoBarSubmitUnion = geoBarActions.onGeoBarSubmitUnion
const onGeoBarSubmitZonalStats = geoBarActions.onGeoBarSubmitZonalStats
const onGeoBarChangeSearchValue = geoBarActions.onGeoBarChangeSearchValue
const onGeoBarFetchErrorRendered = geoBarActions.onGeoBarFetchErrorRendered
const onGeoBarResultRendered = geoBarActions.onGeoBarResultRendered
const onGeoBarSelectLayer = geoBarActions.onGeoBarSelectLayer
const onGeoBarSelectTool = geoBarActions.onGeoBarSelectTool
const onGeoBarSuggestionsFetchRequested =
    geoBarActions.onGeoBarSuggestionsFetchRequested
const onGeoBarSuggestionsClearRequested =
    geoBarActions.onGeoBarSuggestionsFetchRequested
const setGeoBarFetchingOperation = geoBarActions.setGeoBarFetchingOperation
const setGeoBarFetchedOperationError =
    geoBarActions.setGeoBarFetchedOperationError
const setGeoBarFetchedOperation = geoBarActions.setGeoBarFetchedOperation
const { onLogout, onRegistrationUpdate, onRemoveUser, updateUser } = userActions
// Reducers

export type {
    GeoBarType,
    MapToolbarType,
    MapToolbarItemType,
    MapToolbarActionType,
    UserType,
}

export {
    actionTypes,
    geoBarInitialState,
    geoBarReducer,
    ids,
    getActiveTool,
    olUtils,
    onChangeInput,
    onChangeMapToolbarOpacity,
    onClickGeoBarItem,
    onClickInlineHelp,
    onClickMapToolbarItem,
    onGeoBarChangeSearchValue,
    onGeoBarFetchClassifications,
    onGeoBarFetchErrorRendered,
    onGeoBarFetchKnownBoundaries,
    onGeoBarFetchKnownBoundary,
    onGeoBarResultRendered,
    onGeoBarSelectLayer,
    onGeoBarSelectTool,
    onGeoBarSubmitBuffer,
    onGeoBarSubmitCalculate,
    onGeoBarSubmitClip,
    onGeoBarSubmitDigitize,
    onGeoBarSubmitDissolve,
    onGeoBarSubmitIntersect,
    onGeoBarSubmitReclassify,
    onGeoBarSubmitUnion,
    onGeoBarSubmitZonalStats,
    onGeoBarSuggestionsClearRequested,
    onGeoBarSuggestionsFetchRequested,
    mapToolbarInitialState,
    mapToolbarReducer,
    setGeoBarFetchedOperation,
    setGeoBarFetchingOperation,
    setGeoBarFetchedOperationError,
    onLogout,
    onRegistrationUpdate,
    onRemoveUser,
    updateUser,
    Breadcrumbs,
    ContextBar,
    ContextBar2,
    GeoBar,
    GeoSearch,
    Header,
    MapToolbar,
    MapToolbarPanel,
    TokenNavLink,
    userReducer,
    UserSettings,
}
