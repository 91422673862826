import React from "react"
import { useAppStyles } from "@owsi/catena-vellum"
import { Box, Grow, Paper } from "@material-ui/core"
import { Stack } from "app/components/box/Stack"
import Wave from "react-wavify"

const LoadingWave = ({
    level = 1,
    elevation = 0,
    opacity = 0.8 / level + 0.02 * elevation,
    options = {},
}) => (
    <Wave
        fill={`rgba(88,128,150,${opacity})`}
        paused={false}
        options={{
            height: 50,
            amplitude: 30 + 10 / level + 2 * elevation,
            speed: 0.1 - 0.01 * elevation,
            points: 3,
            ...options,
        }}
        style={{
            width: "100%",
            height: "100%",
        }}
    />
)

export const Loading: React.FC = (props) => {
    const classes = useAppStyles()
    return (
        <Box
            flex="1 1"
            className={classes.loading}
            display="flex"
            alignItems="center"
            justifyContent="center"
            {...props}
        >
            <Grow in>
                <Stack component={Paper} maxWidth={300} maxHeight={300}>
                    <LoadingWave
                        level={3}
                        elevation={0}
                        options={{ points: 5 }}
                    />
                    <LoadingWave
                        level={3}
                        elevation={1}
                        options={{ points: 5 }}
                    />
                    <LoadingWave level={1} elevation={0} />
                    <LoadingWave level={1} elevation={1} />
                    <Box textAlign="center" p={10} color="white">
                        <div
                            style={{
                                fontSize: "20px",
                                margin: "20px",
                            }}
                        >
                            Loading...
                        </div>
                    </Box>
                </Stack>
            </Grow>
        </Box>
    )
}
