/**
 * Contains ids assigned to elements in the DOM.  Useful to store these
 * here because the ids are used in actions and reducers to determine
 * the source of redux actions and ui interactions.
 */
export const HOME = "HOME"
export const SEARCH = "SEARCH"
export const OVERVIEW = "OVERVIEW"
export const GEO = "GEOGRAPHY"
export const HYDRO = "HYDROLOGY"
export const BIRDS = "BIRDS"
export const FISH = "FISH"
export const MITIGATION = "MITIGATION"
export const TOOLS = "TOOLS"
export const LAB = "LAB"
export const MAIL = "MAIL"
