import React from "react"

type myProps = { children: any }

export interface userType {
    username: string
    setUsername: (s: string) => void
    roles: string[]
    setRoles: (sa: string[]) => void
    editMode: boolean
    setEditMode: (b: boolean) => void
}

export const CurrentUser = React.createContext({
    username: "Guest-0",
    setUsername: (s) => null,
    roles: ["Guest"],
    setRoles: (sa) => null,
    editMode: false,
    setEditMode: (b) => null,
} as userType)

export const GuestUser = {
    username: "Guest",
    roles: [],
    editMode: false,
}

export function UserContext(props: myProps) {
    const { children } = props

    const [username, setUsername] = React.useState(GuestUser.username)
    const [roles, setRoles] = React.useState([])
    const [editMode, setEditMode] = React.useState(GuestUser.editMode)

    return (
        <CurrentUser.Provider
            value={{
                username,
                setUsername,
                roles,
                setRoles,
                editMode,
                setEditMode,
            }}
        >
            {children}
        </CurrentUser.Provider>
    )
}
