// @flow
import * as React from "react"
import { NavLink, withRouter } from "react-router-dom"

const { parse, stringify } = require("query-string")

type Props = {
    children: object,
    className: string,
    history: {
        location: {
            hash: string,
        },
    },
    location: { search: {}, token: {} },
    role?: string,
    to: string,
    hash?: string,
    token?: {
        value: string,
        expires: number,
    },
}

// Displays a `NavLink` with an optional session token, if available
function TokenNavLink(props: Props) {
    const params = parse(props.location.search)
    if (props.token) {
        params.token = (props.token && props.token.value) || ""
    }
    const hash = props.hash || ""
    const link = `${props.to}?${stringify(params)}${hash}`
    return (
        <NavLink className={props.className} to={link} role={props.role}>
            {props.children}
        </NavLink>
    )
}

TokenNavLink.defaultProps = {
    role: "button",
    token: undefined,
    hash: undefined,
}

export default withRouter(TokenNavLink)
