// @flow
import React from "react"
import ReactDOM from "react-dom"
import ResizeObserver from "resize-observer-polyfill"
import type { MapType } from "@owsi/catena/er2_map_userlayers/js/reducers/map"
import OlMap from "./ol_map"
import type {
    AnimateConfigType,
    AnimateDataType,
    MapfileDataType,
} from "./layer_animation"
import { useMapsources } from "@owsi/catena/er2_map_userlayers/js/hooks/mapsources_hook"

export type ViewType = {
    center: [number, number], // ol.Coordinate
    resolution: number,
    rotation: number,
    mapScale: number,
}

// [minx, miny, maxx, maxy]
export type ExtentType = [number, number, number, number]

type ClickType = {
    coordinate: [number, number],
}

// Re-exports
// Tweak the name so I can re-export this type
//   https://github.com/facebook/flow/issues/1379
export type LayerAnimationType = AnimateDataType
export type LayerAnimationConfigType = AnimateConfigType
export type MapfileAnimationType = MapfileDataType

type PropType = {
    baseLayer?: string,
    // Map coordinates of the click (3857)
    click?: ClickType,
    extent?: ExtentType,
    layerAnimationData?: AnimateDataType,
    map: MapType,
    mapScale?: number,
    mapExtentUpdateHandled: Function,
    mapViewUpdateHandled: Function,
    needsMapExtentUpdate: boolean,
    needsMapViewUpdate: boolean,
    olMapProps?: {
        hasZoom: false,
    },
    onChangeView?: Function,
    onSingleClick?: Function,
}

const isEmpty = (o) => Object.keys(o || {}).length === 0

const Map = React.forwardRef((props: PropType, container) => {
    const [map, setMap] = React.useState()
    const [oldProps, setOldProps] = React.useState({})
    const { loadingTileCounts } = useMapsources({
        ...props,
        er2Map: map,
    })

    React.useEffect(() => {
        if (map) {
            addMapEvents()
            updateMapFromProps()
        }
    }, [map])

    React.useEffect(() => {
        updateMapFromProps()
        setOldProps(props)
    }, [props])

    const setRef = React.useCallback((node) => {
        if (node !== null) {
            const olmap = new OlMap("map", props.olMapProps)
            const handleResize = () => {
                const dim = node.getBoundingClientRect()
                const mapEl = document.getElementById("map")
                if (mapEl) {
                    mapEl.style.height = dim.height
                    olmap.updateSize()
                }
            }

            // https://github.com/que-etc/resize-observer-polyfill
            const ro = new ResizeObserver(handleResize)
            ro.observe(node)
            handleResize()
            setMap(olmap)
            container.current = node
            node.map = olmap
        }
    }, [])

    function addMapEvents() {
        map.addViewChangedEvent((e: { view: ViewType }) =>
            props.onChangeView ? props.onChangeView(e.view) : {},
        )
        map.addSingleClickEvent((e: { lonlat: [number, number] }) =>
            // TODO: fix 'this' reference
            props.onSingleClick ? props.onSingleClick(e, this) : {},
        )
    }

    function simulateClick(click: ClickType) {
        const pixels = map.olmap.getPixelFromCoordinate(click.coordinate)
        // Not sure how this can be null
        if (pixels) {
            map.simulateEvent(
                "singleclick",
                pixels[0],
                pixels[1],
                container.current.offsetWidth,
                container.current.offsetHeight,
            )
        }
    }

    function updateMapFromProps() {
        if (map) {
            // If there are no previous props and the mapview is valid, then go ahead and update the map extent.
            const updateMap =
                Object.keys(oldProps).length === 0 &&
                props.map.mapView.mapScale > 0
            const changed = (prop) =>
                props[prop] &&
                JSON.stringify(oldProps[prop]) !== JSON.stringify(props[prop])

            if (oldProps.baseLayer !== props.baseLayer || !map.hasBaseLayer()) {
                map.setBaseLayer(props.baseLayer)
            }
            if (changed("click") && props.click) simulateClick(props.click)
            if (changed("mapScale") && props.mapScale) {
                map.setMapScale(props.mapScale)
            }
            if (props.map.needsMapExtentUpdate) {
                map.setExtent(props.map.extent)
                props.mapExtentUpdateHandled()
            }
            if (props.map.needsMapViewUpdate || updateMap) {
                map.setView(props.map.mapView)
                props.mapViewUpdateHandled()
            }
            if (!isEmpty(props.layerAnimationData)) {
                map.animate(props.layerAnimationData)
            } else {
                map.animate()
            }
        }
    }

    /**
     * A placemark is an overlay with extra content management.
     * @param args
     */
    function addPlacemark(...args: any[]) {
        return map.addPlacemark(...args)
    }

    function updatePlacemark(...args: any[]) {
        return map.updatePlacemark(...args)
    }

    function movePlacemarkToTop(...args: any[]) {
        return map.movePlacemarkToTop(...args)
    }

    function removePlacemark(...args: any[]) {
        return removeOverlay(...args)
    }

    function removePlacemarks(...args: any[]) {
        return removeOverlays(...args)
    }

    /**
     * Create a map overlay directly.
     * @param content
     */
    function addOverlay(content: string) {
        // Add to document
        const popupDiv = document.createElement("div")
        ReactDOM.render(<span>{content}</span>, popupDiv)
        map.addOverlay(popupDiv)
    }

    function removeOverlay(...args: any[]) {
        map.removeOverlay(...args)
    }

    function removeOverlays() {
        map.removeOverlays()
    }

    return (
        <div id="map" className={props.theme.map} ref={setRef}>
            <div
                id="mouse-position"
                style={{
                    // backgroundColor: 'rgba(0, 0, 0, 0.5',
                    bottom: 0,
                    color: "black",
                    padding: 10,
                    position: "absolute",
                    right: "100px",
                    textShadow: "1px 1px white",
                    zIndex: 1,
                }}
            />
        </div>
    )
})

Map.defaultProps = {
    baseLayer: "OpenStreetMap",
    click: undefined,
    extent: undefined,
    layerAnimationData: undefined,
    layers: [],
    mapScale: 0,
    olMapProps: {},
    theme: {},

    // Map events default to noop
    onChangeView: () => {},
    onSingleClick: () => {},
}

export default Map
