// @flow
import * as types from "../constants/action_types"
import type { WindowStateType } from "../components/window_state"

export const onSetTabbedPanelKey = (key: string) => ({
    type: types.TABBED_PANEL_KEY,
    key,
})
export const setTabbedPanelWindowState = (windowState: WindowStateType) => ({
    type: types.TABBED_PANEL_WINDOW_STATE,
    windowState,
})
