import mapBaseLayerReducer, {
    initialState as baseInitialState,
} from "@owsi/catena/er2_map_userlayers/js/reducers/mapbaselayer"
import BingMaps from "ol/source/BingMaps"
import Tile from "ol/layer/Tile"

const makeBingLayer = (layerType: string, apiKey: string) =>
    new Tile({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
            key: apiKey,
            imagerySet: layerType,
        }),
    })

// Override the behavior of the map, or disable it here
const mapBaseLayerInitialState = Object.assign({}, baseInitialState, {})

mapBaseLayerInitialState.baseLayerChoices = [
    // {
    //     name: "Bing Aerial",
    //     imageSrc: imageSrc.bingAerialIcon,
    //     create: (apiKeys) => makeBingLayer("Aerial", apiKeys.bing),
    // },
    // {
    //     name: "Bing Aerial/Labels",
    //     imageSrc: imageSrc.bingAerialLabelsIcon,
    //     create: (apiKeys) => makeBingLayer("AerialWithLabels", apiKeys.bing),
    // },
    // {
    //     name: "Bing Road",
    //     imageSrc: imageSrc.bingRoadIcon,
    //     create: (apiKeys) => makeBingLayer("Road", apiKeys.bing),
    // },
    ...baseInitialState.baseLayerChoices.slice(
        0,
        baseInitialState.baseLayerChoices.length - 2
    ),
]

export default function catenaMapBaseLayerReducer(
    initialState = mapBaseLayerInitialState,
    action
) {
    return mapBaseLayerReducer(initialState, action)
}
