// @flow
import React, { useEffect, useRef, useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import Theme from "@owsi/catena/er2_styles"
import * as maptoolbarTypes from "@owsi/catena/er2_ui/constants/action_types"
import * as mapToolbarIds from "@owsi/catena/er2_ui/constants/ids"
import { olUtils } from "@owsi/catena/er2_ui"
import {
    getBaseType,
    getLayer,
    getMapsource,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import ErrorBoundary from "../error_boundary"
import EditManager from "./edit_manager"
import Statistics from "./statistics"
import Calculator from "./calculator"
import AddColumn from "./add_column"
import JoinTable from "./join_table"
import CreateLayer from "./create_layer"
import type { MapsourcesType } from "../../reducers/mapsources"
import type { AttributeTableType } from "../../reducers/attribute_tables"
import { appendToLogger, setMapError } from "../../actions"

type PropsType = {
    appendToLogger: Function,
    attributeTable: AttributeTableType,
    canClose: boolean,
    createLayerFromTable: Function,
    height: number,
    joinTable: Function,
    mapsources: MapsourcesType,
    onClickMapToolbarItem: Function,
    onExportTable: Function,
    onShowTool?: Function,
    saveEdits: Function,
    token: string,
    theme: Theme,
}

const copyToClipboard = (str) => {
    const el = document.createElement("textarea")
    el.value = str
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    const selected =
        document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
    if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
    }
}

export function getAttributes(props, finished) {
    let hasError = false
    fetch(`/er2_map/get_layer_attributes/?token=${props.token.value}`, {
        body: JSON.stringify({
            layer: props.attributeTable.name,
            keepGeom: true,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((res) => {
            hasError = res.status !== 200
            if (hasError) return res.text()
            return res.json()
        })
        .then((json) => {
            if (hasError) {
                props.appendToLogger([`Got failure to create layer: ${json}`])
                finished([])
            } else {
                finished(json.attributes)
            }
        })
}

export function AttributeTable(props: PropsType) {
    const containerRef = useRef(null)
    const [attributeTable, setAttributeTable] = useState(props.attributeTable)
    const [cellSelection, setCellSelection] = useState([[-1, -1]])
    const [columnMenuActionsAnchor, setColumnMenuActionsAnchor] = useState(null)
    const [columnMenuActionsColumn, setColumnMenuActionsColumn] = useState(null)
    const [containerState, setContainerState] = useState({})
    const [editable, setEditable] = useState(false)
    const [editManager, setEditManager] = useState(null)
    const [fetchingAttributes, setFetchingAttributes] = useState(false)
    const [filter, setFilter] = useState("")
    const [prevHeight, setPrevHeight] = useState(null)
    const [redraw, setRedraw] = useState(0)
    const [showAddColumn, setShowAddColumn] = useState(false)
    const [showCalculator, setShowCalculator] = useState(false)
    const [showCreateLayer, setShowCreateLayer] = useState(false)
    const [showEditColumn, setShowEditColumn] = useState(false)
    const [showJoinTable, setShowJoinTable] = useState(false)
    const [showStats, setShowStats] = useState(false)
    const [tableMenuActionsAnchor, setTableMenuActionsAnchor] = useState(null)

    const columns = React.useMemo(() => {
        const renderCell = (cellInfo) => <div>{cellInfo.value}</div>

        const renderEditableCell = (cellInfo) => {
            const emRows = editManager.rows
            let val = emRows[cellInfo.index][cellInfo.column.id]
            // Convert nulls and undefined to empty string for the input
            if (val === undefined || val === null) val = ""
            return (
                <TextField
                    className={props.theme.attributeTableTextEdit}
                    InputProps={{
                        classes: {
                            input: props.theme.attributeTableTextEdit,
                        },
                    }}
                    onBlur={(e) => {
                        const newValue = e.currentTarget.value
                        const row = emRows[cellInfo.index]
                        editManager.addCellEdit(
                            row.id,
                            cellInfo.column.id,
                            newValue,
                        )
                        setRedraw(redraw + 1)
                    }}
                    onFocus={(e) => e.target.select()}
                    defaultValue={val}
                    key={val}
                />
            )
        }

        function columnWithContextMenu(fname) {
            return (
                <React.Fragment>
                    <div>
                        {fname}
                        <IconButton
                            aria-owns={columnMenuActionsAnchor}
                            aria-haspopup="true"
                            onClick={handleColumnMenuClick(fname)}
                            className={props.theme.littleButton}
                        >
                            <i className="fa fa-caret-down" />
                        </IconButton>
                    </div>
                </React.Fragment>
            )
        }

        const handleColumnMenuClick = (fname) => (event) => {
            setColumnMenuActionsAnchor(event.currentTarget)
            setColumnMenuActionsColumn(fname)
        }

        if (editManager) {
            return editManager.header.map((fname) => ({
                Header: columnWithContextMenu(fname),
                accessor: fname,
                Cell: editable ? renderEditableCell : renderCell,
                getProps: (state, rowInfo, column) => {
                    if (rowInfo) {
                        let selected = false
                        const colIndex = editManager.header.indexOf(column.id)
                        const rowIndex = rowInfo.index
                        const col0 = cellSelection[0][0]
                        const row0 = cellSelection[0][1]
                        if (cellSelection.length === 1) {
                            selected = colIndex === col0 && rowIndex === row0
                        } else {
                            const col1 = cellSelection[1][0]
                            const row1 = cellSelection[1][1]
                            const lowCol = col0 < col1 ? col0 : col1
                            const highCol = col0 > col1 ? col0 : col1
                            const lowRow = row0 < row1 ? row0 : row1
                            const highRow = row0 > row1 ? row0 : row1
                            selected =
                                colIndex >= lowCol &&
                                colIndex <= highCol &&
                                rowIndex >= lowRow &&
                                rowIndex <= highRow
                        }
                        let selectedColor = "#bde9f7"
                        if (rowIndex % 2 === 0) {
                            selectedColor = "#b7e0ed"
                        }
                        return {
                            style: {
                                background: selected
                                    ? selectedColor
                                    : "inherit", // rowInfo && rowInfo.row.age > 10 ? 'red' : null,
                                borderBottom: "1px solid",
                                borderBottomColor: selected
                                    ? selectedColor
                                    : "#f7f7f7",
                                // borderColor: selected ? 'lightblue' : 'inherit',
                                // border: selected ? 'solid 1px' : 'inherit'
                            },
                        }
                    }
                    return {}
                },
            }))
        }
        return []
    }, [cellSelection, editManager])

    const handleColumnMenuClose = (fname) => (action) => () => {
        setColumnMenuActionsAnchor(null)
        setColumnMenuActionsColumn(fname)
        if (action === "statistics") {
            showStatistics(fname)
        } else if (action === "calculate") {
            setShowCalculator(true)
        } else if (action === "calculateArea") {
            calculateArea(fname)
        } else if (action === "editColumn") {
            setShowEditColumn(true)
        } else if (action === "deleteColumn") {
            deleteColumn(fname)
        } else if (action !== undefined) {
            console.warn(`Action is ${action}`)
        }
    }

    const saveEdits = () => {
        const editsToSave = editManager.saveEdits()
        if (editsToSave.length) {
            props.saveEdits(
                props.attributeTable.name,
                props.attributeTable.mapsource,
                editsToSave,
            )
            setEditable(false)
        }
    }

    const cancelEdits = () => {
        editManager.cancelEdits()
    }

    const toggleEditing = () => {
        if (editable) {
            cancelEdits()
        }
        setEditable(!editable)
    }

    const undoEdit = () => {
        editManager.undoLastEdit()
    }

    const setFilterFixMe = (e) => {
        setFilter(e.currentTarget.value)
    }

    const handleCellClick = (event, rowIndex, colIndex) => {
        if (!event.shiftKey) {
            setCellSelection([[colIndex, rowIndex]])
        } else {
            event.preventDefault()
            const firstColIndex = cellSelection[0][0]
            const firstRowIndex = cellSelection[0][1]
            setCellSelection([
                [firstColIndex, firstRowIndex],
                [colIndex, rowIndex],
            ])
        }
    }

    const selectionString = () => {
        if (cellSelection.length === 1) {
            const i = cellSelection[0][1]
            const j = cellSelection[0][0]
            return editManager.rows[i][editManager.header[j]]
        }
        const col0 = cellSelection[0][0]
        const col1 = cellSelection[1][0]
        const row0 = cellSelection[0][1]
        const row1 = cellSelection[1][1]
        const lowCol = col0 < col1 ? col0 : col1
        const highCol = col0 > col1 ? col0 : col1
        const lowRow = row0 < row1 ? row0 : row1
        const highRow = row0 > row1 ? row0 : row1

        let resultString = ""

        for (let i = lowRow; i <= highRow; i += 1) {
            for (let j = lowCol; j <= highCol; j += 1) {
                const value = editManager.rows[i][editManager.header[j]]
                resultString += `${value}\t`
            }
            if (i !== highRow) resultString += "\n"
        }
        return resultString
    }

    const handleCellCopy = (event) => {
        event.stopPropagation()
        copyToClipboard(selectionString())
    }

    const handleFieldCalculate = (formula, targetColumn) => {
        editManager.fieldCalculate(formula, targetColumn)
    }

    const addColumn = (name, type) => {
        editManager.addColumn(name, type)
        setShowAddColumn(false)
    }

    function buildRows(attributes) {
        // row data is nested in the properties object
        const newRows = attributes.map((attrs) => ({
            ...attrs.properties,
            id: parseInt(attrs.id),
        }))
        setEditManager(
            new EditManager(
                newRows,
                props.attributeTable.schema,
                props.attributeTable.header,
            ),
        )
    }

    const calculateArea = (name) => {
        fetch(`/er2_map/get_layer_areas/?token=${props.token.value}`, {
            body: JSON.stringify({
                layer: props.attributeTable.name,
                keepGeom: true,
            }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        })
            .then((res) => res.json())
            .then((json) => {
                Object.entries(json.areas).forEach(([id, area]) => {
                    editManager.addCellEdit(parseInt(id), name, area.toFixed(1))
                })
                setRedraw(redraw + 1)
            })
    }

    const deleteColumn = (name) => {
        editManager.deleteColumn(name)
    }

    const calculateColumn = (formula) => {
        editManager.fieldCalculate(columnMenuActionsColumn, formula)
        setShowCalculator(false)
    }

    const editColumn = (name, type) => {
        if (false && editManager.header.indexOf(name) >= 0) {
            alert("Column already exists")
        } else {
            editManager.editColumn(columnMenuActionsColumn, name, type)
            setShowEditColumn(false)
        }
    }

    function getRowsPerPage(containerHeight) {
        // Return at least one row so that the react-table has a tr element for calculating height on the next go-around
        let nrows = 0
        const {
            controlbarHeight,
            headerHeight,
            paginationHeight,
            rowHeight,
        } = containerState
        if (rowHeight) {
            nrows = Math.floor(
                (containerHeight -
                    headerHeight -
                    paginationHeight -
                    controlbarHeight) /
                    rowHeight,
            )
            if (nrows < 1) {
                nrows = 1
            }
        }
        return nrows
    }

    useEffect(() => {
        // Initial mount
        setFetchingAttributes(true)
        getAttributes(props, (attributes) => buildRows(attributes))
    }, [])

    useEffect(() => {
        // add row height to state after height updates
        if (containerRef.current && prevHeight !== props.height) {
            const headers = containerRef.current.getElementsByClassName(
                "rt-thead",
            )
            if (headers.length) {
                const headerHeight = Array.from(headers).reduce(
                    (height, h) => height + h.clientHeight,
                    0,
                )
                const pagination = containerRef.current.getElementsByClassName(
                    "pagination-bottom",
                )
                const paginationHeight = pagination[0].getBoundingClientRect()
                    .height
                const controlbar = containerRef.current.getElementsByClassName(
                    "attributeTableControlBar",
                )
                const controlbarHeight = controlbar[0].getBoundingClientRect()
                    .height
                const containerRows = containerRef.current
                    .getElementsByClassName("rt-tbody")[0]
                    .getElementsByClassName("rt-tr")
                if (containerRows.length) {
                    const rowHeight = containerRows[0].getBoundingClientRect()
                        .height
                    setContainerState({
                        controlbarHeight,
                        headerHeight,
                        paginationHeight,
                        rowHeight,
                    })
                }
            }
            setPrevHeight(props.height)
        }
    })

    const createLayer = (mode, state) => {
        props.createLayerFromTable(
            props.attributeTable.mapsource,
            props.attributeTable.name,
            mode,
            state,
        )
        setShowCreateLayer(false)
    }

    const joinTable = (joinField, sourceLayer, sourceField, joinLayerName) => {
        props.joinTable(
            props.attributeTable.mapsource,
            props.attributeTable.name,
            joinField,
            sourceLayer,
            sourceField,
            joinLayerName,
        )
        setShowJoinTable(false)
    }

    if (editManager === null) {
        return (
            <div
                style={{
                    fontSize: 24,
                    marginTop: 40,
                    textAlign: "center",
                }}
            >
                Loading&nbsp;
                <i className="fa fa-spinner fa-spin" />
            </div>
        )
    } else if (attributeTable.schema.properties) {
        const handleTableMenuClick = (event) => {
            setTableMenuActionsAnchor(event.currentTarget)
        }

        const handleTableMenuClose = (action) => () => {
            setTableMenuActionsAnchor(null)
            if (action === "addColumn") {
                setShowAddColumn(true)
            } else if (action === "createLayer") {
                setShowCreateLayer(true)
            } else if (action === "export") {
                const mapsource = getMapsource(
                    props.mapsources,
                    props.attributeTable,
                )
                const layer = getLayer(
                    props.mapsources,
                    "default",
                    props.attributeTable.name,
                )
                props.onExportTable(mapsource, layer)
            } else if (action === "joinTable") {
                setShowJoinTable(true)
            } else if (action === "buildQuery") {
                // setModelessDialog('QUERY_BUILDER')
                // Check for app override in case the toolbox is not in the maptoobar.
                if (props.onShowTool) {
                    props.onShowTool(mapToolbarIds.GEO_BAR_QUERY)
                } else {
                    props.onClickMapToolbarItem(mapToolbarIds.SELECT_SERVICES)
                }
            }
        }

        const data = filter
            ? editManager.rows.filter((row) => {
                  let ok = false
                  Object.entries(row).forEach(([k, v]) => {
                      ok = ok || String(v).includes(filter)
                  })
                  return ok
              })
            : editManager.rows

        const rowsPerPage = getRowsPerPage(props.height) || 1
        return (
            <ErrorBoundary>
                <AddColumn
                    onClose={() => setShowAddColumn(false)}
                    onAccept={addColumn}
                    open={showAddColumn}
                />
                <AddColumn
                    columnName={columnMenuActionsColumn}
                    columnType={
                        props.attributeTable.schema.properties[
                            columnMenuActionsColumn
                        ]
                    }
                    onClose={() => setShowEditColumn(false)}
                    onAccept={editColumn}
                    open={showEditColumn}
                />
                <Calculator
                    column={columnMenuActionsColumn}
                    onClose={() => setShowCalculator(false)}
                    onCalculate={calculateColumn}
                    open={showCalculator}
                    schema={editManager.schema}
                    theme={props.theme}
                />
                {columnMenuActionsAnchor !== null && (
                    <Menu
                        id={columnMenuActionsColumn}
                        anchorEl={columnMenuActionsAnchor}
                        open={columnMenuActionsColumn !== null}
                        onClose={handleColumnMenuClose(columnMenuActionsColumn)(
                            null,
                        )}
                    >
                        <MenuItem
                            className={props.theme.attributeTableMenuItem}
                            onClick={handleColumnMenuClose(
                                columnMenuActionsColumn,
                            )("editColumn")}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            className={props.theme.attributeTableMenuItem}
                            onClick={handleColumnMenuClose(
                                columnMenuActionsColumn,
                            )("deleteColumn")}
                        >
                            Delete
                        </MenuItem>
                        <MenuItem
                            className={props.theme.attributeTableMenuItem}
                            onClick={handleColumnMenuClose(
                                columnMenuActionsColumn,
                            )("calculate")}
                        >
                            Field Calculator
                        </MenuItem>
                        {props.layer_type !== "Table" && (
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleColumnMenuClose(
                                    columnMenuActionsColumn,
                                )("calculateArea")}
                            >
                                Calculate Area
                            </MenuItem>
                        )}
                        <MenuItem
                            className={props.theme.attributeTableMenuItem}
                            onClick={handleColumnMenuClose(
                                columnMenuActionsColumn,
                            )("statistics")}
                        >
                            Statistics
                        </MenuItem>
                    </Menu>
                )}
                <div
                    ref={containerRef}
                    className={props.theme.attributeTableContainer}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <IconButton
                            className={props.theme.littleButton}
                            onClick={handleTableMenuClick}
                            size={"small"}
                        >
                            <i className={"fa fa-bars"} />
                        </IconButton>
                        <Menu
                            id="table-actions-menu"
                            anchorEl={tableMenuActionsAnchor}
                            className={props.theme.attributeTableMenuItem}
                            open={Boolean(tableMenuActionsAnchor)}
                            onClose={handleTableMenuClose(null)}
                        >
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleTableMenuClose("addColumn")}
                            >
                                Add Column
                            </MenuItem>
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleTableMenuClose("createLayer")}
                            >
                                Build Layer
                            </MenuItem>
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleTableMenuClose("export")}
                            >
                                Export
                            </MenuItem>
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleTableMenuClose("buildQuery")}
                            >
                                Query (Advanced Search)
                            </MenuItem>
                            <MenuItem
                                className={props.theme.attributeTableMenuItem}
                                onClick={handleTableMenuClose("joinTable")}
                            >
                                Join Table
                            </MenuItem>
                        </Menu>
                        <IconButton
                            className={props.theme.littleButton}
                            onClick={toggleEditing}
                            size={"small"}
                        >
                            <i className={"fa fa-pencil"} />
                        </IconButton>
                        <IconButton
                            className={props.theme.littleButton}
                            disabled={!editManager.hasEdits()}
                            onClick={saveEdits}
                            size={"small"}
                            title={"Commit changes to layer"}
                        >
                            <i className={"fa fa-floppy-o"} />
                        </IconButton>
                        <IconButton
                            className={props.theme.littleButton}
                            disabled={!editManager.hasEdits()}
                            onClick={undoEdit}
                            size={"small"}
                        >
                            <i className={"fa fa-undo"} />
                        </IconButton>
                        <IconButton
                            onClick={handleCellCopy}
                            className={props.theme.littleButton}
                            disabled={cellSelection[0][0] === -1}
                        >
                            <i className={"fa fa-copy"} />
                        </IconButton>
                        <div
                            className={"attributeTableControlBar"}
                            style={{
                                flex: 1,
                                justifyContent: "flex-end",
                                textAlign: "right",
                            }}
                        >
                            <span
                                style={{
                                    display: "inline-block",
                                    marginTop: 10,
                                    marginRight: 6,
                                }}
                            >
                                Search:
                            </span>
                            <TextField
                                margin="none"
                                onChange={(e) =>
                                    setFilter(e.currentTarget.value)
                                }
                            />
                        </div>
                    </div>
                    <CreateLayer
                        rows={editManager.rows}
                        onClose={() => setShowCreateLayer(false)}
                        onAccept={createLayer}
                        schema={editManager.schema}
                        open={showCreateLayer}
                    />
                    <JoinTable
                        layer={props.attributeTable}
                        mapsources={props.mapsources}
                        onClose={() => setShowJoinTable(false)}
                        onAccept={joinTable}
                        open={showJoinTable}
                    />
                    <ReactTable
                        className="-striped -highlight"
                        columns={columns}
                        data={data}
                        defaultPageSize={rowsPerPage}
                        getResizerProps={() => ({
                            style: { right: "-3px", width: "6px" },
                        })}
                        getTdProps={(state, rowInfo, column) => ({
                            onClick: (e, handleOriginal) => {
                                if (!editable) {
                                    handleCellClick(
                                        e,
                                        rowInfo.index,
                                        editManager.header.indexOf(column.id),
                                    )
                                }
                                if (handleOriginal) {
                                    // Allow original event
                                    handleOriginal()
                                }
                            },
                        })}
                        getTheadThProps={() => ({ style: { width: 0 } })}
                        getTheadGroupThProps={() => ({ style: { outline: 0 } })}
                        getTrGroupProps={() => ({
                            style: { borderBottom: "solid 0px" },
                        })}
                        key={rowsPerPage}
                        pageSize={rowsPerPage}
                        showPageSizeOptions={false}
                    />
                </div>
            </ErrorBoundary>
        )
    }
    return <h3>Sorry, no attribute information is available</h3>
}

AttributeTable.defaultProps = {
    onShowTool: null,
}

export default AttributeTable
