// @flow
// Manages state for the map toolbar
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as actionTypes from "../constants/action_types"
import * as ids from "../constants/ids"

export type PanelType = {
    visible: boolean,
}

export type MapToolbarItemType = {
    active: boolean,
    icon: { default: string },
    id: string,
    name: string,
}

export type MapToolbarType = {
    opacity: number,
    panel: PanelType,
    items: MapToolbarItemType[],
}

export type MapToolbarActionType = {
    type: string,
    id: string,
    active?: boolean,
}

export const getMapToolbarContent = (theme) => (
    <FontAwesomeIcon
        icon={"tools"}
        className={`${theme.iconButton} ${theme.iconStyle}`}
        style={{ marginTop: -4 }}
    />
)

export const initialState = {
    isContracted: false,
    items: [
        {
            active: false,
            icon: { default: <FontAwesomeIcon icon={"layer-group"} /> },
            id: ids.SELECT_USER_LAYERS,
            name: "Upload and Display Layers and Tables",
        },
        {
            active: false,
            icon: { default: <FontAwesomeIcon icon={"tools"} /> },
            id: ids.SELECT_SERVICES,
            name: "Geoprocessing Tools and CSIP Services",
        },
        {
            active: false,
            icon: { default: <FontAwesomeIcon icon={"cog"} /> },
            id: ids.SELECT_BASE_LAYERS,
            name: "Base Layer Selection and Settings",
        },
    ],
    opacity: 65,
    panel: { visible: false },
}

/**
 * Returns true if any of the map toolbar items are active. Used to toggle
 * the map toolbar panel on and off.
 */
export function isPanelVisible(items, action) {
    let isVisible = false
    items.find((item) => {
        if (item.id === action.id && !item.active) {
            isVisible = true
            return true
        }
        return false
    })
    return isVisible
}

export default function mapToolbar(
    state: MapToolbarType = initialState,
    action: MapToolbarActionType,
) {
    switch (action.type) {
        case actionTypes.CLICK_GEO_BAR_ITEM:
            // Allow for the toggling of the user layers panel when the geo bar is enabled
            if (action.id === ids.GEO_BAR_HOME) {
                return {
                    ...state,
                    panel: { visible: true },
                    items: state.items.map((item) => ({
                        ...item,
                        active: item.id === ids.MAP_TOOLBAR_USER_LAYERS,
                    })),
                }
            }
            return state

        case actionTypes.CLICK_MAP_TOOLBAR_ITEM:
            // Allow for the toggling of the user layers panel when the geo bar is enabled
            if (action.id === ids.GEO_BAR_HOME) {
                return {
                    ...state,
                    panel: { visible: true },
                    items: state.items.map((item) => ({
                        ...item,
                        active: item.id === ids.MAP_TOOLBAR_USER_LAYERS,
                    })),
                }
            }
            return {
                ...state,
                panel: { visible: isPanelVisible(state.items, action) },
                items: state.items.map((item) =>
                    item.id === action.id
                        ? { ...item, active: !item.active }
                        : { ...item, active: false },
                ),
            }

        case actionTypes.CHANGE_MAPTOOLBAR_OPACITY:
            return {
                ...state,
                opacity: action.opacity,
            }

        default:
            return state
    }
}
