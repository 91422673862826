// Theme Colors

// from https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=00B8D4&secondary.color=607D8B&primary.text.color=FAFAFA
export const primaryColor = "#00b8d4"
export const primaryLightColor = "#62ebff"
export const primaryDarkColor = "#20638c"
export const secondaryColor = "#368cc1"
export const secondaryLightColor = "#caedff"
export const secondaryDarkColor = "#34515e"
export const primaryTextColor = "#f9f9f9"
export const secondaryTextColor = "#000000"

// Others
export const primaryTwo = "#C8C372"
export const primaryTwoDarken5 = "#b4ae46"

// blue
export const highlight = "#588fff"
// dark green
export const primaryOne = primaryColor
export const primaryOneDarken5 = primaryDarkColor
export const primaryOneLighten5 = primaryLightColor
export const secondaryOne = secondaryColor
export const secondaryOneDarken5 = secondaryDarkColor
export const secondaryOneLighten5 = secondaryLightColor

export const secondaryTwo = "#368cc1"
// dark gray
export const textColor = "#59595B"
export const contrastTextColor = "#FFF"

// Standard Colors
export const closerBackgroundColor = "#ffffff"
export const danger = "#dc3545"
export const dangerDarken5 = "#d42537"
export const dangerLighten5 = "#e04d5c"
export const info = "#898e96"
export const success = "#5cb85c"
export const successDarken5 = "#4cae4c"
export const successLighten5 = "#6ebf6e"
export const warning = "#ffc107"
export const warningDarken5 = "#ebb000"
export const warningLighten5 = "#ffc71f"

// Custom colors
export const gray = "#e3eaed"
export const offwhite = "#f9f9f9"
export const white = "#FFF"
export const blue = "#267fca"
export const blueDarken5 = "#2272b4"
export const blueLighten5 = "#318dd8"
