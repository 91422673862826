import React from "react"
import { Grid, Grow, IconButton, SvgIcon } from "@material-ui/core"
import OLVectorLayer from "ol/layer/Vector"
import OLVectorSource from "ol/source/Vector"
import OLStyle from "ol/style/Style"
import OlStyleStroke from "ol/style/Stroke"
import OlStyleCircle from "ol/style/Circle"
import OlStyleFill from "ol/style/Fill"
import OlStyleText from "ol/style/Text"
import OLGeoJSON from "ol/format/GeoJSON"
import { graphGeneralRegionColors } from "styles/colors.graph"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as icons from "constants/icons"
import { secondaryDarkColor } from "../../styles/colors.main"

const featureProjection = "EPSG:3857"
const dataProjection = "EPSG:4326"
const commonMapFontStyle = {
    font: "13px Roboto Condensed, sans-serif",
    fill: new OlStyleFill({
        color: "black",
    }),
    padding: [5, 5, 5, 5],
    stroke: new OlStyleStroke({ color: "black", width: 0.4 }),
}

export const MrrpDefaultCategories = {
    GENERAL: { label: "General", icon: icons.SmallRiver },
    BIRD: { label: "Piping Plover & Least Tern", icon: icons.SmallBirds },
    FISH: { label: "Pallid Sturgeon", icon: icons.SmallFish },
}

export const mapLayers = {
    MissouriRiverInvert: {
        id: "MissouriRiverInvert",
        displayName: "Missouri River Basin",
        shapeFileName: "MissouriRiver_invert.shp",
        legend: {
            names: ["Missouri River Basin"],
            colors: [],
            type: null,
        },
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    MajorDams: {
        id: "MajorDams",
        displayName: "Major Dams",
        shapeFileName: "Dams_combined.shp",
        legend: {
            names: ["Major Dams"],
            colors: ["#000000"],
            type: "Point",
        },
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    StreamNetwork: {
        id: "StreamNetwork",
        displayName: "Missouri Stream Network",
        shapeFileName: "MissouriStreamNetwork.shp",
        legend: null,
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    MO_River_Miles_min: {
        id: "RiverMiles",
        displayName: "Missouri River Miles",
        shapeFileName: "MO_River_Miles_min.shp",
        legend: {
            names: ["River Miles"],
            colors: ["#bbb5b5"],
            borderColor: "#000000",
            type: "Point",
        },
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    MississippiStreamNetwork: {
        id: "MississippiStreamNetwork",
        displayName: "Mississippi Stream Network",
        shapeFileName: "MississippiStreamNetwork.shp",
        legend: {
            names: ["Mississippi Stream Network"],
            colors: ["#000000"],
            type: "Line",
        },
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    BirdSegments: {
        id: "BirdSegments",
        displayName: "Segments",
        shapeFileName: "PP_Segments.shp",
        legend: {
            names: [
                "Lake Sakakawea",
                "Garrison River",
                "Lake Oahe",
                "Fort Randall River",
                "Lewis and Clark Lake",
                "Gavins Point River",
            ],
            colors: [
                graphGeneralRegionColors.specific.Sakakawea,
                graphGeneralRegionColors.specific.Garrison,
                graphGeneralRegionColors.specific.Oahe,
                graphGeneralRegionColors.specific.Randall,
                graphGeneralRegionColors.specific.LewisAndClark,
                graphGeneralRegionColors.specific.Gavins,
            ],
            type: "Rectangle",
        },
        defaultCategory: MrrpDefaultCategories.BIRD,
    },
    BirdRegions: {
        id: "BirdRegions",
        displayName: "Regions",
        shapeFileName: "BirdRegions.shp",
        legend: {
            names: ["Northern Region", "Southern Region"],
            colors: ["#FF8C19", "#492970"],
            type: "Line",
        },
        defaultCategory: MrrpDefaultCategories.BIRD,
    },
    BirdTribs: {
        id: "BirdTribs",
        displayName: "Major Tributaries",
        shapeFileName: "Bird_Major_Tribs_Dissolve.shp",
        legend: {
            names: ["Bird Major Tributaries"],
            colors: ["#404040"],
            type: "Line",
        },
        defaultCategory: MrrpDefaultCategories.BIRD,
    },
    FishRPMU: {
        id: "FishRPMU",
        displayName: "RPMU Segments",
        shapeFileName: "PallidSturgeon_ManagementUnits_min.shp",
        alternateShapeName: "PallidSturgeon_ManagementUnits_merged",
        legend: {
            names: ["Great Plains", "Central Lowlands", "Interior Highlands"],
            colors: [
                graphGeneralRegionColors.upperRiver,
                graphGeneralRegionColors.lowerRiver,
                graphGeneralRegionColors.mississippiRiver,
            ],
            type: "Line",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    FishSegments: {
        id: "FishSegments",
        displayName: "Segments",
        shapeFileName: "PSPA_Segments_Layer_2020_Update.shp",
        legend: {
            names: [
                "Segment 2",
                "Segment 3",
                "Segment 4",
                "Segment 7",
                "Segment 8",
                "Segment 9",
                "Segment 10",
                "Segment 13",
                "Segment 14",
                "Segment 22 (Yellowstone River)",
                "Segment 57 (Yellowstone River)",
                "Segment 55 (Mississippi River)",
                "Segment 56 (Mississippi River)",
            ],
            colors: [
                graphGeneralRegionColors.specific.segment2,
                graphGeneralRegionColors.specific.segment3,
                graphGeneralRegionColors.specific.segment4,
                graphGeneralRegionColors.specific.segment7,
                graphGeneralRegionColors.specific.segment8,
                graphGeneralRegionColors.specific.segment9,
                graphGeneralRegionColors.specific.segment10,
                graphGeneralRegionColors.specific.segment13,
                graphGeneralRegionColors.specific.segment14,
                graphGeneralRegionColors.specific.segment22,
                graphGeneralRegionColors.specific.segment57,
                graphGeneralRegionColors.specific.segment55,
                graphGeneralRegionColors.specific.segment56,
            ],
            type: "Rectangle",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    FishTribs: {
        id: "FishTribs",
        displayName: "Major Tributaries",
        shapeFileName: "Fish_Major_Tribs_Dissolve.shp",
        legend: {
            names: ["Fish Major Tributaries"],
            colors: ["#404040"],
            type: "Line",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    Hatcheries: {
        id: "Hatcheries",
        displayName: "Hatcheries",
        shapeFileName: "PS_Hatcheries.shp",
        legend: {
            names: ["Hatcheries"],
            colors: ["#bbb5b5"],
            borderColor: "#000000",
            type: "Point",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    PSBends: {
        id: "PSBends",
        displayName: "River Bends",
        shapeFileName: "PS_Bends.shp",
        legend: {
            names: ["Pallid Sturgeon Bends"],
            colors: ["#bbb5b5"],
            borderColor: "#000000",
            type: "OutlinePolygon",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    IrcTreatmentControlSites: {
        id: "IRCSites",
        displayName: "IRC Sites",
        shapeFileName: "IRC_Treatment_Control_Sites.shp",
        alternateShapeName: "Treatment_Sites",
        legend: {
            names: ["Control Sites", "Treatment Sites"],
            colors: ["#8A2BE2", "#f4b442"],
            type: "Rectangle",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
    UsgsCombineSites: {
        id: "UsgsCombineSites",
        displayName: "USGS Sites",
        shapeFileName: "USGS_Combine_Sites.shp",
        alternateShapeName: "",
        legend: {
            names: ["Sites"],
            colors: [secondaryDarkColor],
            type: "Point",
        },
        defaultCategory: MrrpDefaultCategories.GENERAL,
    },
    FishRegions: {
        id: "FishRegions",
        displayName: "Regions",
        shapeFileName: "PSPA_Regions.shp",
        legend: {
            names: ["Upper River", "Lower River"],
            colors: ["#A52A2A", "#4472C4"],
            type: "OutlinePoygon",
        },
        defaultCategory: MrrpDefaultCategories.FISH,
    },
} as { [x: string]: MrrpMapLayerMetadataType }

export const mrrpLayerMetadata = Object.keys(mapLayers).map(
    (key) => mapLayers[key]
) as MrrpMapLayerMetadataType[]

export type MrrpMapLayerMetadataType = {
    id: string
    displayName: string
    shapeFileName: string
    alternateShapeName?: string
    legend: any
    defaultCategory: { label: string; icon: any }
}

const legendIconStyle = (type, color, borderColor) => {
    switch (type) {
        case "Rectangle":
            return (
                <div
                    style={{
                        width: "1em",
                        height: "1em",
                        background: color,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: borderColor,
                    }}
                />
            )
        case "Point":
            return (
                <SvgIcon viewBox={"0 0 100 100"} width="1em" height="1em">
                    <circle cx={"50"} cy={"50"} r={"20"} fill={color} />
                </SvgIcon>
            )
        case "Line":
            return (
                <SvgIcon viewBox={"0 0 100 100"} width="1em" height="1em">
                    <line
                        x1="10"
                        y1="50"
                        x2="90"
                        y2="50"
                        stroke={color}
                        strokeWidth={"10"}
                    />
                </SvgIcon>
            )
        case "OutlinePoygon":
            return (
                <div
                    style={{
                        width: "1em",
                        height: "1em",
                        background: color,
                        borderColor: "black",
                        borderStyle: "solid",
                        borderWidth: "thin",
                    }}
                />
            )
        case "OutlinePoint":
            return (
                <SvgIcon viewBox={"0 0 100 100"} width="1em" height="1em">
                    <circle
                        cx={"50"}
                        cy={"50"}
                        r={"20"}
                        fill={color}
                        stroke={"black"}
                        strokeWidth={2}
                    />
                </SvgIcon>
            )
        default:
            return null
    }
}

export const renderLegend = (legend) => {
    const legendType = legend && legend.type ? legend.type : null
    const nameList = legend && legend.names ? legend.names : null
    const colorList = legend && legend.colors ? legend.colors : null
    if (legendType && nameList && colorList) {
        return (
            <Grid
                container
                direction="column"
                style={{ marginLeft: "16px" }}
                spacing={1}
            >
                {legend.colors.map((color, index) => (
                    <Grid item key={index.toString()}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                {legendIconStyle(
                                    legendType,
                                    color,
                                    legend.borderColor
                                        ? legend.borderColor
                                        : color
                                )}
                            </Grid>
                            <Grid item xs>
                                {legend.names[index]}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
    }
    return null
}

export const getPointLayerStyle = (
    feature,
    resolution,
    strokeColor,
    fillColor,
    featureName = null
) => {
    if (featureName !== null) {
        // const text =
        //     feature.get(featureName) !== null
        //         ? featureName === "RIVMILE"
        //             ? parseInt(feature.get(featureName)).toString()
        //             : feature.get(featureName).toString()
        //         : ""
        const text =
            feature.get(featureName) !== null
                ? feature.get(featureName).toString()
                : ""
        const textStyle = new OlStyleText({
            ...commonMapFontStyle,
            offsetY: 15,
            text,
            // font: 'bold 15px serif',
            // fill: new OlStyleFill({
            //     color: 'black',
            // }),
            // offsetX: 50,
            // padding: [2, 2, 2, 2],
            // stroke: new OlStyleStroke({ color: 'black', width: 0.5 }),
            // text,
        })
        return new OLStyle({
            image: new OlStyleCircle({
                fill: new OlStyleFill({
                    color: fillColor,
                }),
                radius: 5,
                stroke: new OlStyleStroke({
                    color: strokeColor,
                    width: 1,
                }),
            }),
            text: textStyle,
        })
    }
    return new OLStyle({
        image: new OlStyleCircle({
            fill: new OlStyleFill({
                color: fillColor,
            }),
            radius: 5,
            stroke: new OlStyleStroke({
                color: strokeColor,
                width: 1,
            }),
        }),
    })
}

export const getPolyLayerStyle = (
    feature,
    resolution,
    strokeColor,
    fillColor,
    featureName = null
) => {
    if (featureName !== null) {
        const text =
            feature.get(featureName) !== null
                ? feature.get(featureName).toString()
                : ""
        const textStyle = new OlStyleText({
            ...commonMapFontStyle,
            overflow: true,
            padding: [2, 2, 2, 2],
            placement: "point",
            textBaseline: "hanging",
            offsetX: 45,
            offsetY: -15,
            text,
            // font: '18px',
            // fill: new OlStyleFill({
            //     color: 'black',
            // }),
            // overflow: true,
            // padding: [2, 2, 2, 2],
            // placement: 'point',
            // // stroke: new OlStyleStroke({ color: 'black' }),
            // textBaseline: 'hanging',
            // text,
        })
        return new OLStyle({
            fill: new OlStyleFill({
                color: fillColor,
            }),
            stroke: new OlStyleStroke({
                color: strokeColor,
            }),
            text: textStyle,
        })
    }
    return new OLStyle({
        fill: new OlStyleFill({
            color: fillColor,
        }),
        stroke: new OlStyleStroke({
            color: strokeColor,
        }),
    })
}

export const addMapLayerUtils = (
    olmap,
    mapLayerData,
    style,
    layerName,
    opacity,
    declutter = false
) => {
    const features = new OLGeoJSON().readFeatures(mapLayerData, {
        featureProjection,
        dataProjection,
    })

    const vectorSource = new OLVectorSource({ features })

    const layer = new OLVectorLayer({
        declutter,
        name: layerName,
        opacity,
        source: vectorSource,
        style,
    })

    olmap.addLayer(layer)
}

export const setMapExtent = (
    olmap,
    extent = [-12926501, 4438368, -9548407, 6449138]
) => {
    olmap.getView().fit(extent, olmap.getSize())
}

export const removeAllLayers = (olmap, mapLayerList) => {
    mapLayerList.forEach((i) =>
        olmap.getLayers().forEach((layer) => {
            if (layer !== undefined) {
                if (
                    layer.get("name") !== undefined &&
                    i === layer.get("name")
                ) {
                    olmap.removeLayer(layer)
                }
            }
        })
    )
}

export const PopUpIconButton = ({ onClick, ...rest }) => {
    return (
        <Grow in={true}>
            <IconButton
                title="Show Map"
                size="small"
                aria-label="show map"
                onClick={onClick}
                {...rest}
            >
                <FontAwesomeIcon icon="map-marked-alt" />
            </IconButton>
        </Grow>
    )
}

export const renderPopUpIconButton = (onDialogAction) => (
    <PopUpIconButton onClick={onDialogAction} />
)
