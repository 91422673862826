// Combines all reducers for the application into a single exportable object
import { combineReducers } from "redux"
import {
    attributeTables,
    identify,
    logger,
    map,
    mapsources,
    publicSites,
    tabbedPanel,
    theme,
} from "@owsi/catena/er2_map_userlayers/js/reducers"
import { geoBarReducer } from "@owsi/catena/er2_ui"
import mapToolbar from "@owsi/catena/er2_ui/reducers/map_toolbar"
import services from "@owsi/catena/er2_map_services/js/reducers/services"
import contextBar from "app/store/reducers/context_bar"
import mapBaseLayer from "app/store/reducers/mapbaselayer"
import meta from "app/store/reducers/meta"
import serverState from "app/store/reducers/server_state"
import token from "app/store/reducers/token"

import birds from "app/store/reducers/birds"
import fish from "app/store/reducers/fish"
import overview from "app/store/reducers/overview"
import project from "app/store/reducers/project"
import birdModel from "app/store/reducers/bird_model"

const rootReducer = combineReducers({
    attributeTables,
    birds,
    contextBar,
    fish,
    birdModel,
    geoBar: geoBarReducer,
    identify,
    logger,
    map,
    mapBaseLayer,
    mapsources,
    mapToolbar,
    meta,
    overview,
    project,
    publicSites,
    serverState,
    services,
    tabbedPanel,
    theme,
    token,
})

export default rootReducer
