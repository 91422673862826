// @flow
import React, { useCallback, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import Map from "@owsi/catena/er2_map/src/map"
import Theme from "@owsi/catena/er2_styles"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import type { ServicesType } from "@owsi/catena/er2_map_services/js/reducers/services"
import {
    Checkbox,
    Fab,
    FormControlLabel,
    Icon,
    Snackbar,
    TextField,
} from "@material-ui/core"
import VectorSource from "ol/source/Vector"
import VectorLayer from "ol/layer/Vector"
import Draw, { createBox } from "ol/interaction/Draw"
import OlGeoJSON from "ol/format/GeoJSON"
import { olUtils } from "@owsi/catena/er2_ui"
import Fill from "ol/style/Fill"
import CircleStyle from "ol/style/Circle"
import Stroke from "ol/style/Stroke"
import { nullFunc } from "@owsi/catena/er2_map_userlayers/js/utils"

type PropsType = {
    actions: {
        fieldToWEPP: Function,
    },
    appendToLogger: Function,
    er2Map: Map,
    mapsources: MapsourcesType,
    services: ServicesType,
    theme: Theme,
}

const onFocus = (event) => event.target.select()

function FieldToWEPP(props: PropsType) {
    const [contributingArea, setContributingArea] = useState("0.1")
    const [drawnFeature, setDrawnFeature] = useState()
    const [isDrawing, setIsDrawing] = useState(false)
    const [outputTag, setOutputTag] = useState("Watershed")
    const [result, setResult] = useState(false)
    const [resultRendered, setResultRendered] = useState(false)
    const [running, setRunning] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const [vectorLayer, setVectorLayer] = useState()

    const olMap = props.er2Map.olmap
    const geojson = new OlGeoJSON()

    useEffect(() => {
        if (drawnFeature) {
            setRunning(true)
            const feat = geojson.writeFeatureObject(drawnFeature, {
                featureProjection: "EPSG:3857",
                dataProjection: "EPSG:4326",
            })
            feat.properties = {}
            props.actions.fieldToWEPP(
                feat,
                contributingArea,
                outputTag,
                showAll,
                (ret) => {
                    setResult("Extraction finished.")
                    setResultRendered(false)
                    setRunning(false)
                },
            )
        }
    }, [drawnFeature])

    const selectRegion = () => {
        setIsDrawing(!isDrawing)
    }

    const getStyle = useCallback(() => {
        const rgbFill = [0, 255, 0]
        const fillColor = `rgba(${[...rgbFill, 0.5]})`

        const rgbBorder = [0, 255, 255]
        const borderColor = `rgba(${[...rgbBorder, 0.5]})`

        // Todo: make this dynamic based on layer type
        return olUtils.getOlStyles({
            fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: fillColor,
                    opacity: 0.6,
                }),
                stroke: new Stroke({
                    color: borderColor,
                    width: 2,
                }),
            }),
            stroke: new Stroke({
                color: borderColor,
                width: 5,
            }),
        })
    }, [])

    useEffect(() => {
        const vs = new VectorSource()
        const vl = new VectorLayer({
            source: vs,
            style: getStyle,
            zIndex: 100,
        })
        vl.set("digitizingLayer", true)
        olMap.addLayer(vl)
        setVectorLayer(vl)

        return () => {
            olMap.removeLayer(vl)
        }
    }, [getStyle, olMap])

    useEffect(() => {
        if (vectorLayer && isDrawing) {
            const opts = {
                style: getStyle,
                source: vectorLayer.getSource(),
                type: "Circle",
                geometryFunction: createBox(),
            }
            const draw = new Draw(opts)
            draw.on("drawend", (evt) => {
                vectorLayer.getSource().clear()
                setDrawnFeature(evt.feature)
            })
            olMap.addInteraction(draw)
            return () => {
                olMap.removeInteraction(draw)
            }
        }
        return nullFunc
    }, [vectorLayer, olMap, isDrawing])

    function renderNotifications() {
        if (result) {
            const message = `Results saved to ${outputTag}`
            return (
                <div>
                    <Snackbar
                        open={resultRendered === false}
                        autoHideDuration={6000}
                        onClose={() => setResultRendered(true)}
                        message={<span id="message-id">{message}</span>}
                    />
                </div>
            )
        }
        return null
    }

    return (
        <div className={props.theme.geoBarCtr}>
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Tag"
                        value={outputTag}
                        onChange={(evt) => setOutputTag(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
                <div className={props.theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Contributing Area (hectares)"
                        value={contributingArea}
                        onChange={(evt) =>
                            setContributingArea(evt.target.value)
                        }
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
            </div>
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                label={"Show all outputs"}
                                margin="normal"
                                onChange={(evt) =>
                                    setShowAll(evt.target.checked)
                                }
                                value={showAll}
                            />
                        }
                        label={"Show all outputs"}
                    />
                </div>
            </div>
            <div className={props.theme.toolCtr}>
                <div className={props.theme.toolRow}>
                    <Fab
                        color={isDrawing ? "primary" : "default"}
                        onClick={selectRegion}
                        size={"small"}
                        title="Click on the map to create the point."
                    >
                        <Icon className={"ms ms-draw-point"} />
                    </Fab>
                    {running && (
                        <span
                            style={{ display: "inline-block", marginLeft: 30 }}
                        >
                            <FontAwesomeIcon
                                icon={faSpinner}
                                size={"2x"}
                                spin
                            />
                        </span>
                    )}
                </div>
            </div>
            {renderNotifications()}
        </div>
    )
}

export default FieldToWEPP
