// @flow
/* eslint no-console: "warn" */
import OlLayer from "ol/layer/Base"
import type OlMap from "./ol_map"
import type { ExtentType } from "./map"

export type AnimateConfigType = {
    // If true, then mapserver rasters should be cached
    animationMode: boolean,
    // Are we animating over a theme or a time interval
    animationType: "frame" | "interval",
    // Current frame of animation
    iframe: number,
    // The current interval
    iInterval: number,
    // Alternate ordering over mapfiles if present.
    iterationOrder: number[],
    // Number o` frames in transition
    ntransitions: number,
    opacity: number,
    // time in millis between frames
    speedFrame: number,
    // time in millis between transitions
    speedTransition: number,
    started: boolean,
}

export type MapfileDataType = {
    mapfile: string,
    layer: string,
    extent: ExtentType,
}

type ThemeType = {
    mapfiles: MapfileDataType[],
}

export type AnimateDataType = {
    config: AnimateConfigType,
    frameChanged: Function,
    themes: ThemeType[],
}

type AnimationStateType = {
    cacheUpdateTimeout: number,
    itransition: number,
    layers: OlLayer[],
    timeout: number,
}

class LayerAnimation {
    animationData: AnimateDataType
    animationState: AnimationStateType
    map: OlMap
    useTransition: boolean

    constructor(map: OlMap) {
        this.map = map
        // eslint-disable-next-line
        this.map.addViewChangedEvent(() => this.updateAnimateFrameCache())

        this.animationState = {
            layers: [],
        }
        this.useTransition = true
    }

    /**
     * layer animation
     * @param animateData
     */
    animate(animateData: AnimateDataType) {
        let wasRunningNowIsStopped
        let wasStoppedNowIsRunning
        let mapfilesChanged = false
        let iframeChanged = false
        let iIntervalChanged = false
        let configChanged = false

        // CHeck for changes in animation data state.
        const mapfiles = animateData.themes[0].mapfiles
        if (!this.animationData) {
            // Initial run.
            wasRunningNowIsStopped = false
            wasStoppedNowIsRunning = animateData.config.started
            mapfilesChanged = mapfiles.length > 0
            iframeChanged = animateData.config.iframe >= 0
        } else {
            wasRunningNowIsStopped =
                this.animationData.config.started && !animateData.config.started
            wasStoppedNowIsRunning =
                !this.animationData.config.started && animateData.config.started

            mapfilesChanged =
                !mapfiles ||
                JSON.stringify(this.animationData.themes[0].mapfiles) !==
                    JSON.stringify(mapfiles)
            iframeChanged =
                this.animationData.config.iframe !== animateData.config.iframe
            iIntervalChanged =
                this.animationData.config.iInterval !==
                animateData.config.iInterval

            // ignore iframe
            configChanged =
                JSON.stringify({
                    ...this.animationData.config,
                    iframe: undefined,
                    iInterval: undefined,
                }) !==
                JSON.stringify({
                    ...animateData.config,
                    iframe: undefined,
                    iInterval: undefined,
                })
        }

        // Now update state.
        this.animationData = { ...animateData }

        if (mapfilesChanged) {
            this.stopAnimation()

            // Turn everything off
            this.animationState.layers.forEach((l) => {
                l.setVisible(false)
            })

            // Add any new layers
            animateData.themes.forEach((theme) =>
                theme.mapfiles.forEach((l) => {
                    let mapserver = this.map.getLayerByName(l.mapfile)
                    if (!mapserver) {
                        mapserver = this.map.addMapserver(
                            l.mapfile,
                            { visibleLayerNames: [l.layer], visible: false },
                            l.layer,
                        )
                        this.animationState.layers.push(mapserver)
                    }
                }),
            )
            this.updateAnimateFrameCache()
        } else if (configChanged) {
            if (wasRunningNowIsStopped) {
                this.stopAnimation()
            }
            if (wasStoppedNowIsRunning) {
                setTimeout(() => this.startAnimation())
            }
            this.setFrameOpacity()
        }
        if (
            (iframeChanged || iIntervalChanged || mapfilesChanged) &&
            !this.animationData.config.started
        ) {
            this.stopAnimation()
            this.setAnimationFrame()
        }
    }

    isAnimating() {
        return this.animationData && this.animationState.layers.length > 0
    }

    startAnimation() {
        this.stopAnimation()

        this.animationState.itransition = 0
        this.animationState.timeout = setTimeout(() =>
            this.startAnimationLoop(),
        )
    }

    stopAnimation() {
        if (this.isAnimating()) {
            if (this.animationState.timeout)
                clearTimeout(this.animationState.timeout)
        }
    }

    /**
     * Initialize the visibility of all cached layers
     *  This is only necessary if the extent has changed because new layers to the map are always visible initially.
     */
    updateAnimateFrameCache() {
        if (this.animationData) {
            // Turn everything off
            this.animationState.layers.forEach((l) => {
                if (l.getVisible()) {
                    l.setVisible(false)
                }
            })

            // Bring the top frame into view.
            const ollayerCurrent =
                this.animationData.config.iframe >= 0
                    ? this.getAnimationLayer(
                          this.animationData.config.iframe,
                          this.animationData.config.iInterval,
                      )
                    : null
            if (ollayerCurrent) {
                ollayerCurrent.setOpacity(this.animationData.config.opacity)
                ollayerCurrent.setVisible(true)
            }

            if (this.animationData.config.animationMode) {
                // Interrupt frame cachine update
                if (this.animationState.cacheUpdateTimeout) {
                    clearTimeout(this.animationState.cacheUpdateTimeout)
                }

                // Pause animation

                // Use a timeout to refresh mapserver files asynchronously.
                // Q: Can I just turn it on and then turn it off?
                let refreshIframe = 0
                const turnOff = (layer) => {
                    layer.setVisible(false)
                }
                const turnOn = () => {
                    const layer = this.getAnimationLayer(
                        refreshIframe,
                        this.animationData.config.iInterval,
                    )
                    if (layer) {
                        if (layer !== ollayerCurrent) {
                            // Prevent flashing of layers as they turn visible
                            layer.setOpacity(0)
                            layer.setVisible(true)

                            // Give the layer time to load before turning off
                            setTimeout(() => turnOff(layer), 100)
                        }

                        // Move to next frame. Stop if we have moved back to the starting frame.
                        refreshIframe += 1
                        if (
                            refreshIframe <
                            this.animationData.themes[
                                this.animationData.config.iInterval
                            ].mapfiles.length
                        ) {
                            this.animationState.cacheUpdateTimeout = setTimeout(
                                turnOn,
                                400,
                            )
                        }
                    } else {
                        console.warn(
                            `updateAnimateFrameCache: layer #${refreshIframe} not available`,
                        )
                    }
                }

                this.animationState.cacheUpdateTimeout = setTimeout(turnOn, 100)
            }

            // Restart animation
            const wasRunning = this.animationData.config.started
            if (wasRunning && this.animationData.config.started) {
                this.startAnimation()
            }
        }
    }

    getAnimationLayer(iframe: number, iInterval): OlLayer {
        let layer
        const order = this.animationData.config.iterationOrder
        const i = order.length ? order[iframe] : iframe
        if (i < this.animationData.themes[iInterval].mapfiles.length) {
            const m = this.animationData.themes[iInterval].mapfiles[i]
            layer = this.animationState.layers.find(
                (l) => l.get("mapfile") === m.mapfile,
            )
        }
        return layer
    }

    // Move the next frame to the top.
    startAnimationLoop() {
        const {
            iInterval,
            speedFrame,
            speedTransition,
            ntransitions,
            opacity,
        } = this.animationData.config

        let inewinterval = this.animationData.config.iInterval
        let inewframe = this.animationData.config.iframe
        if (this.animationData.config.animationType === "frame") {
            inewframe += 1
            if (
                inewframe >=
                this.animationData.themes[iInterval].mapfiles.length
            ) {
                inewframe = 0
            }
        } else if (this.animationData.config.animationType === "interval") {
            inewinterval += 1
            if (inewinterval >= this.animationData.themes.length) {
                inewinterval = 0
            }
        }

        // Move next frame to top, set visible, then make opaque.
        const ollayerCurrent =
            this.animationData.config.iframe >= 0
                ? this.getAnimationLayer(
                      this.animationData.config.iframe,
                      this.animationData.config.iInterval,
                  )
                : null
        const ollayerNew = this.getAnimationLayer(inewframe, inewinterval)
        if (ollayerNew) {
            ollayerNew.setOpacity(0)
            ollayerNew.setVisible(true)

            if (!this.useTransition) {
                // Turn on new frame
                ollayerNew.setOpacity(opacity)
                // Then turn off lower frame.
                if (ollayerCurrent) {
                    ollayerCurrent.setOpacity(0)
                    ollayerCurrent.setVisible(false)
                }
            }
        }

        this.animationData.config.iframe = inewframe
        this.animationData.config.iInterval = inewinterval

        if (this.useTransition) {
            const interval = speedTransition / ntransitions
            // eslint-disable-next-line no-use-before-define
            this.animationState.timeout = setTimeout(
                () => this.animateTransitionLoop(),
                Math.round(interval),
            )
        } else {
            this.animationData.frameChanged(inewframe, inewinterval)
            this.animationState.timeout = setTimeout(
                () => this.startAnimationLoop(),
                speedFrame,
            )
        }
    }

    animateTransitionLoop() {
        const {
            iframe,
            iInterval,
            speedFrame,
            speedTransition,
            ntransitions,
            opacity,
        } = this.animationData.config
        let iframeprev = iframe
        let iIntervalprev = iInterval
        if (this.animationData.config.animationType === "frame") {
            iframeprev =
                (iframe -
                    1 +
                    this.animationData.themes[iInterval].mapfiles.length) %
                this.animationData.themes[iInterval].mapfiles.length
        } else if (this.animationData.config.animationType === "interval") {
            iIntervalprev =
                (iInterval - 1 + this.animationData.themes.length) %
                this.animationData.themes.length
        }
        // Move next frame to top, set visible, then make opaque.
        const ollayerCurrent = this.getAnimationLayer(iframe, iInterval)
        const ollayerPrev = this.getAnimationLayer(iframeprev, iIntervalprev)

        // Check that the layers are still around in case the animationData changed
        if (ollayerCurrent && ollayerPrev) {
            this.animationState.itransition += 1
            const opacityInc = opacity / ntransitions
            if (this.animationState.itransition <= ntransitions) {
                // Notify of new layer at midpoint of transition
                if (this.animationState.itransition === ntransitions / 2.0) {
                    this.animationData.frameChanged(
                        this.animationData.config.iframe,
                        this.animationData.config.iInterval,
                    )
                }

                ollayerCurrent.setOpacity(
                    opacityInc * this.animationState.itransition,
                )
                ollayerPrev.setOpacity(
                    opacityInc *
                        (ntransitions - this.animationState.itransition),
                )
                const interval = speedTransition / ntransitions
                this.animationState.timeout = setTimeout(
                    () => this.animateTransitionLoop(),
                    Math.round(interval),
                )
            } else {
                // All done
                ollayerPrev.setVisible(false)
                ollayerCurrent.setOpacity(opacity)
                this.animationState.itransition = 0
                const interval = Math.max(speedFrame - speedTransition, 100)
                this.animationState.timeout = setTimeout(
                    () => this.startAnimationLoop(),
                    interval,
                )
            }
        }
    }

    setAnimationFrame() {
        this.animationState.layers.forEach((l) => {
            l.setVisible(false)
            l.setOpacity(0)
        })
        const ollayerCurrent = this.getAnimationLayer(
            this.animationData.config.iframe,
            this.animationData.config.iInterval,
        )
        if (ollayerCurrent) {
            ollayerCurrent.setVisible(true)
            ollayerCurrent.setOpacity(this.animationData.config.opacity)
        } else {
            console.warn(
                `setAnimationFrame: frame #${this.animationData.config.iframe} does not exist.`,
            )
        }
    }

    setFrameOpacity() {
        this.animationState.layers.forEach((l) => {
            l.setOpacity(this.animationData.config.opacity)
        })
    }

    unload() {
        this.animationState.layers.forEach((l) => {
            this.map.removeLayer(l)
        })
        this.animationData = null
    }
}

export default LayerAnimation
