// @flow
import React from "react"

const fontSizeSmaller = 12

const styles = {
    containerWide: {
        cursor: "initial",
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20,
    },
    layerRow: {
        marginTop: 2,
        display: "table-row",
    },
    column: {
        width: "50%",
        minWidth: "50%",
        display: "table-cell",
        verticalAlign: "top",
    },
    columnBorder: {
        padding: "2px 4px",
        display: "table-cell",
        fontSize: fontSizeSmaller,
        width: "100%",
    },
    expandButton: {
        // reduce space taken by expandmoreicon
        marginLeft: -30,
        marginRight: -11,
        position: "relative !important",
    },
    aboveColumn: {
        display: "table-cell",
    },
    layerCtr: {
        display: "flex",
        flexDirection: "column",
        marginTop: 0,
        width: "100%",
    },
    layerEntry: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "space-between",
        marginLeft: 0,
        marginRight: 20,
        marginTop: 0,
    },
    layerAccordionSummary: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
    },
    layerLabel: {
        // Needed to keep the label centered.
        margin: "9px 0 0 0",
        whiteSpace: "nowrap",
        maxWidth: 250,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    labelTable: {
        // Needed to keep the label centered.
        margin: "9px 0 0 9px",
        // overflow for long names
        height: 20,
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    layerOverviewItem: {
        display: "inline-block",
        float: "right",
        marginLeft: 10, // prevents overlapping text
    },
    layerOverviewLabel: {
        display: "inline-block",
        marginRight: 4,
        whiteSpace: "nowrap",
        width: 66,
    },
    layerPropsDiv: {
        position: "relative",
    },
    layerTextFieldEdit: {
        flexDirection: "initial",
    },
    legend: {
        display: "block",
        overflow: "auto",
        maxHeight: 150,
    },
    littleButton: {
        fontSize: 16,
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        height: 30,
        width: 30,
    },
    panel: {
        padding: 0,
        // marginTop: 0,
    },
    panelClicked: {
        // background: 'rgba(247, 247, 247)',
    },
    panelExpanded: {
        // marginBottom: 10,
        // marginTop: 0,
        padding: 0,
    },
    panelExpandedRoot: {
        borderTop: "1px solid #cccccc",
        "& expanded": {
            // marginTop: 0,
        },
    },
    panelSummary: {
        padding: 0,
        margin: 0,
        minHeight: 0,
        maxHeight: 45,
        height: 45,
        minWidth: 50, // preventing panelSummary from being too wide when window shrinks
    },
    panelDetails: {
        // Remove flex display
        display: "block",
        padding: 0,
        margin: 0,
    },
    panelSwitch: {
        marginLeft: -6,
    },
}

export default styles
