// @flow
import React, { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import {
    faBars,
    faPencilAlt,
    faSave,
    faUndo,
} from "@fortawesome/free-solid-svg-icons"
import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
} from "@material-ui/core"
import Theme from "@owsi/catena/er2_styles"
import EditManager from "@owsi/catena/er2_map_userlayers/js/components/attribute_table/edit_manager"

type PropsType = {
    editManager: EditManager,
    hasSelectedCells: boolean,
    onCancelEdits: Function,
    onCopyCells: Function,
    onExportTable: Function,
    onJoinTable: Function,
    onSaveEdits: Function,
    onSetEditable: Function,
    onSetFilter: Function,
    onShowAddColumn: Function,
    onShowCreateLayer: Function,
    onShowJoinTable: Function,
    onShowQueryBuilder: Function,
    onToggleEditing: Function,
    onUndoEdit: Function,
    theme: Theme,
}

const OptionsBar = React.forwardRef((props: PropsType, ref) => {
    const [tableMenuActionsAnchor, setTableMenuActionsAnchor] = useState(null)

    const handleTableMenuClick = (event) => {
        setTableMenuActionsAnchor(event.currentTarget)
    }

    const onSetFilter = (e) => {
        const filter = e.target.value
        props.onSetFilter(filter)
    }

    const handleTableMenuClose = (action) => () => {
        setTableMenuActionsAnchor(null)
        if (action === "addColumn") {
            props.onShowAddColumn(true)
        } else if (action === "createLayer") {
            props.onShowCreateLayer(true)
        } else if (action === "export") {
            props.onExportTable()
        } else if (action === "joinTable") {
            props.onShowJoinTable(true)
        } else if (action === "buildQuery") {
            props.onShowQueryBuilder()
        }
    }

    return (
        <div className={props.theme.attributeTableContainer} ref={ref}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid lightgray",
                    padding: 2,
                }}
            >
                <div>
                    <Tooltip title={"Menu of additional options"}>
                        <IconButton
                            className={props.theme.littleButton}
                            onClick={handleTableMenuClick}
                            size={"small"}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit table data"}>
                        <IconButton
                            className={props.theme.littleButton}
                            onClick={props.onToggleEditing}
                            size={"small"}
                        >
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Commit changes to layer"}>
                        {/* span is needed if the child is disabled */}
                        <span>
                            <IconButton
                                className={props.theme.littleButton}
                                disabled={!props.editManager.hasEdits()}
                                onClick={props.onSaveEdits}
                                size={"small"}
                            >
                                <FontAwesomeIcon icon={faSave} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={"Undo edits"}>
                        <span>
                            <IconButton
                                className={props.theme.littleButton}
                                disabled={!props.editManager.hasEdits()}
                                onClick={props.onUndoEdit}
                                size={"small"}
                            >
                                <FontAwesomeIcon icon={faUndo} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={"Copy selected cells to clipboard"}>
                        <span>
                            <IconButton
                                onClick={props.onCopyCells}
                                className={props.theme.littleButton}
                                disabled={props.hasSelectedCells}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
                <div
                    className={"attributeTableControlBar"}
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        textAlign: "right",
                    }}
                >
                    <span
                        style={{
                            display: "inline-block",
                            marginTop: 10,
                            marginRight: 6,
                        }}
                    >
                        Search:
                    </span>
                    <TextField margin="none" onChange={onSetFilter} />
                </div>
            </div>
            <Menu
                id="table-actions-menu"
                anchorEl={tableMenuActionsAnchor}
                className={props.theme.attributeTableMenuItem}
                open={Boolean(tableMenuActionsAnchor)}
                onClose={handleTableMenuClose(null)}
            >
                <MenuItem
                    className={props.theme.attributeTableMenuItem}
                    onClick={handleTableMenuClose("addColumn")}
                >
                    Add Column
                </MenuItem>
                <MenuItem
                    className={props.theme.attributeTableMenuItem}
                    onClick={handleTableMenuClose("createLayer")}
                >
                    Build Layer
                </MenuItem>
                <MenuItem
                    className={props.theme.attributeTableMenuItem}
                    onClick={handleTableMenuClose("export")}
                >
                    Export
                </MenuItem>
                <MenuItem
                    className={props.theme.attributeTableMenuItem}
                    onClick={handleTableMenuClose("buildQuery")}
                >
                    Query (Advanced Search)
                </MenuItem>
                <MenuItem
                    className={props.theme.attributeTableMenuItem}
                    onClick={handleTableMenuClose("joinTable")}
                >
                    Join Table
                </MenuItem>
            </Menu>
        </div>
    )
})

export default OptionsBar
