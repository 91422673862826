const styles = {
    user: {
        display: "inline-flex",
        flexDirection: "row",
        gridColumnStart: "controlBar",
    },
    userButton: {
        margin: 10,
    },
    userContainerDown: {
        display: "inline-flex",
        flexDirection: "column",
    },
    userControl: {
        margin: 20,
    },
    userFormControl: {
        width: 200,
    },
    userTextField: {
        margin: 10,
    },
}

export default styles
