const styles = {
    box: {
        backgroundColor: "white",
        borderBottom: "1px solid #888",
        borderLeft: "1px solid #888",
        borderRadius: "5px 0px 0px 5px",
        borderTop: "1px solid #888",
        fontSize: "0.875em",
        height: 550,
        position: "absolute",
        width: 600,
        zIndex: 500,
    },
    boxTableProps: {
        backgroundColor: "white",
        position: "absolute",
        zIndex: "200",
        height: "350px",
        borderRadius: "5px 0px 0px 5px",
        borderLeft: "1px solid #888",
        borderTop: "1px solid #888",
        borderBottom: "1px solid #888",
    },
    flexCol: {
        display: "flex",
        flexDirection: "column",
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
    },
    iconButton: {
        maxWidth: 20,
        maxHeight: 20,
        padding: 0,
    },
    iconPushRight: {
        marginLeft: 3,
    },
    iconStyle: {
        width: "fit-content",
        fontSize: 18,
    },
    miniAddIcon: {
        borderRadius: "100%",
        display: "inline",
        fontSize: "13px",
        marginRight: "-9px",
        position: "relative",
        right: "7px",
        top: "7px",
    },
    slider: {
        margin: "10px 0px",
    },
    sliderDot: {
        height: "10px",
        width: "10px",
        border: "1px",
    },
    layerPropsClassificationClassesInput: {
        width: 40,
    },
    layerPropsClassificationButton: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    layerPropsClassificationFormControl: {
        marginLeft: 20,
    },
    layerPropsUpdateButton: {
        bottom: "2px",
        margin: "6px",
        right: "90px",
        position: "absolute",
    },
    layerPropsCloseButton: {
        bottom: "2px",
        right: "2px",
        margin: "6px",
        position: "absolute",
    },
    metadataContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
    },
    leftCellHead: {
        textAlign: "left",
    },
    colorLabel: {
        float: "left",
        marginLeft: "5%",
    },
    colorBoxLeft: {
        float: "right",
        marginRight: "5%",
    },
    colorBoxRight: {
        float: "right",
    },
    colorScalePicker: {
        maxHeight: 300,
    },
    generateButton: {
        width: "50%",
    },
    generateCell: {
        textAlign: "center",
        // width: '50%',
    },
    tableItem: {
        textAlign: "center",
    },
    tableContainer: {
        overflow: "auto",
        maxHeight: "140px",
    },
    symbologyTab: {
        // textAlign: center,
    },
    symTableContainer: {
        borderBottom: "1px solid lightgray",
        height: 200,
        overflow: "auto",
    },
    symTableHead: {
        backgroundColor: "#f3f3f3",
        marginTop: "40px",
    },
    symTable: {
        borderCollapse: "collapse",
        borderRadius: "6px",
    },
    symTableCellCenter: {
        fontWeight: "normal",
        textAlign: "center",
        border: "1px solid #ccc",
        padding: 4,
    },
    symTableCellLeft: {
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        margin: "0px",
        textAlign: "center",
    },
    symTableCellRight: {
        textAlign: "center",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        margin: "0px",
    },
    inputStyle: {
        width: 100,
        marginRight: 20,
    },
    inputStyleNumber: {
        width: 40,
        marginRight: 20,
    },
    labelSpacer: {
        width: "14%",
    },
    symbolLabel: {
        width: "1%",
    },
    paramLabel: {
        display: "inline-block",
        width: 120,
    },
    paramValue: {
        display: "inline-block",
        marginLeft: 10,
    },
    sectionLabel: {
        fontSize: 16,
        margin: "10px 0px 10px 0px",
        // borderBottom: '1px solid #ccc',
        textAlign: "center",
    },
    extentTable: {
        textAlign: "center",
        width: "100%",
    },
    fieldset: {
        margin: "20px 0px 10px 0px",
        borderRadius: "5px",
        borderColor: "#fff",
    },
    metadataLabel: {
        marginRight: 10,
        width: 200,
    },
    layerPropsTable: {
        marginLeft: "auto",
        marginRight: "auto",
    },
    labelSelect: {
        display: "flex",
        minWidth: 150,
        marginLeft: 20,
    },
    layerSelect: {
        display: "flex",
        minWidth: 130,
        marginRight: 10,
        paddingBottom: 20,
    },
    listItem: {
        fontSize: 13,
    },
    tag: {
        margin: "5px",
    },
    tagBox: {
        marginLeft: 80,
        marginTop: "10px",
        width: "400px",
        maxHeight: "200px",
        overflow: "auto",
    },
    tabLabel: {
        boxSizing: "border-box",
        color: (props) => props.contrastTextColor,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    tabLabelSmall: {
        boxSizing: "border-box",
        // color: (props) => props.contrastTextColor,
        minWidth: 100,
        width: 100,
    },
    // for styling tab labels Might not be needed
    tabLabelSmallNoTextColorContrast: {
        boxSizing: "border-box",
        minWidth: 100,
        width: 100,
    },
    tabScrollButton: {
        color: (props) => props.contrastTextColor,
    },
    tableLabel: {
        width: "43%",
    },
    table: {
        display: "table",
        width: "100%",
    },
    tableRow: {
        display: "table-row",
    },
    tableCell: {
        display: "table-cell",
    },
    userMetadata: {
        maxHeight: 200,
        overflow: "auto",
    },
    viewTabContainer: {
        display: "flex",
        flexFlow: "row nowrap",
    },
    viewContainer: {
        // border: '1px solid black',
        margin: 3,
    },
    viewButtons: {
        marginTop: 50,
        textAlign: "center",
    },
    viewEntry: {
        border: "1px solid transparent",
        cursor: "pointer",
        fontSize: 13,
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
    },
    viewEntrySelected: {
        backgroundColor: "#eeeeee",
        fontSize: 13,
        fontWeight: "bold",
        cursor: "pointer",
    },
    viewNameLabel: {
        display: "inline-block",
        whiteSpace: "nowrap",
    },
    viewList: {
        width: 160,
    },
    viewSelect: {
        fontSize: 13,
    },
}

export default styles
