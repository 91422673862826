// @flow
import React from "react"
import ReactDOM from "react-dom"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import LineString from "ol/geom/LineString"
import Point from "ol/geom/Point"
import Polygon from "ol/geom/Polygon"
import OlMap from "ol/Map"
import OlVectorLayer from "ol/layer/Vector"
import OlVectorSource from "ol/source/Vector"
import Overlay from "ol/Overlay"
import Circle from "ol/style/Circle"
import Draw from "ol/interaction/Draw"
import Fill from "ol/style/Fill"
import Stroke from "ol/style/Stroke"
import Style from "ol/style/Style"
import Text from "ol/style/Text"
import { singleClick as SingleClickCondition } from "ol/events/condition"
import { getArea, getLength } from "ol/sphere"
import { unByKey } from "ol/Observable"
import Modify from "ol/interaction/Modify"
import Theme from "@owsi/catena/er2_styles"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

type Props = {
    olMap: OlMap,
    theme: Theme,
}

type StateProps = {
    distanceUnits: string,
    drawToolActive: string,
    featureCount: number,
    method: string,
}

class GeoBarDistance extends React.Component<Props, StateProps> {
    constructor(props) {
        super(props)
        this.state = {
            distanceUnits: "English",
            drawToolActive: "",
            featureCount: 0,
            method: null,
        }
    }

    componentDidMount() {
        this.vectorSource = new OlVectorSource({
            projection: "EPSG:4326",
        })
        this.vectorLayer = new OlVectorLayer({
            source: this.vectorSource,
            style: (feat, res) => this.getStyle(feat, res),
            zIndex: 99,
        })

        this.props.olMap.addLayer(this.vectorLayer)
        this.props.olMap.render()

        this.modify = new Modify({
            source: this.vectorSource,
            deleteCondition: SingleClickCondition,
        })
        this.props.olMap.addInteraction(this.modify)
    }

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        if (this.draw) {
            this.props.olMap.removeInteraction(this.draw)
            this.props.olMap.removeInteraction(this.modify)
        }
        this.clearOverlays()
        this.props.olMap.removeLayer(this.vectorLayer)
    }

    clearFeatures = () => {
        this.vectorSource.clear()
        this.setState({ featureCount: 0 })
    }

    clearOverlays() {
        this.props.olMap
            .getOverlays()
            .getArray()
            .slice(0)
            .forEach((overlay) => {
                this.props.olMap.removeOverlay(overlay)
            })
    }

    getStyle(feature) {
        const geometry = feature.getGeometry()
        const styles = [
            new Style({
                fill: new Fill({
                    color: "rgba(255, 255, 255, 0.2)",
                }),
                stroke: new Stroke({
                    color: "#ffcc33",
                    lineDash: [10, 10],
                    width: 2,
                }),
            }),
        ]
        let totalDistance = 0

        const segmentText = (coord, coord2, rotation) => {
            let coordText
            if (coord2) {
                ;[coordText, length] = this.formatLength(
                    new LineString([coord2, coord]),
                    totalDistance,
                )
                totalDistance += length
            } else {
                coordText = 0
            }

            return new Text({
                fill: new Fill({
                    color: "#00f",
                }),
                font: '14px "Roboto Condensed", sans-serif',
                offsetY: 25,
                align: "center",
                // rotation: -rotation,
                scale: 1,
                text: coordText,
            })
        }

        function createSegmentStyle(coord, coord2, rotation) {
            return new Style({
                geometry: new Point(coord),
                image: new Circle({
                    radius: 5,
                    fill: null,
                    stroke: new Stroke({ color: "orange", width: 2 }),
                }),
                text: segmentText(coord, coord2, rotation),
            })
        }

        function createPolygonStyle(polyGeom, text) {
            const coord = polyGeom.getInteriorPoint()
            const htmlElem = document.createElement("div")

            ReactDOM.render(
                <span dangerouslySetInnerHTML={{ __html: text }} />,
                htmlElem,
            )
            console.log(htmlElem.textContent)
            return new Style({
                geometry: coord,
                image: new Circle({
                    radius: 5,
                    fill: null,
                    stroke: new Stroke({ color: "orange", width: 2 }),
                }),
                text: new Text({
                    fill: new Fill({
                        color: "#00f",
                    }),
                    font: '14px "Roboto Condensed", sans-serif',
                    align: "center",
                    offsetY: -15,
                    scale: 1,
                    text: htmlElem.textContent,
                }),
            })
        }

        if (geometry instanceof LineString) {
            const firstCoord = geometry.getFirstCoordinate()
            geometry.forEachSegment((start, end) => {
                const dx = end[0] - start[0]
                const dy = end[1] - start[1]
                const rotation = Math.atan2(dy, dx)
                if (firstCoord[0] === start[0] && firstCoord[1] === start[1]) {
                    styles.push(createSegmentStyle(start, null, rotation))
                }
                styles.push(createSegmentStyle(end, start, rotation))
            })
        } else {
            // Create single text style with area
            styles.push(createPolygonStyle(geometry, this.formatArea(geometry)))
        }
        return styles
    }

    activateDrawTool(method) {
        if (this.draw) {
            this.props.olMap.removeInteraction(this.draw)
            this.draw = null
        }

        if (this.state.drawToolActive === method) {
            // Toggle off
            this.setState({ drawToolActive: "" })
        } else {
            const type = method === "area" ? "Polygon" : "LineString"
            this.draw = new Draw({
                source: this.vectorSource,
                type,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(255, 255, 255, 0.2)",
                    }),
                    stroke: new Stroke({
                        color: "rgba(0, 0, 0, 0.5)",
                        lineDash: [10, 10],
                        width: 2,
                    }),
                    image: new Circle({
                        radius: 5,
                        stroke: new Stroke({
                            color: "rgba(0, 0, 0, 0.7)",
                        }),
                        fill: new Fill({
                            color: "rgba(255, 255, 255, 0.2)",
                        }),
                    }),
                }),
            })
            this.props.olMap.addInteraction(this.draw)

            this.createMeasureTooltip()
            this.createHelpTooltip()

            this.draw.on(
                "drawstart",
                (evt) => {
                    // set sketch
                    this.sketch = evt.feature

                    let tooltipCoord = evt.coordinate

                    this.listener = this.sketch
                        .getGeometry()
                        .on("change", (e) => {
                            const geom = e.target
                            let output
                            let dist
                            if (geom instanceof Polygon) {
                                output = this.formatArea(geom)
                                tooltipCoord = geom
                                    .getInteriorPoint()
                                    .getCoordinates()
                            } else if (geom instanceof LineString) {
                                ;[output, dist] = this.formatLength(geom, 0)
                                tooltipCoord = geom.getLastCoordinate()
                            }
                            this.measureTooltipElement.innerHTML = output
                            this.measureTooltip.setPosition(tooltipCoord)
                        })
                },
                this,
            )

            this.draw.on(
                "drawend",
                () => {
                    this.clearOverlays()
                    // unset sketch
                    this.sketch = null
                    // unset tooltip so that a new one can be created
                    this.measureTooltipElement = null
                    this.props.olMap.removeInteraction(this.draw)
                    this.draw = null
                    unByKey(this.listener)
                    this.setState({
                        drawToolActive: "",
                        featureCount:
                            this.vectorSource.getFeatures().length + 1,
                    })
                },
                this,
            )

            this.setState({ drawToolActive: method })
        }
    }

    changeUnits = (e) => {
        this.setState({ distanceUnits: e.target.value })
        this.vectorLayer.changed()
    }

    createHelpTooltip() {
        if (this.helpTooltipElement) {
            this.helpTooltipElement.parentNode.removeChild(
                this.helpTooltipElement,
            )
        }
        this.helpTooltipElement = document.createElement("div")
        this.helpTooltipElement.className = "tooltip hidden"
        this.helpTooltip = new Overlay({
            element: this.helpTooltipElement,
            offset: [15, 0],
            positioning: "center-left",
        })
        this.props.olMap.addOverlay(this.helpTooltip)
    }

    createMeasureTooltip() {
        if (this.measureTooltipElement) {
            this.measureTooltipElement.parentNode.removeChild(
                this.measureTooltipElement,
            )
        }
        this.measureTooltipElement = document.createElement("div")
        this.measureTooltipElement.className = "tooltip tooltip-measure"
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: "bottom-center",
        })
        this.props.olMap.addOverlay(this.measureTooltip)
    }

    drawArea = () => {
        this.activateDrawTool("area")
    }

    drawDistance = () => {
        this.activateDrawTool("line")
    }

    formatLength(line, totalDistance) {
        const length = getLength(line)
        let measuredLength = length + totalDistance
        let largeUnitsConv = 1000
        if (this.state.distanceUnits === "English") {
            measuredLength *= 3.28084
            largeUnitsConv = 5280
        }
        let output
        let units = "m"
        if (measuredLength > largeUnitsConv) {
            units = "km"
            if (this.state.distanceUnits === "English") {
                units = "mi"
            }
            output = `${
                Math.round((measuredLength / largeUnitsConv) * 100) / 100
            } ${units}`
        } else {
            if (this.state.distanceUnits === "English") {
                units = "ft"
            }
            output = `${Math.round(measuredLength * 100) / 100} ${units}`
        }
        return [output, length]
    }

    /**
     * Format area output.
     * @return {string} Formatted area.
     */
    formatArea(polygon) {
        let area = getArea(polygon)
        let largeUnitsConv = 1000 * 1000
        if (this.state.distanceUnits === "English") {
            area *= 3.28084 * 3.28084
            largeUnitsConv = 5280 * 5280
        }
        let output
        let units = "m<sup>2</sup>"
        if (area > largeUnitsConv) {
            units = "km<sup>2</sup>"
            if (this.state.distanceUnits === "English") {
                units = "mi<sup>2</sup>"
            }
            output = `${
                Math.round((area / largeUnitsConv) * 100) / 100
            } ${units}`
        } else {
            if (this.state.distanceUnits === "English") {
                units = "ft<sup>2</sup>"
            }
            output = `${Math.round(area * 100) / 100} ${units}`
        }
        return output
    }

    renderTool() {
        const { theme } = this.props
        const distances = ["English", "Metric"]
        return (
            <div className={theme.toolCtr}>
                <div className={theme.toolRow}>
                    <FormControl style={{ width: 120 }}>
                        <InputLabel htmlFor={"distance"}>
                            Units Family
                        </InputLabel>
                        <Select
                            onChange={this.changeUnits}
                            value={this.state.distanceUnits}
                            inputProps={{
                                name: "distance",
                                id: "distance",
                            }}
                        >
                            {distances.map((s) => (
                                <MenuItem key={s} value={s}>
                                    {s}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div title={"Remove drawing features"}>
                        <IconButton
                            color={"primary"}
                            disabled={this.state.featureCount === 0}
                            onClick={this.clearFeatures}
                            variant={"contained"}
                        >
                            <i className={"fas fa-eraser"} />
                        </IconButton>
                    </div>
                </div>
                <div className={theme.toolRow}>
                    <Button
                        color={
                            this.state.drawToolActive === "line"
                                ? "primary"
                                : "default"
                        }
                        onClick={this.drawDistance}
                        variant={"contained"}
                    >
                        <span>Distance</span>
                    </Button>
                    <Button
                        color={
                            this.state.drawToolActive === "area"
                                ? "primary"
                                : "default"
                        }
                        onClick={this.drawArea}
                        style={{ marginLeft: 30 }}
                        variant={"contained"}
                    >
                        <span>Area</span>
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        const theme = this.props.theme

        return <div className={theme.geoBarCtr}>{this.renderTool(theme)}</div>
    }
}

export default GeoBarDistance
