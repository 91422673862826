import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
// import { persistCache } from "apollo-cache-persist"

export const relayConnectionNodes = (connection) =>
    connection ? connection.edges.map((edge) => edge.node) : []

export const cache = new InMemoryCache({
    typePolicies: {
        HftTrackedGridCell: {
            keyFields: ["GridId"],
        },
        HftTrackedGridCellMutation: {
            keyFields: ["GridId"],
        },
        FishEfQuery: {
            merge: true,
        },
        BirdModelQuery: {
            merge: true,
        },
    },
})

export const client = new ApolloClient({
    cache,
    link: new HttpLink({
        uri: "/graphql/v2/",
    }),
})

// persistCache({
//     cache,
//     storage: window.localStorage,
// }).then(
//     () =>
//         new ApolloClient({
//             cache,
//             link: new HttpLink({
//                 uri: "/graphql/v1/",
//             }),
//         })
// )
