// @flow
// Functions for grouping mapsources
import React from "react"
import { compare } from "@owsi/catena/er2_map_userlayers/js/utils"
import type { GroupingType, OrderingType } from "./components/mapsources"
import type { MapsourcesType } from "./reducers/mapsources"

/**
 * Single group.
 * @param mapsources
 * @param sortBy Field to sort by
 * @returns {Array}
 */
export const getGroupsForTypeNone = (mapsources, sortBy: string) => {
    const layers = []
    const tables = []
    mapsources.forEach((mapsource) => {
        if (mapsource.type.includes("mapserver")) {
            mapsource.layers.forEach((l) =>
                l.layer_type.toLowerCase() === "table"
                    ? tables.push(l)
                    : layers.push(l),
            )
        } else if (mapsource.type === "arcgisrest") {
            layers.push(mapsource)
        } else if (mapsource.type === "timeseries") {
            layers.push(mapsource)
        }
    })
    if (sortBy) {
        layers.sort((l1, l2) => l1[sortBy] < l2[sortBy])
        tables.sort((t1, t2) => t1[sortBy] < t2[sortBy])
    }
    const groups = []
    if (layers.length) {
        groups.push({
            layers,
            name: "Layers",
        })
    }
    if (tables.length) {
        groups.push({
            layers: tables,
            name: "Tables",
        })
    }
    return groups
}

export const getGroupsForTypeLayer = (mapsources) => {
    const groups = []
    const types = [
        "Point",
        "Line",
        "Polygon",
        "Service",
        "Raster",
        "Table",
        "Timeseries",
    ]
    types.forEach((layerType) => {
        const layerGroup = {
            layers: [],
            name: layerType,
        }
        mapsources.forEach((mapsource) => {
            if (mapsource.type.includes("mapserver")) {
                mapsource.layers.forEach((layer) => {
                    if (
                        layer.layer_type
                            .toLowerCase()
                            .indexOf(layerType.toLowerCase()) >= 0
                    ) {
                        layerGroup.layers.push(layer)
                    }
                })
            } else if (
                mapsource.type === "arcgisrest" &&
                layerType === "Service"
            ) {
                layerGroup.layers.push(mapsource)
            } else if (
                mapsource.type === "timeseries" &&
                layerType === "Timeseries"
            ) {
                layerGroup.layers.push(mapsource)
            }
        })
        if (layerGroup.layers.length) {
            groups.push(layerGroup)
        }
    })
    return groups
}

export const getGroupsForTypeTag = (mapsources) => {
    const groups = []
    // Get all the tags
    const mapserverSources = mapsources.filter((m) =>
        m.type.includes("mapserver"),
    )
    const servicesTag = "Services"
    const alltags = [servicesTag]
    mapserverSources.forEach((m) =>
        m.layers.forEach((l) => {
            if (l.tags) alltags.push(...l.tags)
        }),
    )
    const tags = Array.from(new Set(alltags)).sort()
    tags.forEach((tag) => {
        const layerGroup = {
            layers: [],
            name: tag || "???",
        }
        mapsources.forEach((mapsource) => {
            if (mapsource.type.includes("mapserver")) {
                mapsource.layers.forEach((layer) => {
                    const layerTags = layer.tags || [undefined]
                    const hasTag = layerTags.indexOf(tag) >= 0
                    if (hasTag) {
                        layerGroup.layers.push(layer)
                    }
                })
            } else if (tag === servicesTag) {
                layerGroup.layers.push(mapsource)
            }
        })
        // Append services? Maybe not.
        if (layerGroup.layers.length) {
            groups.push(layerGroup)
        }
    })
    return groups
}

export const getGroupsForTypeCategory = (mapsources) => {
    const groups = []
    const types = ["My Layers", "Public", "Timeseries"]
    types.forEach((cat) => {
        const layerGroup = {
            layers: [],
            name: cat,
        }
        mapsources.forEach((mapsource) => {
            if (mapsource.type.includes("mapserver") && cat === "My Layers") {
                mapsource.layers.forEach((layer) => {
                    layerGroup.layers.push(layer)
                })
            } else if (mapsource.type === "arcgisrest" && cat === "Public") {
                layerGroup.layers.push(mapsource)
            } else if (
                mapsource.type === "timeseries" &&
                cat === "Timeseries"
            ) {
                layerGroup.layers.push(mapsource)
            }
        })
        // Append services? Maybe not.
        if (layerGroup.layers.length) {
            groups.push(layerGroup)
        }
    })
    return groups
}

export const sortGroup = (group, orderingType) => {
    if (group.layers) {
        if (orderingType === "asc") {
            group.layers.sort((a, b) => compare(a.name, b.name))
        } else if (orderingType === "desc") {
            group.layers.sort((a, b) => compare(a.name, b.name)).reverse()
        }
    }
    return group
}

/**
 * Update the state with the new grouping.
 * @param groupingType
 * @param orderingType 'asc' or 'desc'
 * @param mapsources Either new mapsources or the current
 * @returns {Array}
 */
export const getGrouping = (
    groupingType: GroupingType,
    orderingType: OrderingType,
    mapsources: MapsourcesType,
) => {
    // For now grouping is by mapsource
    let groups = []
    if (groupingType === "none") {
        groups = getGroupsForTypeNone(mapsources)
    } else if (groupingType === "category") {
        groups = getGroupsForTypeCategory(mapsources)
    } else if (groupingType === "layerType") {
        groups = getGroupsForTypeLayer(mapsources)
    } else if (groupingType === "tag") {
        groups = getGroupsForTypeTag(mapsources)
    } else {
        // Assume sort on metadata
        groups = getGroupsForTypeNone(mapsources, groupingType)
        if (orderingType === "asc") groups[0].layers.sort()
        else if (orderingType === "desc") groups[0].layers.sort().reverse()
    }
    return groups.map((g) => sortGroup(g, orderingType))
}

/**
 * Filter layers in each group based on layer metadata
 * @param groups
 */
export const filterGroups = (groups, filters) => {
    if (filters.length) {
        groups.forEach((group) => {
            group.layers = group.layers.filter((l) => {
                let hasMatch = true
                // If the layer has tags, then search them
                if (l.tags && l.tags.length) {
                    const matchedTags = filters.filter((f) =>
                        l.tags.filter((t) => t === f),
                    )
                    // Every tag must match
                    hasMatch = matchedTags.length === filters.length
                }
                if (hasMatch) {
                    // Filter name using first filter
                    hasMatch = l.name.indexOf(filters[0]) >= 0
                }
                return hasMatch
            })
        })
    }
    return groups
}

/**
 * Change the ordering of the layers in each mapsource to match the group ordering
 * @param groups
 * @param mapsources
 */
export function reorderMapsourcesFromGroups(groups, mapsources) {
    const allLayers = groups.reduce(
        (current, g) => current.concat(g.layers),
        [],
    )
    mapsources.forEach((m) => {
        const sortedLayers = []
        allLayers.forEach((l) => {
            if (l.mapsource === m.name) {
                sortedLayers.push(l)
            }
        })
        m.layers = sortedLayers
    })
    return [...mapsources]
}

/**
 *
 * @param groupSortedName Name of the group that was sorted
 * @param layersSorted The list of layers in the group that were sorted
 * @param groups Groups state
 * @param mapsources Mapsources state
 */
export function reorderMapsourcesFromLayers(
    groupSortedName,
    layersSorted,
    groups,
    mapsources,
) {
    let layersOrdered = []
    const newgroups = []
    groups.forEach((group) => {
        let groupCopy = group
        if (groupSortedName === group.name) {
            // The input layers list is a subset of the layer object, so replace each one with their actual layer object
            const layersComplete = layersSorted.map((sortedLayer) =>
                group.layers.find(
                    (groupLayer) => groupLayer.name === sortedLayer.name,
                ),
            )
            layersOrdered = [
                ...layersOrdered,
                ...layersComplete.map((l) => ({
                    name: l.name,
                    mapsource: l.mapsource,
                })),
            ]
            groupCopy = { ...group, layers: layersComplete }
        } else layersOrdered = [...layersOrdered, ...group.layers]
        newgroups.push(groupCopy)
    })

    // Second ordering is public layers and the userlayers. Userlayers are one entity for our purposes because
    //   they are all represented by a single mapfile.
    let mapsourcesOrdered = []
    layersSorted.forEach((l) => {
        const mapsource = mapsources.find((m) => m.name === l.mapsource)
        if (mapsourcesOrdered.indexOf(mapsource) < 0) {
            mapsourcesOrdered.push(mapsource)
        }
    })
    // Append missing mapsources
    mapsources.forEach((m) => {
        if (mapsourcesOrdered.indexOf(m) < 0) {
            mapsourcesOrdered.push(m)
        }
    })
    // Update the ordering of the layers within each mapsource to match the groups
    mapsourcesOrdered = reorderMapsourcesFromGroups(
        newgroups,
        mapsourcesOrdered,
    )
    return { groups: newgroups, mapsources: mapsourcesOrdered }
}
