// @flow
import * as types from "../constants/action_types"
import { setUserLayers } from "@owsi/catena/er2_map_userlayers/js/actions/map"

/**
 *
 * @param siteData Array of [site_name, service_name]
 * @returns {Function}
 */
export const onAddTimeseriesSource = (mongoHost, mongoDatabase, collection) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    // Update current visibility to be default visibility
    fetch(`/er2_map/api/v1/timeseries/add_collection/?token=${token.value}`, {
        body: JSON.stringify({
            mongo_host: mongoHost,
            mongo_db: mongoDatabase,
            collection,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) => {
            dispatch(setUserLayers(json.state.mapsources, json.state.mapfile))
        })
}

export const onDeleteTimeseries = (mapsource_def) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    fetch(`/er2_map/delete_timeseries/?token=${token.value}`, {
        body: JSON.stringify({}),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) => {
            dispatch(setUserLayers(json.state.mapsources, json.state.mapfile))
        })
}

export const onTimeseriesDownload = (service) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token
    fetch(`/er2_map/export_timeseries_layer/?token=${token.value}`, {
        body: JSON.stringify({}),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((ret) => ret.json())
        .then((json) =>
            dispatch(setUserLayers(json.state.mapsources, json.state.mapfile)),
        )
}
