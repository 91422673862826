import React from "react"
import { Box, Button, Divider } from "@material-ui/core"

export const ErrorMessage: React.FC<{
    error: Error
    resetErrorBoundary: () => void
}> = ({ error, resetErrorBoundary }) => {
    return (
        <Box
            role="alert"
            display="flex"
            flexDirection="column"
            alignItems="center"
            px={3}
            py={6}
            flex="1 1"
        >
            <p>Something went wrong:</p>
            <pre style={{ whiteSpace: "pre-wrap" }}>{error.message}</pre>
            <Button
                size="small"
                variant="outlined"
                onClick={resetErrorBoundary}
            >
                Hide this message
            </Button>
        </Box>
    )
}
