// @flow
import { onSetTabbedPanelKey } from "@owsi/catena/er2_map_userlayers/js/actions/tabbed_panel"
import * as actionTypes from "../constants/action_types"
import { windowStates } from "../components/window_state"
import { appendToLogger } from "../actions/logger"
import {
    onUpdateUserLayers,
    setMapError,
    setMapMessage,
    updateUserLayer,
} from "../actions/map"
import type { UserLayerType, UserMapsourceType } from "../reducers/mapsources"

export const createLayerFromTable = (
    mapsourceName: string,
    layerName: string,
    mode: string,
    state: {},
) => (dispatch: Function, getState: Function) => {
    const token = getState().token.value
    let hasError = false
    fetch(`/er2_map/api/v1/create_layer_from_table/?token=${token}`, {
        body: JSON.stringify({
            mapsource_name: mapsourceName,
            layer: layerName,
            mode,
            state,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((res) => {
            hasError = res.status !== 200
            if (hasError) return res.text()
            return res.json()
        })
        .then((res) => {
            if (hasError) {
                dispatch(setMapError(`Geocoded layer ${res.layer.name}`))
                dispatch(
                    appendToLogger([`Got failure to create layer: ${res}`]),
                )
            } else {
                dispatch(setMapMessage(`Geocoded layer ${res.layer.name}`))

                // TODO: group layers ordering needs to be added to state
                // dispatch(onUpdateGroupLayerOrdering())
                dispatch(
                    appendToLogger([`Created layer ${res.layer.name}`], true),
                )
                dispatch(onUpdateUserLayers())
            }
        })
        .catch((res) => {
            console.error(res)
        })
}

export const joinTable = (
    mapsourceName: string,
    layerName: string,
    joinField: string,
    sourceLayer: string,
    sourceField: string,
    joinLayerName: string,
) => (dispatch: Function, getState: Function) => {
    const token = getState().token.value
    let hasError = false
    dispatch(appendToLogger(["Start join table."]))
    fetch(`/er2_map/api/v1/table_join/?token=${token}`, {
        body: JSON.stringify({
            mapsource_name: mapsourceName,
            layer: layerName,
            joinField,
            sourceLayer,
            sourceField,
            joinLayerName,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((res) => {
            hasError = res.status !== 200
            return res.json()
        })
        .then((res) => {
            if (hasError) {
                dispatch(setMapError(res.exception.message))
                dispatch(appendToLogger([`Got failure to join table: ${res}`]))
            } else {
                dispatch(setMapMessage(`Created layer/table ${res.layer.name}`))
                dispatch(
                    appendToLogger(
                        [`Created layer/table ${res.layer.name}`],
                        true,
                    ),
                )
                dispatch(onUpdateUserLayers())
            }
        })
        .catch((res) => {
            console.error(res)
        })
}

export const saveEdits = (layer: string, mapsource: string, edits: []) => (
    dispatch: Function,
    getState: Function,
) => {
    // Get the layer properties
    const state = getState()
    let hasError = false
    fetch(`/er2_map/save_layer_edits/?token=${state.token.value}`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            edits,
            layer,
            mapsource,
        }),
    })
        .then((res) => {
            hasError = res.status !== 200
            dispatch(appendToLogger([`Saving edits for layer ${layer}`]))
            return res.json()
        })
        .then((json) => {
            if (hasError) {
                dispatch(setMapMessage(json.exception.message))
                dispatch(
                    appendToLogger([
                        `Got failure to save edits to table: ${json.exception.message}`,
                    ]),
                )
            } else {
                // Res contains the new layer state
                dispatch(updateUserLayer(json.layer, json.layer))
                dispatch(setMapMessage("Edits saved"))
            }
        })
        .catch((err) => (res) =>
            dispatch(
                appendToLogger([
                    `Failed to save edits for layer ${layer}: ${res}`,
                ]),
            ),
        )
}

/**
 * Open attribute table for the given layer. Note that the attribute table component will retrieve the row data.
 * @param layer
 * @param querying True if the attribute table is being queried and should be displayed
 * @returns {Function}
 */
export const onUserLayerGetAttributes = (
    layer: UserLayerType,
    querying: boolean,
) => (dispatch: Function, getState: Function) => {
    const state = getState()
    dispatch({
        type: actionTypes.ATTRIBUTE_TABLE_SHOW,
        attributeTable: { ...layer, querying },
    })
    dispatch(onSetTabbedPanelKey(layer.name))
    if (state.tabbedPanel.windowState === windowStates.minimized) {
        dispatch({
            type: actionTypes.TABBED_PANEL_WINDOW_STATE,
            windowState: windowStates.opened,
        })
    }
}

export const onExportTable = (mapsourceName, layerName) => (
    dispatch: Function,
    getState: Function,
) => {
    const token = getState().token.value
    window.open(
        `/er2_map/export_table/?token=${token}&mapsource_name=${mapsourceName}&layer=${encodeURIComponent(
            layerName,
        )}&foo=${Math.random()}`,
    )
}
