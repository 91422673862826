// @flow
import React from "react"
import stringify from "json-stringify-safe"
import { parse } from "query-string"
import { Circle as CircleGeom } from "ol/geom"
import { fromCircle } from "ol/geom/Polygon"
import { Circle, Fill, Icon, RegularShape, Stroke, Style, Text } from "ol/style"
import type {
    MapsourcesType,
    UserLayerType,
    UserMapsourceType,
} from "./reducers/mapsources"

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    )
}

export const nullFunc = () => {}

export const isEmpty = (d) => !d || Object.keys(d).length === 0

export const setFocus = (e) => e.target.select()

export function layerIsVisible({ scaleMin, scaleMax }, mapScale) {
    let ret = true
    if (scaleMin && scaleMin > mapScale) {
        ret = false
    } else if (scaleMax && scaleMax < mapScale) {
        ret = false
    }
    return ret
}

/*
 * Converts a raw location object from the browser window into an object
 * that is easier to parse and query.
 */
export function parseLocation(location: { pathname: string, search: string }) {
    return { path: location.pathname, params: parse(location.search) || {} }
}

export function range(startAt, endAt, interval = 1) {
    const size = (endAt - startAt) / interval
    return [...Array(size).keys()].map((i) => i * interval + startAt)
}

/**
 * Compares two strings alphabetically.
 *
 * If `reversed`, will sort with a descending order.
 */
export function compare(
    a: string,
    b: string,
    reversed: boolean = false,
    caseInsensitive: boolean = true,
) {
    let aFormatted = a
    let bFormatted = b
    if (caseInsensitive) {
        aFormatted = a.toLowerCase()
        bFormatted = b.toLowerCase()
    }
    if (aFormatted < bFormatted) {
        return reversed ? 1 : -1
    } else if (aFormatted > bFormatted) {
        return reversed ? -1 : 1
    }
    return 0
}

export function round(v, prec) {
    const t = 10 ** prec
    const rounded = Math.round(v * t) / t
    // console.log(`rounded ${v} -> ${rounded}`)
    return rounded
}

// Return the mapsource for user layers
export const getMapsource = (
    mapsources: MapsourcesType,
    layer: UserLayerType,
) => {
    if (!layer) {
        // Just return the default mapsource.
        return mapsources.mapsources.find(
            (mapsource) => mapsource.name === "default",
        )
    }
    if (layer.type && layer.type === "arcgisrest") {
        // For ArcGISrest layers, the layer is the mapsource
        return layer
    }
    return mapsources.mapsources.find((mapsource) =>
        mapsource.layers.find((l) => l.name === layer.name),
    )
}

/**
 * Get the flat list of layers
 * @param mapsources
 */
export const getLayers = (mapsources: MapsourcesType) => {
    let ret = []
    mapsources.mapsources.forEach((mapsource) => {
        if (mapsource.type === "mapserver") {
            ret = [...ret, ...mapsource.layers]
        }
    })
    return ret.sort((a, b) => compare(a.name, b.name))
}

export const getVectorLayers = (
    mapsources: MapsourcesType,
    specificVectorTypes,
) => {
    const vectorTypes = specificVectorTypes || [
        "Point",
        "MultiPoint",
        "Line",
        "LineString",
        "Polygon",
        "MultiPolygon",
    ]
    return getLayers(mapsources).filter((l) =>
        vectorTypes.includes(l.layer_type),
    )
}

export const getPolygonVectorLayers = (mapsources: MapsourcesType) =>
    getVectorLayers(mapsources, ["Polygon", "MultiPolygon"])

export const getPointVectorLayers = (mapsources: MapsourcesType) =>
    getVectorLayers(mapsources, ["Point", "MultiPoint"])

export const getRasterLayers = (mapsources: MapsourcesType) =>
    getLayers(mapsources).filter((l) => l.layer_type === "Raster")

export const getLayersWithTag = (mapsources: MapsourcesType, tag) =>
    getLayers(mapsources).filter((l) => l.tags.includes(tag))

/**
 * Get the associated layer object
 * @param mapsources
 * @param mapsourceName
 * @param layerName
 */
export const getLayer = (
    mapsources: MapsourcesType,
    mapsourceName: string,
    layerName: string,
) => {
    let ret = null
    mapsources.mapsources.forEach((mapsource) => {
        if (mapsource.name === mapsourceName) {
            if (mapsource.type === "arcgisrest") {
                ret = mapsource
            } else if (mapsource.type === "mapserver") {
                const layer = mapsource.layers.find(
                    (l) =>
                        l.mapsource === mapsourceName && l.name === layerName,
                )
                if (layer) ret = layer
            }
        }
    })
    return ret
}

export const getLayerType = (layerType: string) => {
    let checkLayerType = layerType
    if (layerType.indexOf(" ") >= 0) {
        const tokens = layerType.split(" ")
        checkLayerType = tokens[tokens.length - 1]
    }
    const layerConversions = {
        Polygon: "Polygon",
        MultiPolygon: "Polygon",
        Line: "Line",
        LineString: "Line",
        MultiLineString: "Line",
        Point: "Point",
        MultiPoint: "Point",
        Raster: "Raster",
    }
    return layerConversions[checkLayerType]
}

export function getLayerIcon(layer) {
    if (getLayerType(layer.layer_type) === "Polygon") {
        return <i className={"ms ms-polygon"} title={"Polygon"} />
    } else if (getLayerType(layer.layer_type) === "Line") {
        return <i className={"ms ms-line"} title={"Line"} />
    } else if (getLayerType(layer.layer_type) === "Point") {
        return <i className={"ms ms-points"} title={"Point"} />
    } else if (getLayerType(layer.layer_type) === "Raster") {
        return <i className={"ms ms-raster"} title={"Raster"} />
    }
    return <i className={"far fa-question-circle"} />
}

/**
 * Convert a mapserver expression string into a string of the form "<min> - <max>"
 * @param {string} expression
 */
export function makeReadableExpression(expression) {
    let ret = expression
    // Try range, ie (([OBJECTID] >= 25.0) AND ([OBJECTID] <= 38.0))
    let re = /\(\("*\[(?<field>.+?)]"* (?<op>\S+) "*(?<val>.+)"*\) AND \("*\[(?<field1>.+?)]"* (?<op1>\S+) "*(?<val1>.+?)"*\)\)/
    let m = expression.match(re)
    if (m && m.length > 3) {
        ret = `${m[3]} - ${m[6]}`
    } else {
        // Look for eq, which means a single value (ie, ("[County Name]" eq "ADAMS "))
        re = /\("*\[(?<field>.+?)]"* (?<op>\S+) "*(?<val>.+?)"*\)/
        m = expression.match(re)
        if (m && m.length > 3) {
            ret = m[3]
        }
    }
    return ret
}

export function getBaseType(type) {
    if (type.includes("str")) return "str"
    if (type.includes("object")) return "str"
    if (type.includes("float")) return "float"
    if (type.includes("date")) return "date"
    if (type.includes("int")) return "int"
    return type
}

export function getExt(filepath) {
    const m = filepath.match(/.*(\..+)$/)
    let ret = null
    if (m) {
        ret = m[1]
    }
    return ret
}

export function splitExt(filepath) {
    const ext = getExt(filepath) || ""
    return [filepath.substring(0, filepath.length - ext.length), ext]
}

export function basename(filepath) {
    const m = filepath.match(/.*\/(.+)$/)
    let ret = null
    if (m) {
        ret = m[1]
    }
    return ret
}

/**
 * Return the mapserver host location
 * @returns {string}
 */
export const getMapserverHost = () => {
    let server = window.location.origin
    // Check for django dev server, in which case mapserver requests just go to port 80
    let port = window.location.port
    if (window.location.port == 3000) {
        // create-react-app dev port, map to 8000
        port = 8000
    }
    if (port >= 8000) {
        server = `${window.location.protocol}//${window.location.hostname}`
    }
    return server
}

export function getMapsourceForLayer(
    mapsources: UserMapsourceType[],
    layerName,
) {
    let ret
    mapsources.forEach((mapsource) => {
        mapsource.layers.forEach((layer) => {
            if (layer.name === layerName) {
                ret = mapsource
            }
        })
    })
    return ret
}

export function getURLparams(obj) {
    return Object.entries(obj)
        .map(([key, val]) => `${key}=${val}`)
        .join("&")
}

export function getMapserverName(l: UserLayerType, viewName) {
    return `${l.name}-${viewName || l.current_view_name}`.replace(/[,/']/g, "")
}

export function getLegendForLayer(mapsource, layer, viewName) {
    // Remove commas from layer entry
    const layerEntryName = getMapserverName(layer, viewName)
    const params = {
        SERVICE: "WMS",
        VERSION: "1.1.1",
        REQUEST: "GetLegendGraphic",
        LAYER: encodeURIComponent(layerEntryName),
        Format: "image/png",
        map: mapsource.mapfile,
        foo: Math.random(),
    }
    return `${getMapserverHost()}/cgi-bin/mapserv?${getURLparams(params)}`
}

export const mapToolbarHasActivePanels = (mapToolbar) => {
    const activePanels = mapToolbar.items.filter((item) => item.active)
    return activePanels.length > 0
}

export const getSymbolStyle = (layer, classification) => {
    const patternShape = getLayerType(layer.layer_type).toLowerCase()
    const { borderColor, color, symbolType, transparent } = classification
    if (symbolType === "Solid" || patternShape !== "point") {
        return {
            backgroundColor: transparent === true ? "initial" : color,
            border: "1px solid gray",
            borderColor,
            margin: 2,
            width: 20,
        }
    }
    return {}
}

export const getSymbolURL = (layer, classification) => {
    if (classification.url) {
        return classification.url
    }

    const patternShape = getLayerType(layer.layer_type).toLowerCase()
    const icons = layer.icons
    const { symbolType, symbol } = classification
    return `${icons.url}${patternShape}/${symbolType || "Solid"}/${
        symbol || "Solid.png"
    }`
}

function getClassification(layer, feature) {
    const classifications = layer.classifications
    const regex = /\[(.+?)\]/
    return classifications.find((c) => {
        if (c.expression) {
            // Check if this classification applies
            const m = c.expression.match(regex)
            if (m) {
                const field = m[1]
                const fieldReplace = m[0]
                const val = feature.get(field)
                const evalExp = c.expression
                    .replace(fieldReplace, val)
                    .replace(fieldReplace, val)
                    .replace("AND", "&&")
                    .replace("eq", "==")
                const matches = eval(evalExp)
                if (matches) {
                    return c
                }
            }
        } else {
            return c
        }
    })
}

/**
 *
 * @param feature Openlayers feature
 * @param layer catena layer with classifications
 */
export function getFeatureStyleFromLayer(feature, layer, opts = {}) {
    // Parse label
    let fill
    let geometry
    let image
    let stroke
    let text

    if (layer.labelsVisible) {
        const backgroundFill = layer.labelBackgroundVisible
            ? new Fill({ color: layer.labelBackgroundColor })
            : undefined
        text = new Text({
            backgroundFill,
            font: `${layer.labelSize}px "${layer.labelFont}", sans-serif`,
            fill: new Fill({ color: layer.labelTextColor }),
            offsetX: "0",
            // TODO: change this to be a function of symbol size
            offsetY: "30",
            stroke: new Stroke({
                color: layer.labelBorderColor,
                width: layer.labelBorderWidth,
            }),
            text: feature.get(layer.labelField),
        })
    }

    const geomType = feature.getGeometry().getType()
    // Parse classifications
    const sym = getClassification(layer, feature)
    // Update style
    fill =
        sym.transparent || opts.transparent
            ? undefined
            : new Fill({
                  color: sym.color,
              })
    stroke = new Stroke({
        color: sym.bordercolor || "black",
        width: sym.bordersize === undefined ? 1 : sym.bordersize,
    })
    if (geomType.includes("Point")) {
        if (sym.symbolType === "Solid") {
            fill = opts.transparent
                ? undefined
                : new Fill({
                      color: sym.color,
                  })
            stroke = new Stroke({
                color: sym.bordercolor,
                width: sym.bordersize,
            })
            if (sym.symbol === "triangle.png") {
                image = new RegularShape({
                    fill,
                    points: 3,
                    radius: sym.size,
                    stroke,
                })
            } else if (sym.symbol === "square.png") {
                image = new RegularShape({
                    angle: Math.PI / 4,
                    fill,
                    points: 4,
                    // Fudge factor to match mapserver
                    radius: sym.size * 0.75,
                    stroke,
                })
            } else if (sym.symbol === "ellipse_vertical.png") {
                image = new Circle({
                    fill,
                    radius: sym.size,
                })
                const coordinates = feature.getGeometry().getCoordinates()
                const center = coordinates[0]
                const dx = sym.size / 2
                const dy = sym.size
                // The geometry needs to resize so that it always looks to be the same size at any zoom level.
                const radius = opts.resolution / 2
                const circle = new CircleGeom(center, radius)
                const polygon = fromCircle(circle, 64)
                polygon.scale(dx, dy)
                geometry = polygon
            } else {
                image = new Circle({
                    fill,
                    stroke,
                    radius: sym.size,
                })
            }
        } else {
            // Found a symbol, convert this to an openlayers image style
            image = new Icon({
                opacity: sym.opacity,
                scale: sym.size / 20,
                src: getSymbolURL(layer, sym),
            })
        }
    }

    return new Style({
        fill,
        geometry,
        image,
        stroke,
        text,
    })
}

/**
 * Remove key from obj completely rather than leave it undefined.
 * @param obj
 * @param key
 */
export function removeKey(obj, key) {
    const ret = {}
    Object.entries(obj).forEach(([k, v]) => {
        if (k !== key) ret[k] = v
    })
    return ret
}

/**
 * Convert react children to an array
 * @param props
 * @returns {T[]}
 */
export function getChildren(props) {
    return React.Children.toArray(props.children).filter((c) => c)
}

export const layerNameExists = (mapsources, layerName) =>
    getLayers(mapsources)
        .map((l) => l.name)
        .includes(layerName)

/**
 * Generate a layer name of the form "desiredLayerName (xx)" if desiredLayerName exists
 * @param mapsources
 * @param desiredLayerName
 * @returns {*}
 */
export function getUniqueLayerName(
    mapsources: MapsourcesType,
    desiredLayerName,
    ext,
) {
    let uniqueLayerName = desiredLayerName
    let baseLayerName = desiredLayerName
    let counter = 0
    const m = /(\(\d+\))(?=[^(]*)$/g
    if (uniqueLayerName.match(m)) {
        counter = parseInt(m[1])
        const groupLength = m[0].length + 1
        baseLayerName = uniqueLayerName.substr(
            0,
            uniqueLayerName.length - groupLength,
        )
    }
    while (layerNameExists(mapsources, uniqueLayerName)) {
        counter += 1
        uniqueLayerName = `${baseLayerName} (${counter})`
    }
    return uniqueLayerName
}

export const fetchKnownBoundaries = (
    boundary,
    boundaryType,
    onSuccess,
    onError,
) => {
    let fetchError = false
    const payload = {
        extent: boundary,
        boundaryType,
    }
    fetch("/er2_map/api/v1/known-boundaries/", {
        credentials: "include",
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: stringify(payload),
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                onError(json)
            } else {
                const valueField = boundaryType
                onSuccess(
                    json.items.map((item) => ({
                        ...item,
                        value: item[valueField] || item.name,
                    })),
                )
            }
        })
}

export const fetchKnownBoundary = (
    boundaryId,
    boundaryType,
    onSuccess,
    onError,
) => {
    let fetchError = false
    fetch(`/er2_map/api/v1/known-boundary/${boundaryType}/${boundaryId}/`)
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                onError(json)
            } else {
                onSuccess(json.geojson)
            }
        })
}

export function copyToClipboard(str) {
    const el = document.createElement("textarea")
    el.value = str
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    const selected =
        document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
    if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
    }
}

export function getCurrentTimestamp() {
    return Math.floor(Date.now())
}

export function downloadCSV(csvData, filename) {
    let csv = ""
    csvData.forEach((row) => {
        csv += row.join(",")
        csv += "\n"
    })

    const hiddenElement = document.createElement("a")
    hiddenElement.href = `data:text/csvcharset=utf-8,${encodeURI(csv)}`
    hiddenElement.target = "_blank"
    hiddenElement.download = `${filename}.csv`
    hiddenElement.click()
}

export function tryCastToNumber(v) {
    if (parseFloat(v) == v) return parseFloat(v)
    if (parseInt(v) == v) return parseInt(v)
    return v
}

export function numericSort(a, b) {
    return a - b
}

export function tryNumericSort(a, b) {
    const aPrime = parseFloat(a)
    const bPrime = parseFloat(b)
    if (aPrime == a && bPrime == b) {
        return aPrime - bPrime
    }
    return a.localeCompare(b)
}

// https://tobbelindstrom.com/blog/measure-scrollbar-width-and-height/
export function getScrollbarSize() {
    const { body } = document
    const scrollDiv = document.createElement("div")

    // Append element with defined styling
    scrollDiv.setAttribute(
        "style",
        "width: 1337px; height: 1337px; position: absolute; left: -9999px; overflow: scroll;",
    )
    body.appendChild(scrollDiv)

    // Collect width & height of scrollbar
    const calculateValue = (type) =>
        scrollDiv[`offset${type}`] - scrollDiv[`client${type}`]
    const width = calculateValue("Width")
    const height = calculateValue("Height")

    body.removeChild(scrollDiv)

    return {
        width,
        height,
    }
}

/**
 * Removed HTML tags and replace HTML entities.
 * @param s
 * @returns {string}
 */
export function innerHTML(s) {
    return s.replace(/(<([^>]+)>)/gi, "").replace(/&quot;/g, '"')
}

export const encodeGetParams = (p) =>
    Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&")

export function enlargeExtent(extent, factor = 0.2) {
    const width = Math.abs(extent[2] - extent[0])
    const height = Math.abs(extent[3] - extent[1])
    const inc = Math.max(width, height) * factor
    extent[0] -= inc
    extent[2] += inc
    extent[1] -= inc
    extent[3] += inc
    return extent
}
