import { StyleRules, Theme } from "@material-ui/core"
import { paletteBlue, paletteDarkGrey, paletteOrange } from "styles/colors.main"

export const mrrpStyles: (theme: Theme) => StyleRules = (theme) => ({
    mrrpStepperButton: {
        "&:disabled": {
            opacity: 0.6,
        },
    },
    mrrpSectionLink: {
        fontSize: "0.8rem",
        color: paletteDarkGrey,
        paddingLeft: "20px",
    },
    mrrpStepperTextStyle: {
        fontSize: theme.typography.body1.fontSize,
        color: paletteBlue,
    },
    mrrpTabLink: {
        fontSize: "0.9rem",
        color: paletteDarkGrey,
        fontWeight: 600,
    },
    mrrpTabLinkActive: {
        fontSize: "0.9rem",
        color: paletteOrange,
    },
    mrrpToken: {
        color: paletteOrange,
    },
})
