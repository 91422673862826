// @flow
import React from "react"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import Switch from "@material-ui/core/Switch"
import Slider from "rc-slider"
import PropStyles from "@owsi/catena/er2_styles/layer_properties"
import Color from "./color"
import { getLayerType } from "../utils"

type PropsType = {
    layer_type: string,
    onClose: Function,
    onUpdate: Function,
    pos: { left: number, right: number },
    symbol: {
        bordercolor: string,
        bordersize: number,
        color: string,
        hatchsize: number,
        opacity: number,
        size: number,
        transparent: boolean,
    },
    theme: PropStyles,
}

type StateType = {
    patterns: {},
    patternSets: string[],
    patternShape: string,
    symbolType: string,
    // Currepnt symbol
    symbol: string,
    theme: {},
    transparent: boolean,
}

const defaultSymbol = {
    opacity: 100,
    symbolType: "Solid",
    symbol: "Solid.png",
}

const defaultSymbolForType = {
    Point: {
        size: 4,
    },
    Line: {
        size: 1,
    },
    Polygon: {
        size: 1,
    },
}

class SymbolProperties extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props)
        this.state = this.getNewState(props)
    }

    componentDidMount() {
        this.loadIcons()
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.getNewState(nextProps))
    }

    changeState(prop, value) {
        let newState = prop
        if (value !== undefined) {
            newState = { [prop]: value }
        }
        this.setState({ symbol: newState })
        if (this.props.onUpdate) {
            this.props.onUpdate(newState)
        }
    }

    getNewState(props) {
        const newState = {
            symbol: {
                ...defaultSymbol,
                ...defaultSymbolForType[props.layer_type],
                ...props.symbol,
            },
        }
        if (!this.state || !this.state.patternSets) {
            newState.patternSets = []
        }
        return newState
    }

    handle(sliderType, props) {
        const Handle = Slider.Handle
        const { value, dragging, index, ...restProps } = props
        if (this[sliderType]) {
            this[sliderType].textContent = value
        }
        return <Handle value={value} {...restProps} />
    }

    loadIcons() {
        fetch("/er2_map/get_icons/")
            .then((res) => res.json())
            .then((ret) => {
                const icons = ret.icons

                // Each symbol type has a given number of pattern sets, like 'solid', 'icon', etc
                const patternShape = getLayerType(
                    this.props.layer_type,
                ).toLowerCase()
                const patternSets = Object.keys(icons[patternShape])

                let symbolType = this.state.symbol.symbolType
                if (symbolType === undefined) {
                    symbolType = patternSets[patternSets.indexOf("Solid")]
                }
                this.setState({
                    icons: ret.icons,
                    symbol: { ...this.state.symbol, symbolType },
                    patternSets,
                    patternShape,
                })
            })
            .catch((err) => (res) =>
                console.error(`Got failure to download icons: ${res}`),
            )
    }

    onSetSymbolType = (e) => {
        const symbolType = e.target.value
        const iconData = this.state.icons[this.state.patternShape][symbolType]
        const defaultIcon = iconData.icons[0][0]
        this.changeState({
            symbol: defaultIcon[1],
            url: iconData.url + defaultIcon[1],
            symbolType,
        })
    }

    render() {
        const { theme } = this.props
        const sliderLabelWidth = 80
        const styles = {
            sliderDot: {
                height: "10px",
                width: "10px",
                border: "1px",
            },
            box: {
                backgroundColor: "white",
                position: "absolute",
                left: this.props.pos.left,
                right: this.props.pos.right,
                bottom: 50,
                borderRadius: "5px 0px 0px 5px",
                border: "1px solid #888",
                padding: "20px 20px 20px 20px",
                zIndex: "201",
                width: 250,
            },
            closeButton: {
                bottom: 0,
                margin: "10px",
                position: "absolute",
                right: 0,
            },
            windowLabel: {
                margin: "-20px -20px 0px -20px",
                textAlign: "center",
                backgroundColor: "#12A4B6",
                color: "white",
                borderRadius: "3px 0px 0px 0px",
                boxShadow:
                    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
            },
            fillStyleLabel: {
                borderBottom: "1px solid #ccc",
                fontSize: "larger",
                marginTop: "20px",
                marginBottom: "10px",
                textAlign: "center",
            },
            borderStyleLabel: {
                borderBottom: "1px solid #ccc",
                fontSize: "larger",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
            },
            propertyLabel: {
                // float: 'left',
            },
            field: {
                // margin: '5px'
            },
            selectField: {
                margin: "10px 0px 20px 0px",
            },
            selectTable: {
                width: "103%",
                marginLeft: "-3px",
            },
            table: {
                display: "table",
                width: "100%",
            },
            tableRow: {
                display: "table-row",
            },
            tableCell: {
                display: "table-cell",
            },
        }

        const layerType = this.props.layer_type

        const getPatterns = () => {
            const selectSymbol = (symbol, url) => {
                this.changeState({
                    symbol,
                    url,
                    symbolType: this.state.symbol.symbolType,
                })
            }

            if (this.state.symbol.symbolType && this.state.patternShape) {
                const patternShape = this.state.patternShape
                const style = { height: "16px", width: "16px", margin: "2px" }
                if (
                    this.state.symbol.symbolType === "Solid" ||
                    patternShape !== "point"
                ) {
                    // non-icon display. Set the color so the shape will display
                    style.backgroundColor = this.state.symbol.color
                        ? this.state.symbol.color
                        : "#000000"
                }
                const iconData = this.state.icons[this.state.patternShape][
                    this.state.symbol.symbolType
                ]
                return (
                    <div>
                        {iconData.icons.map((row) =>
                            row.map((i) => {
                                const symbolStyle = {
                                    display: "inline-block",
                                }
                                if (this.state.symbol.symbol === i[1]) {
                                    symbolStyle.border = "1px solid gray"
                                }
                                return (
                                    <span
                                        key={i[1]}
                                        onClick={() =>
                                            selectSymbol(
                                                i[1],
                                                iconData.url + i[1],
                                            )
                                        }
                                        role="button"
                                        style={symbolStyle}
                                        tabIndex={-1}
                                        title={i[0]}
                                    >
                                        <img
                                            alt="sym color"
                                            src={iconData.url + i[1]}
                                            style={style}
                                        />
                                    </span>
                                )
                            }),
                        )}
                    </div>
                )
            }
            return null
        }

        // Raster symbols cannot have opacity, nor can pixmaps
        const hasOpacity =
            layerType !== "Raster" &&
            (layerType !== "Point" || this.state.symbol.symbolType === "Solid")

        return (
            <div className={this.props.theme.toolCtr} style={styles.box}>
                <div style={styles.windowLabel}>
                    <Paper
                        className={this.props.theme.mapToolbarTitleBar}
                        elevation={4}
                    >
                        Symbol Properties
                    </Paper>
                </div>
                <div className={this.props.theme.toolRow}>
                    <div
                        style={{
                            flexGrow: 0,
                            marginRight: 10,
                            width: sliderLabelWidth,
                        }}
                    >
                        Opacity:{" "}
                    </div>
                    <div style={{ flexGrow: 1, marginTop: 3 }}>
                        <Slider
                            disabled={!hasOpacity}
                            min={0}
                            max={100}
                            value={this.state.symbol.opacity || 0}
                            // This has to placed in a class method rather than handled locally
                            handle={(value) => this.handle("opacity", value)}
                            onChange={(value) =>
                                this.changeState("opacity", value)
                            }
                        />
                    </div>
                    <div style={{ flexGrow: 0, marginLeft: 10 }}>
                        {this.state.symbol.opacity} %
                    </div>
                </div>
                {layerType !== "Raster" && (
                    <div className={this.props.theme.toolRow}>
                        <div
                            style={{
                                flexGrow: 0,
                                marginRight: 10,
                                width: sliderLabelWidth,
                            }}
                        >
                            Size:{" "}
                        </div>
                        <div style={{ flexGrow: 1, marginTop: 3 }}>
                            <Slider
                                min={0}
                                max={40}
                                value={
                                    this.state.symbol.size !== undefined
                                        ? this.state.symbol.size
                                        : 0
                                }
                                dotStyle={styles.sliderDot}
                                handle={(value) => this.handle("size", value)}
                                onChange={(value) =>
                                    this.changeState("size", value)
                                }
                            />
                        </div>
                        <div style={{ flexGrow: 0, marginLeft: 10 }}>
                            {this.state.symbol.size}
                        </div>
                    </div>
                )}
                <div className={this.props.theme.toolRow}>
                    <div
                        style={{
                            flexGrow: 0,
                            marginRight: 10,
                            width: sliderLabelWidth,
                        }}
                    >
                        Hatch Size:{" "}
                    </div>
                    <div style={{ flexGrow: 1, marginTop: 3 }}>
                        <Slider
                            disabled={layerType === "Point"}
                            min={0}
                            max={40}
                            value={
                                this.state.symbol.hatchsize !== undefined
                                    ? this.state.symbol.hatchsize
                                    : 0
                            }
                            dotStyle={styles.sliderDot}
                            handle={(value) => this.handle("hatchsize", value)}
                            onChange={(value) =>
                                this.changeState("hatchsize", value)
                            }
                        />
                    </div>
                    <div style={{ flexGrow: 0, marginLeft: 10 }}>
                        {this.state.symbol.hatchsize}
                    </div>
                </div>
                <div className={this.props.theme.toolRow}>
                    <div style={styles.propertyLabel}>Color</div>
                    <div style={styles.propertyValue}>
                        <Switch
                            disabled={!hasOpacity}
                            checked={!this.state.symbol.transparent}
                            color="primary"
                            onChange={() =>
                                this.changeState(
                                    "transparent",
                                    !this.state.symbol.transparent,
                                )
                            }
                            size={"small"}
                            title="Click off to set transparent"
                        />
                        <div style={{ display: "inline-block", width: 46 }}>
                            {!this.state.symbol.transparent && (
                                <Color
                                    color={
                                        this.state.symbol.color !== undefined
                                            ? this.state.symbol.color
                                            : "#000000"
                                    }
                                    disabled={!hasOpacity}
                                    onSel={(color) =>
                                        this.changeState("color", color.hex)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                {layerType !== "Raster" && this.state.patternSets.length > 0 && (
                    <React.Fragment>
                        <div className={this.props.theme.toolRow}>
                            <div>
                                <span style={styles.propertyLabel}>
                                    Pattern:
                                </span>
                            </div>
                            <div style={styles.tableCell}>
                                <FormControl fullWidth>
                                    <Select
                                        onChange={this.onSetSymbolType}
                                        value={
                                            this.state.symbol.symbolType !==
                                            undefined
                                                ? this.state.symbol.symbolType
                                                : 0
                                        }
                                    >
                                        {this.state.patternSets
                                            .sort()
                                            .map((set) => (
                                                <MenuItem key={set} value={set}>
                                                    {set}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={this.props.theme.toolRow}>
                            <div
                                style={{
                                    textAlign: "center",
                                    maxHeight: 200,
                                    overflow: "auto",
                                    margin: "auto",
                                }}
                            >
                                {getPatterns()}
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {hasOpacity && (
                    <React.Fragment>
                        <div className={this.props.theme.toolRow}>
                            <div
                                style={{
                                    flexGrow: 0,
                                    marginRight: 10,
                                    width: sliderLabelWidth,
                                }}
                            >
                                Border Size:{" "}
                            </div>
                            <div style={{ flexGrow: 1, marginTop: 3 }}>
                                <Slider
                                    min={0}
                                    max={40}
                                    value={
                                        this.state.symbol.bordersize !==
                                        undefined
                                            ? this.state.symbol.bordersize
                                            : 0
                                    }
                                    handle={(value) =>
                                        this.handle("bordersize", value)
                                    }
                                    onChange={(value) =>
                                        this.changeState("bordersize", value)
                                    }
                                />
                            </div>
                            <div style={{ flexGrow: 0, marginLeft: 10 }}>
                                {this.state.symbol.bordersize}
                            </div>
                        </div>
                        <div className={this.props.theme.toolRow}>
                            <span style={styles.propertyLabel}>
                                Border Color
                            </span>
                            <span style={styles.propertyValue}>
                                <Color
                                    color={this.state.symbol.bordercolor}
                                    onSel={(color) =>
                                        this.changeState(
                                            "bordercolor",
                                            color.hex,
                                        )
                                    }
                                />
                            </span>
                        </div>
                    </React.Fragment>
                )}
                <div>
                    <span style={{ display: "inline-block", height: "30px" }}>
                        &nbsp;
                    </span>
                    <Button
                        classes={{
                            contained: theme.button,
                        }}
                        color="primary"
                        style={styles.closeButton}
                        onClick={() => {
                            this.props.onClose()
                        }}
                    >
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}

export default SymbolProperties
