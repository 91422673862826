import type { DecoratorFunction, DecorateStoryFunction } from "./_types"

import React from "react"
import { defaultDecorateStory } from "@storybook/client-api"

type FolioConfiguration = {
    defaultDecorators: DecoratorFunction[]
    decorateStoryFn: DecorateStoryFunction
}

const ConfigurationContext = React.createContext<FolioConfiguration>({
    defaultDecorators: [],
    decorateStoryFn: defaultDecorateStory as DecorateStoryFunction,
})

export const useConfiguration = () => React.useContext(ConfigurationContext)

export const ConfigurationProvider: React.FC<FolioConfiguration> = ({
    children,
    ...rest
}) => {
    const outer = React.useContext(ConfigurationContext)

    return (
        <ConfigurationContext.Provider
            value={{
                ...outer,
                ...rest,
            }}
            children={children}
        />
    )
}

export default ConfigurationProvider
