import * as actionTypes from "constants/action_types"

const initialState = {
    activeStep: 0,
    status: false,
    statusAndTrends: {
        status: false,
        catchRateLarval: [],
        CPUELowerRand: [],
        CPUEUpperRand: [],
        CPUESeg2Rand: [],
        CPUESeg3Rand: [],
        CPUESeg4Rand: [],
        CPUESeg7Rand: [],
        CPUESeg8Rand: [],
        CPUESeg9Rand: [],
        CPUESeg10Rand: [],
        CPUESeg13Rand: [],
        CPUESeg14Rand: [],
        efBigQuestions: [],
        efFocalQuestions: [],
        efManagementActions: [],
        efManagementHypotheses: [],
        efScienceActivities: [],
        PSSizeUpperAll: [],
        PSSizeUpperAllWild: [],
        PSSizeLowerAll: [],
        PSSizeLowerAllWild: [],
        PSSizeSeg2All: [],
        PSSizeSeg2AllWild: [],
        PSSizeSeg3All: [],
        PSSizeSeg3AllWild: [],
        PSSizeSeg4All: [],
        PSSizeSeg4AllWild: [],
        PSSizeSeg7All: [],
        PSSizeSeg7AllWild: [],
        PSSizeSeg8All: [],
        PSSizeSeg8AllWild: [],
        PSSizeSeg9All: [],
        PSSizeSeg9AllWild: [],
        PSSizeSeg10All: [],
        PSSizeSeg10AllWild: [],
        PSSizeSeg13All: [],
        PSSizeSeg13AllWild: [],
        PSSizeSeg14All: [],
        PSSizeSeg14AllWild: [],
        PopulationProgressLower: [],
        PopulationProgressUpper: [],
        PSOccupancy: [],
        CPUETrendUpperHatchery: [],
        CPUETrendUpperWild: [],
        CPUETrendLowerHatchery: [],
        CPUETrendLowerWild: [],
        CpueTrends: [],
        PSCPUETest: [],
        psOccupancyYearList: [],
        PSLarvalCounts: [],
        PSPopulationEstimatesByBasin: [],
        PSPopulationEstimatesByRegion: [],
        PSMeanWeightsUpperBasin: [],
        PSMeanWeightsLowerBasin: [],
        PSMeanWeightsSeg2: [],
        PSMeanWeightsSeg3: [],
        PSMeanWeightsSeg4: [],
        PSMeanWeightsSeg7: [],
        PSMeanWeightsSeg8: [],
        PSMeanWeightsSeg9: [],
        PSMeanWeightsSeg10: [],
        PSMeanWeightsSeg13: [],
        PSMeanWeightsSeg14: [],
        PSPopulationEstimatesWTargets: [],
        PSPopulationEstimatesWTargetsYearList: [],
        PSRecruitmentRSDUpper: [],
        PSRecruitmentRSDLower: [],
        PSRecruitmentRSDSeg2: [],
        PSRecruitmentRSDSeg3: [],
        PSRecruitmentRSDSeg4: [],
        PSRecruitmentRSDSeg7: [],
        PSRecruitmentRSDSeg8: [],
        PSRecruitmentRSDSeg9: [],
        PSRecruitmentRSDSeg10: [],
        PSRecruitmentRSDSeg13: [],
        PSRecruitmentRSDSeg14: [],
        AdjPSRecruitmentRSDUpper: [],
        AdjPSRecruitmentRSDLower: [],
        AdjPSRecruitmentRSDSeg2: [],
        AdjPSRecruitmentRSDSeg3: [],
        AdjPSRecruitmentRSDSeg4: [],
        AdjPSRecruitmentRSDSeg7: [],
        AdjPSRecruitmentRSDSeg8: [],
        AdjPSRecruitmentRSDSeg9: [],
        AdjPSRecruitmentRSDSeg10: [],
        AdjPSRecruitmentRSDSeg13: [],
        AdjPSRecruitmentRSDSeg14: [],
        Adj1PSRecruitmentRSDUpper: [],
        Adj1PSRecruitmentRSDLower: [],
        Adj1PSRecruitmentRSDSeg2: [],
        Adj1PSRecruitmentRSDSeg3: [],
        Adj1PSRecruitmentRSDSeg4: [],
        Adj1PSRecruitmentRSDSeg7: [],
        Adj1PSRecruitmentRSDSeg8: [],
        Adj1PSRecruitmentRSDSeg9: [],
        Adj1PSRecruitmentRSDSeg10: [],
        Adj1PSRecruitmentRSDSeg13: [],
        Adj1PSRecruitmentRSDSeg14: [],

    },
    managementActions: {
        status: false,
        PSStockingHistoryUpper: [],
        PSStockingHistoryLower: [],
        PSYearlingUpper: [],
        PSYearlingLower: [],
        MeanCPUE: [],
        PSCPUETimeseries: [],
        PSInterception: [],
        PSHabitat: [],
    },
    psTargets: {
        psTargets: [],
        catchRateUpper: [],
        catchRateLower: [],
    },
}

export type FishType = typeof initialState

export default function fish(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_FISH_ACTIVESTEP:
            return {
                ...state,
                activeStep: action.activeStep,
            }
        case actionTypes.SET_FISH_SNT_DATA:
            return {
                ...state,
                statusAndTrends: {
                    ...state.statusAndTrends,
                    ...action.output,
                },
            }
        case actionTypes.SET_FISH_MANAGEMENT_DATA:
            return {
                ...state,
                managementActions: {
                    ...state.managementActions,
                    ...action.output,
                },
            }
        default:
            return state
    }
}
