const styles = {
    contextBar: {
        backgroundColor: (props) => props.colors?.gray || props.gray,
        color: (props) => props.colors?.textColor || props.textColor,
        display: "flex",
        flexDirection: "column",
        gridColumnStart: "left",
        gridColumnEnd: "controlBar",
        gridRowStart: "subHeader",
        gridRowEnd: "end",
    },
    contextBarBottom: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
    },
    contextBarTop: {
        flexGrow: "1",
    },
    iconCtr: {
        padding: "10px",
        textAlign: "center",
        fontSize: "20px",
    },
    spacer: {
        backgroundColor: (props) =>
            props.colors?.secondaryColor || props.secondaryColor,
        color: (props) =>
            props.colors?.contrastTextColor || props.contrastTextColor,
        height: "44px",
    },
}

export default styles
