// Based on, but not linked to, build_mrrp_layer_metadata.py
export const graphGeneralRegionColors = {
    northernRegion: "#FF8C19",
    southernRegion: "#492970",
    total: "#000000",
    reservoir: "#808080",
    river: "#bfbfbf",
    upperRiver: "#A52A2A",
    lowerRiver: "#4472C4",
    mississippiRiver: "#003300",
    yellowstoneRiver: undefined,
    specific: {
        segment2: "#640302",
        segment3: "#F60A04",
        segment4: "#fd9c9b",
        segment7: "#000066",
        segment8: "#0000e6",
        segment9: "#1aa3ff",
        segment10: "#00664d",
        segment13: "#1affc6",
        segment14: "#ccfff2",
        segment22: "#ffff1a",
        segment57: "#b3b300",
        segment55: "#008000",
        segment56: "#003300",
        Oahe: "#b32d00",
        Garrison: "#ff6633",
        Sakakawea: "#fcbf82",
        Randall: "#80027a",
        LewisAndClark: "#cc00ff",
        Gavins: "#ecb3ff",
        Runoff: "#808080",
        RunoffSiouxCity: "#a6c9ff",
        "Great Plains": "#a52A2a",
        "Central Lowlands": "#4472C4",
        "Interior Highlands": "#003300",
    },
}

// Different colors than above, trying to match the caption they provided
export const dischargeAreaColors = {
    newGarrison: "#000066",
    oldGarrison: "#1aa3ff",
    newGavins: "#008000",
    newRandall: "#b3b300",
}

export const birdNestFailureGraphColors = {
    success: "#b4c6e7",
    undetermined: "#4472C4",
    flooded: "#ed8f05",
    predator: "#f4b442",
    weather: "#000000",
    human: "#9EECC9",
    livestockOrWildlife: "#6B8912",
    erosion: "#E7D4B5",
    collected: "#999999",
    abandoned: "#b069db",
    noEvidence: "#6E2C00",
}

export const graphSpeciesColor = {
    general: {
        pipingPlover: "#008000",
        leastTern: "#777911",
        pallidSturgeon: "#0D7D7D",
        preyFish: "#ffffff",
    },
    specific: {
        pipingPloverAdult: "#009900",
        pipingPloverFlegling: "#66FF33",
        leastTernAdult: "#999900",
        leastTernFlegling: "#ffff66",
        // pallidSturgeonCombine: '#41A191',
        // pallidSturgeonHatchery: '#3DD7AF',
        // pallidSturgeonWild: '#9EECC9',
        preyFishAll: "#000000",
        preyFishChubnCatfish: "#808080",
        preyFishChub: "#bfbfbf",
        errorRange: "#000000",
    },
}

export const fishSizeGraphColor = {
    "0-200mm": "#c42525",
    "200-400mm": "#f28f43",
    "400-800mm": "#b4c6e7",
    "800+mm": "#4472c4",
}

export const descriptionStyle = {
    marginLeft: "110px",
    marginRight: "110px",
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 600,
}

export const timeRepColor = {
    2016: "#ffc000",
    2017: "#ffff00",
    2018: "#ffff99",
    2019: "#ccff66",
}

export const fishSizeWeightGraphColor = {
    "200mm": "#c42525",
    "400mm": "#f28f43",
    "600mm": "#b4c6e7",
    "800mm": "#96b3eb",
    "1000mm": "#719cf0",
    "1200mm": "#4472c4",
}

export const birdModelResultGraphColor = {
    por: "#808080",
    storage: "#009900",
    wet: "#4472C4",
    decreasing: "#FF8C19",
    dry: "#F60A04",
    target: "#000000",
    construct: "#ffff00",
    // For Confidence Interval graphs
    standardEsh: "#000000",
    availableEsh: "#808080",
}

export const seriesColors = [
    "#96b3eb",
    "#6E2C00",
    "#ffff00",
    "#008000",
    "#80027a",
    "#777911",
]
