// Manages the session's application token
import * as actionTypes from "constants/action_types"

const initialState = {}

export default function token(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_FETCHED_STATE:
            return {
                ...state,
                ...action.state.token,
            }

        default:
            return state
    }
}
