import * as types from "constants/action_types"

const setFetchStatus = (isSuccessful) => ({
    type: types.SET_FISH_FETCH_STATUS,
    isSuccessful,
})

const setStatusAndTrendData = (output) => ({
    type: types.SET_FISH_SNT_DATA,
    output,
})
const getStatusAndTrend = () => (dispatch, getState) =>
    Promise.all([
        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Status1",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setStatusAndTrendData(json))
                    dispatch(setFetchStatus(true))
                }
            }),
        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Status2",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setStatusAndTrendData(json))
                    dispatch(setFetchStatus(true))
                }
            }),

        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Status3",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setStatusAndTrendData(json))
                    dispatch(setFetchStatus(true))
                }
            }),
        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Status4",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setStatusAndTrendData(json))
                    dispatch(setFetchStatus(true))
                }
            }),
    ])

const setManagementActionsData = (output) => ({
    type: types.SET_FISH_MANAGEMENT_DATA,
    output,
})
const getManagementActions = () => (dispatch, getState) => {
    Promise.all([
        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Management1",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setManagementActionsData(json))
                    dispatch(setFetchStatus(true))
                }
            }),
        fetch(`/${getState().meta.name}/api/v1/fish/`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                what: "Management2",
            }),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.error) {
                    // dispatch(setErrorMessage(json.error))
                    dispatch(setFetchStatus(false))
                } else {
                    dispatch(setManagementActionsData(json))
                    dispatch(setFetchStatus(true))
                }
            }),
    ])
}

export const onFishCBStepChange =
    (activeStep: number) => (dispatch, getState) => {
        dispatch({ type: types.SET_FISH_ACTIVESTEP, activeStep })
        switch (activeStep) {
            case 0:
                dispatch(getStatusAndTrend())
                return null
            case 1:
                dispatch(getStatusAndTrend())
                return null
            case 2:
                dispatch(getStatusAndTrend())
                return null
            case 3:
                dispatch(getManagementActions())
                return null
            case 4:
                dispatch(getManagementActions())
                return null
            case 5:
                return null
            default:
                return null
        }
    }
