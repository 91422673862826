import React from "react"

import * as router from "react-router-dom"

const targetOrigin = `${window.location.protocol}//${window.location.hostname}${
    window.location.port && ":" + window.location.port
}`

const WindowManagerContext = React.createContext<[Object, Function] | null>(
    null
)

export const WindowManager: React.FC = ({ children }) => {
    const windowCache = React.useState({})

    const value = windowCache

    return <WindowManagerContext.Provider value={value} children={children} />
}

export const usePopout = (urlBuilder = (it) => it) => {
    const history = router.useHistory()
    const isPopout = history.location.search.includes("isPopout=1")
    const windowCache = React.useContext(WindowManagerContext)

    const makeHandler = React.useCallback(
        (name, url, opts = "") => (payload?: any) => {
            if (windowCache) {
                const [cache, setCache] = windowCache
                const to = urlBuilder(url)
                const windowUrl = [
                    to.pathname,
                    to.search ? to.search + "&isPopout=1" : "?isPopout=1",
                    to.hash,
                ].join("")

                let windowObject = cache[name]
                let fresh = false

                if (windowObject == null || windowObject.closed) {
                    windowObject = window.open(
                        windowUrl,
                        name,
                        ["resizable,scrollbars,status", opts].join(",")
                    )
                    setCache({ ...cache, [name]: windowObject })
                    fresh = true
                } else {
                    windowObject.focus()
                }

                const post = () =>
                    windowObject.postMessage(
                        {
                            source: "popout-handler",
                            payload,
                        },
                        targetOrigin
                    )

                if (fresh) {
                    // wait for a popout listener
                    windowObject.addEventListener(
                        "message",
                        (event) => {
                            if (
                                event.origin === targetOrigin &&
                                event.data.source === "popout-listener"
                            ) {
                                post()
                            }
                        },
                        false
                    )
                } else {
                    // assume the window loaded a popout listener already
                    post()
                }
            }
        },
        [urlBuilder, windowCache]
    )

    return { isPopout, makeHandler }
}

export const usePopoutData = (onData) => {
    const listener = React.useCallback(
        (event) => {
            if (
                event.origin === targetOrigin &&
                event.data.source === "popout-handler"
            ) {
                onData(event.data.payload)
            }
        },
        [onData]
    )

    React.useEffect(() => {
        window.addEventListener("message", listener)
        window.postMessage(
            { source: "popout-listener", payload: true },
            targetOrigin
        )
        return () => window.removeEventListener("message", listener)
    }, [listener])
}
