import React from "react"
import { makeStyles, Snackbar, SnackbarContent } from "@material-ui/core"
import { failureColor, secondaryColor } from "../../styles/colors.main"

type myProps = { children: any }

const useStyles = makeStyles({
    root: {
        marginTop: "40px",
        marginRight: "15px",
        background: "transparent",
    },
    content: {},
})

export const Snack = React.createContext({
    setMessage: (s) => null,
    setIsError: (b) => null,
})

export function SnackContext(props: myProps) {
    const { children } = props
    const classes = useStyles()

    const [message, setMessage] = React.useState("")
    const [isError, setIsError] = React.useState(false)
    const [isOn, setIsOn] = React.useState(false)

    React.useEffect(() => {
        if (!message || !message.length) {
            if (isOn) setIsOn(false)
            return
        }
        if (isOn) return
        setIsOn(true)
    }, [message])

    function clear() {
        setIsOn(false)
        setTimeout(() => {
            setMessage("")
            setIsError(false)
        }, 130)
    }

    return (
        <Snack.Provider
            value={{
                setMessage,
                setIsError,
            }}
        >
            <Snackbar
                className={classes.root}
                open={isOn}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={isError ? 6000 : 3000}
                onClose={clear}
                onClick={clear}
            >
                <SnackbarContent
                    className={classes.content}
                    style={{
                        background: isError ? failureColor : secondaryColor,
                    }}
                    message={message}
                />
            </Snackbar>
            {children}
        </Snack.Provider>
    )
}
