// Manages state for all actions related to the Context Bar
import { Action } from "redux"
import * as actionTypes from "constants/action_types"
import DEFAULT_CONTEXT_BAR_ITEMS, {
    ContextBarItemConfig,
} from "constants/context_bar"

export type ContextBarStateType = {
    items: ContextBarItemConfig[]
    tocVisible: boolean
    contextVisible: boolean
}

const DEFAULT_STATE: ContextBarStateType = {
    items: DEFAULT_CONTEXT_BAR_ITEMS,
    tocVisible: true,
    contextVisible: false,
}

export default function contextBar(
    state = DEFAULT_STATE,
    action: Action<keyof typeof actionTypes> & { id?: any; payload: any }
) {
    if (action.type === actionTypes.CLICK_CONTEXT_BAR_ITEM) {
        return {
            ...state,
            items: state.items.map((item) =>
                item.id === action.id
                    ? { ...item, active: true, badge: { content: "" } }
                    : { ...item, active: false }
            ),
        }
    }
    if (action.type === actionTypes.CHANGE_CONTEXT_BAR_ITEMS) {
        return {
            ...state,
            items: action.payload,
        }
    }
    if (action.type === actionTypes.TOGGLE_CONTEXT_BAR_VISIBILITY) {
        const { context, toc } = action.payload
        return {
            ...state,
            tocVisible: toc ? !state.tocVisible : state.tocVisible,
            contextVisible: context
                ? !state.contextVisible
                : state.contextVisible,
        }
    }
    if (action.type === actionTypes.SET_CONTEXT_BAR_VISIBILITY) {
        const { context, toc } = action.payload
        return {
            ...state,
            tocVisible: toc ?? state.tocVisible,
            contextVisible: context ?? state.contextVisible,
        }
    }

    return state
}
