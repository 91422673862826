// Manages application state fetched from the server
import * as actionTypes from "constants/action_types"

const initialState = {
    isFetched: false,
    isFetching: false,
    showCatena: false,
    useMapserver: true,
}

export default function serverState(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_FETCHING_STATE:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case actionTypes.SET_FETCHED_STATE:
            return {
                ...state,
                isFetched: action.isFetched,
            }
        case actionTypes.SET_SHOW_CATENA:
            return {
                ...state,
                showCatena: action.showCatena,
            }

        default:
            return state
    }
}
