export const selectIsDevSite = (it) => it.project.IMS_SITE === "Dev"

export const selectIsBetaSite = (it) => it.project.IMS_SITE === "Beta"

export const selectCurrentToken = (it) => it.token.value

export const selectIsFetching = (it) => it.serverState.isFetching

export const selectIsAuthenticated = (it) =>
    process.env.NODE_ENV === "production" ? it.meta.user !== undefined : true
