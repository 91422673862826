import React from "react"
import Highcharts, { Chart } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsAddMore from "highcharts/highcharts-more"
import Highstock from "highcharts/highstock"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsAnnotations from "highcharts/modules/annotations"
import HighchartsPatternFill from "highcharts/modules/pattern-fill"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import { useSelector } from "react-redux"

const applyAddons =
    (it) =>
    (...addOns) =>
        addOns.map((on) => on(it))

const prettyThousands = (it) =>
    it.setOptions({
        lang: {
            thousandsSep: ",",
        },
    })

applyAddons(Highcharts)(
    prettyThousands,
    HighchartsAddMore,
    HighchartsExporting,
    HighchartsExportData,
    HighchartsPatternFill,
    HighchartsOfflineExporting,
    HighchartsAnnotations
)

applyAddons(Highstock)(
    prettyThousands,
    HighchartsExporting,
    HighchartsExportData
)

type LooseGraphProps =
    | HighchartsReact.Props
    | { options: Record<string, any>; callback?: (chart: Chart) => void }

export const Graph: React.FC<LooseGraphProps> = (props) => {
    const chartRef = React.useRef<any>(null)
    const drawerOpen = useSelector((it) => it.contextBar.tocVisible)

    React.useEffect(() => {
        let mounted = true
        const chartEl = chartRef.current

        if (chartEl) {
            setTimeout(() => {
                if (mounted) {
                    chartEl.reflow()
                }
            }, 1000)
        }

        return () => {
            mounted = false
        }
    }, [drawerOpen])

    const handleCallback = (chart) => {
        chartRef.current = chart

        if (props.callback) {
            props.callback(chart)
        }
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            {...props}
            callback={handleCallback}
        />
    )
}

export const StockGraph: React.FC<LooseGraphProps> = (props) => (
    <Graph highcharts={Highstock} constructorType={"stockChart"} {...props} />
)
