const baselayers = {
    baseLayer: {
        cursor: "pointer",
        margin: "auto",
    },
    baseLayerImg: {
        height: "auto",
        overflow: "hidden",
        width: "100%",
    },
    baseLayerHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
    },
}

export default baselayers
