// @flow
import * as actionTypes from "../constants/action_types"

export type ThemeType = {
    analysisPanelOffset: number,
    contextPanelOffset: number,
}

export const initialState: ThemeType = {
    analysisPanelOffset: 0,
    contextPanelOffset: 0,
}

export default function theme(state: ThemeType = initialState, action) {
    let newstate = state
    if (action.type === actionTypes.RESIZE_ANALYSIS_PANEL) {
        newstate = {
            ...state,
            analysisPanelOffset: state.analysisPanelOffset + action.offset,
        }
    } else if (action.type === actionTypes.RESIZE_CONTEXT_PANEL) {
        newstate = {
            ...state,
            contextPanelOffset: state.contextPanelOffset + action.offset,
        }
    }
    return newstate
}
