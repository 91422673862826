// @flow
import request from "superagent"
import {
    onMapView,
    onShareLayers,
    onUnshareLayers,
} from "@owsi/catena/er2_sharing/js/actions/sharing"
import type { ExtentType } from "@owsi/catena/er2_map/src/map"
import type { MapType, ViewType } from "../reducers/map"
import type {
    MapsourcesType,
    UserLayerType,
    UserMapsourceType,
} from "../reducers/mapsources"
import * as types from "../constants/action_types"
import { appendToLogger } from "./logger"

const { parse } = require("query-string")

export type OrderingType = {
    mapsourceName: string,
    layerName: string,
}

function getLayerFromMapsource(getState, mapsourceName, layerName) {
    const mapsources = getState().mapsources.mapsources
    const imapsource = mapsources.findIndex((f) => f.name === mapsourceName)
    const mapsource = mapsources[imapsource]
    // Locate the layer
    //   First try by filepath. Search by name should go away.
    let layer = mapsource.layers.find((l) => l.filepath === layerName)
    if (!layer) {
        layer = mapsource.layers.find((l) => l.name === layerName)
    }
    return layer
}

export const changeBaseLayer = (baseLayer: string) => ({
    type: types.CHANGE_BASELAYER,
    baseLayer,
})
export const changeBaseLayerOpacity = (opacity: number) => ({
    type: types.CHANGE_BASELAYER_OPACITY,
    opacity,
})
export const mapsourceIsLoading = (
    mapsource: UserMapsourceType,
    isLoading,
) => ({
    type: types.USER_MAPSOURCE_LOADING,
    mapsourceName: mapsource.name,
    isLoading,
})
export const onChangeMapScale = (mapScale: number) => ({
    type: types.CHANGE_MAPSCALE,
    mapScale,
})
export const setMapExtent = (extent: ExtentType) => ({
    type: types.SET_MAP_EXTENT,
    extent,
})
export const setUserLayers = (mapsources: Array<UserMapsourceType>) => ({
    type: types.USER_LAYERS_SET,
    mapsources,
})
export const setUserLayersAdding = (adding: boolean) => ({
    type: types.USER_LAYERS_ADDING,
    adding,
})
export const setUserLayersSort = (
    mapsourceName: string,
    layerNames: string[],
) => ({ type: types.USER_LAYERS_SORT, mapsourceName, layerNames })
export const showMapToolbar = (show: boolean) => ({
    type: types.SHOW_MAP_TOOLBAR,
    show,
})
export const updateMapState = (state: {
    map: MapType,
    mapsources: MapsourcesType,
}) => ({ type: types.UPDATE_MAP_STATE, state })
export const updateMapView = (mapView: ViewType) => ({
    type: types.UPDATE_MAP_VIEW,
    mapView,
})
export const updateMapsourceOrdering = (ordering, mapsourceNames) => ({
    type: types.UPDATE_MAPSOURCE_ORDER,
    ordering,
    mapsourceNames,
})
export const updateMapsourceLayers = (
    mapsourceName: string,
    layerNames: string,
    newProps: {},
) => ({
    type: types.UPDATE_MAPSOURCE_LAYERS,
    mapsourceName,
    layerNames,
    newProps,
})
export const updateServiceProps = (
    siteName: string,
    serviceName: string,
    props: {},
) => ({
    type: types.PUBLIC_DATA_PROPS_CHANGE,
    siteName,
    serviceName,
    serviceProps: props,
})
export const updateUserLayer = (layer: UserLayerType, newProps: {}) => ({
    type: types.USER_LAYER_UPDATE,
    layer,
    newProps,
})
export const updateUserLayers = (layers: UserLayerType[], newProps: {}[]) => ({
    type: types.USER_LAYERS_UPDATE,
    layers,
    newProps,
})
// Same as updateUserLayers except layers and newprops are zipped into a single array.
export const updateUserLayersBatch = (layerdata: [UserLayerType, {}][]) => ({
    type: types.USER_LAYERS_BATCH_UPDATE,
    layerdata,
})
export const mapExtentUpdateHandled = () => ({
    type: types.SET_MAP_EXTENT_HANDLED,
})
export const mapViewUpdateHandled = () => ({ type: types.SET_MAP_VIEW_HANDLED })
export const clearMapMessage = () => ({ type: types.MAP_MESSAGE_CLEAR })
export const setMapMessage = (message) => ({
    type: types.MAP_MESSAGE_SET,
    message,
})
export const clearMapError = () => ({ type: types.MAP_ERROR_CLEAR })
export const setMapError = (error) => ({ type: types.MAP_ERROR_SET, error })

/**
 *
 * @param initialExtent Specify the project wants the map to start in a specific location
 * @returns {function(...[*]=)}
 */
export const onFetchMapState =
    (initialExtent) => (dispatch: Function, getState: Function ) => {
        const token = getState().token.value
        fetch(`/er2_map/api/v1/state/map/?token=${token}`, { headers: new Headers({'content-type': 'application/json'}), method: "GET" })
            .then((res) => res.json())
            .then((json) => {
                dispatch(updateMapState(json.state))
                // Check for extent in URL
                const params = parse(window.location.search)
                let ex = initialExtent
                if (params.extent) {
                    ex = params.extent.split(",").map((e) => parseFloat(e))
                }
                if (ex) {
                    dispatch(setMapExtent(ex))
                }
            })
            .catch((res) => {
                if (res.response) {
                    dispatch(setMapError(res.response.body.exception.message))
                } else {
                    dispatch(setMapError(`${res}`))
                    console.error(`Got failure to fetch map state: ${res}`)
                }
            })
    }

export const onCreateLayer = (layerName, layerType) => (dispatch, getState) => {
    let fetchError = false
    const token = getState().token.value
    dispatch(setUserLayersAdding(true))
    fetch(`/er2_map/api/v1/create_layer/?token=${token}`, {
        body: JSON.stringify({
            layer_name: layerName,
            type: layerType,
        }),
        headers: new Headers({'content-type': 'application/json'}),
        method: "POST",
    })
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            dispatch(setUserLayersAdding(false))
            dispatch(onUpdateUserLayers())
        })
}

export const getUserLayerClassifications =
    (
        mapsource: UserMapsourceType,
        layer: UserLayerType,
        fieldName: string,
        classifyType: string,
        kwargs: {},
        success: Function,
    ) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        let fetchError = false
        fetch(`/er2_map/get_layer_classifications/?token=${token}`, {
            body: JSON.stringify({
                classify_type: classifyType,
                field: fieldName,
                format_type: kwargs.format_type,
                layer: layer.name,
                mapsource_name: mapsource.name,
                max_color: kwargs.max_color,
                min_color: kwargs.min_color,
                nclass: kwargs.nclass,
                random_colors: kwargs.random_colors,
            }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        })
            .then((res) => {
                fetchError = !res.ok
                return res.json()
            })
            .then((res) => {
                if (fetchError) {
                    dispatch(setMapError(res.exception.message))
                }
                success(res.classifications)
            })
            .catch((res) => {
                dispatch(setMapError(res.response.body.exception.message))
                console.error(`Got failure to update layers: ${res}`)
            })
    }

export const onChangeBaseLayer =
    (baseLayer: string) => (dispatch: Function, getState: Function) => {
        // Only update state if the state has been initialized
        const state = getState()
        if (state.serverState.isFetched) {
            const token = getState().token.value
            fetch(`/er2_map/api/v1/state/map/?token=${token}`, {
                body: JSON.stringify({ baseLayer }),
                headers: new Headers({'content-type': 'application/json'}),
                method: "PUT",
            })
            dispatch(changeBaseLayer(baseLayer))
        }
    }

export const onChangeBaseLayerOpacity =
    (opacity: number) => (dispatch: Function, getState: Function) => {
        // Only update state if the state has been initialized
        const state = getState()
        if (state.serverState.isFetched) {
            const token = getState().token.value
            fetch(`/er2_map/api/v1/state/map/?token=${token}`, {
                body: JSON.stringify({ opacity }),
                headers: new Headers({'content-type': 'application/json'}),
                method: "PUT",
            })
            dispatch(changeBaseLayerOpacity(opacity))
        }
    }

function doMaplayerReorder(getState, dispatch) {
    const mapsources = getState().mapsources
    mapsources.mapsources.forEach((m) => {
        if (m.type === "mapserver") {
            dispatch(
                sortMapLayers(
                    m.name,
                    m.layers.map((l) => l.name),
                ),
            )
        }
    })
}

export const onChangeFilters =
    (filters) => (dispatch: Function, getState: Function) => {
        dispatch({ type: types.SET_FILTERS, filters })
    }
export const onChangeGrouping =
    (grouping) => (dispatch: Function, getState: Function) => {
        dispatch({ type: types.SET_GROUPING, grouping })
        doMaplayerReorder(getState, dispatch)
    }
export const onChangeGroupOrdering =
    (ordering) => (dispatch: Function, getState: Function) => {
        dispatch({ type: types.SET_GROUP_ORDERING, ordering })
        doMaplayerReorder(getState, dispatch)
    }

export const sortMapLayers =
    (mapsourceName, layerNames) => (dispatch, getState) => {
        let fetchError = false
        fetch(`/er2_map/sort_map_layers/?token=${getState().token.value}`, {
            body: JSON.stringify({
                mapsource_name: mapsourceName,
                layers: layerNames,
            }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        })
            .then((res) => {
                fetchError = !res.ok
                return res.json()
            })
            .then((res) => {
                if (fetchError) {
                    dispatch(setMapError(res.exception.message))
                }
            })
            .catch((res) => {
                dispatch(setMapError(res.response.body.exception.message))
                console.error(`Got failure to update layers: ${res}`)
            })
    }

export const onUpdateMapsourceOrdering =
    (mapsourceNames: string[], finishedCB) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        fetch(`/er2_map/update_mapsource_ordering/?token=${token}`, {
            body: JSON.stringify({ mapsource_names: mapsourceNames }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        }).then((res) => {
            if (finishedCB) {
                finishedCB()
            }
        })
    }

export const updateGroupLayerOrdering =
    (groupName, layers) => (dispatch: Function, getState: Function) => {
        dispatch({ type: types.SET_LAYER_ORDERING, groupName, layers })
        doMaplayerReorder(getState, dispatch)
    }

export const onUpdateGroupLayerOrdering =
    (ordering: OrderingType[], mapsourceNames?: string[]) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        dispatch(updateMapsourceOrdering(ordering, mapsourceNames))
        dispatch(
            onUpdateMapsourceOrdering(mapsourceNames, () => {
                fetch(`/er2_map/update_group_layer_ordering/?token=${token}`, {
                    body: JSON.stringify({ ordering }),
                    headers: new Headers({'content-type': 'application/json'}),
                    method: "POST",
                })
                    .then((res) => res.json())
                    .catch((res) =>
                        console.error(
                            `Got failure to update mapfile ordering: ${res}`,
                        ),
                    )
            }),
        )
    }

export const onUpdateMapView =
    (mapView: ViewType) => (dispatch: Function, getState: Function) => {
        // Only update state if the state has been initialized
        const state = getState()

        // If there is a change, then notify anybody sharing our map extent.
        if (state.sharing) {
            if (
                mapView.center !== state.map.mapView.center ||
                mapView.resolution !== state.map.mapView.resolution ||
                mapView.mapScale !== state.map.mapView.mapScale
            ) {
                // Send to room
                dispatch(onMapView(mapView))
            }
        }

        if (state.serverState.isFetched) {
            // Don't save if the mapView isn't valid
            if (mapView.mapScale) {
                const token = getState().token.value
                fetch(`/er2_map/api/v1/state/map/?token=${token}`, {
                    body: JSON.stringify({ mapView }),
                    headers: new Headers({'content-type': 'application/json'}),
                    method: "PUT",
                })
            }
        }
        dispatch(updateMapView(mapView))
    }

export const onUpdateUserLayers =
    () => (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        fetch(`/er2_map/api/v1/state/map/?token=${token}`, { headers: new Headers({'content-type': 'application/json'}), method: "POST" })
            .then((res) => res.json())
            .then((json) => dispatch(setUserLayers(json.state.mapsources)))
            .catch((res) => {
                dispatch(
                    appendToLogger([
                        `Got failure to update user layers: ${res}`,
                    ]),
                )
                dispatch(setMapError(res.exception.message))
            })
    }

export const onUserLayerDelete =
    (mapsource: UserMapsourceType, layer: UserLayerType) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        // Check for multiple
        const layerNames = Array.isArray(layer)
            ? layer.map((l) => l.name)
            : [layer.name]
        dispatch({
            type: types.USER_LAYERS_DELETE,
            mapsourceName: mapsource.name,
            layerNames,
        })
        fetch(`/er2_map/delete_map_layers/?token=${token}`, {
            body: JSON.stringify({
                mapsource_name: mapsource.name,
                layer_names: layerNames,
            }),
            headers: new Headers({'content-type': 'application/json'}),
            method: "POST",
        }).catch((res) =>
            dispatch(appendToLogger([`Got failure to delete layers: ${res}`])),
        )
    }

export const onUserLayerDownload =
    (mapsource: UserMapsourceType, layer: UserLayerType, options) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        let optionsEnc = ""
        if (options) {
            optionsEnc = new URLSearchParams(options).toString()
        }
        window.open(`/er2_map/download_map_layer/?token=${token}&mapsource_name=${
            mapsource.name
        }&layer=${encodeURIComponent(layer.name)}
&foo=${Math.random()}&${optionsEnc}`)
    }

export const onUserLayersZoom =
    (layers: UserLayerType[]) => (dispatch: Function, getState: Function) => {
        // TODO: get union of intersections
        dispatch(setMapExtent(layers[0].bbox_3857))
    }

export const onUserLayersPropsChange =
    (
        mapsource_name: string,
        layers: UserLayerType | UserLayerType[],
        newprops: {},
    ) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        if (!Array.isArray(layers)) {
            layers = [layers]
        }
        if (newprops.sharing && newprops.sharing.enabled) {
            if (newprops.sharing.shared) {
                dispatch(onShareLayers(layers))
            } else {
                dispatch(onUnshareLayers(layers))
            }
        }
        if (layers.length) {
            layers.forEach((l) => dispatch(updateUserLayer(l, newprops)))
            fetch(`/er2_map/api/v1/update_map_layers/?token=${token}`, {
                body: JSON.stringify({
                    mapsource_name,
                    layers: layers.map((l) => l.filepath),
                    newprops,
                }),
                headers: new Headers({'content-type': 'application/json'}),
                method: "PUT",
            })
                .then((res) => res.json())
                .catch((res) =>
                    dispatch(setMapError(res.response.body.exception.message)),
                )
        }
    }

export const onUserLayersPropsChangeBatch =
    (layers: UserLayerType | UserLayerType[], newprops: {}[]) =>
    (dispatch: Function, getState: Function) => {
        const token = getState().token.value
        if (layers.length) {
            dispatch(updateUserLayers(layers, newprops))
            fetch(`/er2_map/api/v1/update_map_layers_batch/?token=${token}`, {
                body: JSON.stringify({
                    layers: layers.map((l) => l.filepath),
                    newprops,
                }),
                headers: new Headers({'content-type': 'application/json'}),
                method: "POST",
            })
                .then((res) => res.json())
                .catch((res) =>
                    dispatch(setMapError(res.response.body.exception.message)),
                )
        }
    }

export const onUserLayersAdd =
    (
        mapsourceName: string,
        acceptedFiles: [],
        rejectedFiles: [],
        initialProps: {} = {},
    ) =>
    (dispatch: Function, getState: Function) => {
        dispatch(setUserLayersAdding(true))
        const token = getState().token.value
        const req = request.post(
            `/er2_map/add_map_layers/?token=${token}&mapsource_name=${mapsourceName}`,
        )
        acceptedFiles.forEach((file) => req.attach(file.name, file))
        req.field("initialProps", JSON.stringify(initialProps))
        req.then((resp) => {
            dispatch(setUserLayersAdding(false))
            if (resp.body.layers.length) {
                dispatch(onUpdateUserLayers())
                dispatch(
                    setMapMessage(`Added ${resp.body.layers.length} layers`),
                )
                if (resp.body.totalEx && resp.body.totalEx.length > 0) {
                    dispatch(setMapExtent(resp.body.totalEx))
                }
            } else {
                dispatch(
                    setMapError(
                        "Warning: no layers were added. Check that all file types were selected.",
                    ),
                )
            }
        }).catch((ret) => {
            dispatch(setUserLayersAdding(false))
            dispatch(appendToLogger([ret.response.text]))
            dispatch(setMapError(ret.response.body.exception.message))
            dispatch(setUserLayersAdding(false))
        })
    }

const setLayerFetchingState = (layer, fetching) => ({
    type: types.USER_LAYER_FETCHING_GEOJSON,
    layer,
    fetching,
})
export const setLayerGeoJSON = (layer, geojson) => ({
    type: types.USER_LAYER_SET_GEOJSON,
    layer,
    geojson,
})

export const userLayerFetchGeoJSON =
    (mapsourceName: string, layer) =>
    (dispatch: Function, getState: Function) => {
        let fetchError = false
        const token = getState().token.value
        const query = `?token=${token}&layerName=${layer.name}`
        dispatch(setLayerFetchingState(layer, true))
        fetch(`/er2_map/api/v1/get_geojson/${query}`)
            .then((response) => {
                fetchError = !response.ok
                return response.json()
            })
            .then((json) => {
                dispatch(setLayerFetchingState(layer, false))
                if (fetchError) {
                    dispatch(setLayerGeoJSON(layer, {}))
                } else {
                    dispatch(setLayerGeoJSON(layer, json.geojson))
                }
            })
    }

export const userLayerSelectFeatures =
    (layer, layerQuery) => (dispatch, getState) => {
        dispatch(updateUserLayer(layer, { query: layerQuery }))
        let fetchError = false
        const token = getState().token.value
        const query = `?token=${token}`
        fetch(`/er2_map/api/v1/map/set_layer_query/${query}`, {
            method: "POST",
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify({
                layerName: layer.name,
                query: layerQuery,
            }),
        }).then((response) => {
            fetchError = !response.ok
            return response.json()
        })
    }

export const userLayerFilterByBoundary = (boundary) => (dispatch, getState) => {
    const token = getState().token.value
    const query = `?token=${token}`
    fetch(`/er2_map/api/v1/map/set_filter_boundary/${query}`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            boundary,
        }),
    }).then((response) => response.json())
}
