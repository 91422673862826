// @flow
const styles = {
    attributeTable: {},
    attributeTableButton: {
        fontSize: "small",
        margin: 2,
    },
    attributeTableContainer: {
        fontSize: 12,
        overflow: "auto",
        userSelect: "none",
    },
    attributeTableControlBar: {
        fontStyle: "italic",
    },
    attributeTableMenuItem: {
        fontSize: "small",
    },
    attributeTableSearch: {
        fontSize: "small",
        margin: 0,
        width: 80,
    },
    attributeTableTextEdit: {
        margin: 0,
        padding: 0,
        fontSize: 12,
    },
    attributeTableTD: {
        overflow: "hidden",
        padding: 2,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    attributeTableTH: {
        alignItems: "center",
        boxSizing: "border-box",
        display: "inlineFlex",
        margin: "0 auto",
        overflow: "hidden",
        position: "relative",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}

export default styles
