export default {
    filterField: {
        marginLeft: "6px",
        marginTop: "6px",
        width: "50%",
    },
    filterResult: {
        float: "right",
        marginRight: "6px",
        marginTop: "6px",
    },
}
