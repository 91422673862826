// @flow
import * as React from "react"
import TokenNavLink from "./token_nav_link"
import MapToolbarStyles from "@owsi/catena/er2_styles/map_toolbar_item"

type Props = {
    idx: number,
    item: {
        active: boolean,
        icon: { default: string, active?: string },
        id: string,
        link: string,
        name: string,
    },
    theme: MapToolbarStyles,
    onClick: Function,
}

function generateClassNames(props) {
    return `${props.theme.mapToolbarItem} ${
        props.item.active ? props.theme.mapToolbarActive : ""
    }`
}

// Displays a button or link that will interact with the map container
function MapToolbarItem(props: Props) {
    const classNames = generateClassNames(props)
    let iconClass = ""
    if (!props.item.content) {
        iconClass =
            props.item.active && props.item.icon.active
                ? props.item.icon.active
                : props.item.icon.default
    }

    // Returns true if the provided string is a Font Awesome or Mapskin icon
    const isIconClass = (str) =>
        str &&
        typeof str === "string" &&
        ["fa", "ms"].includes(str.substr(0, 2))

    const getIcon = () =>
        isIconClass(iconClass) ? (
            <i className={iconClass} aria-hidden="true" />
        ) : (
            iconClass
        )

    const getContent = () => {
        return <span>{getIcon()}</span>
    }

    return (
        <div title={props.item.name}>
            {props.item.link ? (
                <TokenNavLink
                    className={classNames}
                    role="button"
                    tabIndex={props.idx}
                    to={props.item.link}
                >
                    {getContent()}
                </TokenNavLink>
            ) : (
                <div
                    className={classNames}
                    role="button"
                    tabIndex={props.idx}
                    onClick={(evt) => {
                        props.onClick(props.item.id, evt)
                    }}
                >
                    {getContent()}
                </div>
            )}
        </div>
    )
}

export default MapToolbarItem
