import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorMessage } from "./ErrorMessage"

export type ContentError = (
    error: Error,
    info: { componentStack: string }
) => void

export const ContentModule: React.FC<{
    mod: any
    staticData: any
    onError: ContentError
}> = ({ mod, staticData, onError }) => {
    const [hybridData, setHybridData] = React.useState<any>(null)

    React.useEffect(() => {
        let current = true
        if (mod.getHybridData) {
            load()
        } else {
            setHybridData({})
        }

        return () => {
            current = false
        }

        async function load() {
            const loaded = await mod.getHybridData(staticData)

            if (current) {
                setHybridData(loaded)
            }
        }
    }, [mod])

    if (!hybridData) {
        return null
    }

    const finalProps = { ...staticData, ...hybridData }
    const Content = mod.default

    return (
        <ErrorBoundary onError={onError} FallbackComponent={ErrorMessage}>
            <Content {...finalProps} />
        </ErrorBoundary>
    )
}
