// @flow
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import TextField from "@material-ui/core/TextField"
import * as ids from "../../../../er2_ui/constants/ids"
import type { MapsourcesType, UserLayerType } from "../../reducers/mapsources"

const styles = {
    muiFormControl: {
        flexGrow: 1,
        margin: "2%",
        width: "80%",
    },
}

type PropsType = {
    classes: styles,
    layer: UserLayerType,
    mapsources: MapsourcesType,
    onAccept: Function,
    onClose: Function,
    open: boolean,
}

type StateType = {
    joinField: string,
    joinFields: string[],
    joinLayer: string,
    joinLayers: string[],
    joinLayerName: string,
    sourceField: string,
    sourceFields: string[],
}

class JoinTable extends React.Component<PropsType, StateType> {
    constructor(props) {
        super(props)

        const layers = []
        if (props.mapsources) {
            props.mapsources.mapsources.forEach((ms) => {
                ms.layers.forEach((l) => {
                    if (l.layer_type === "Table") {
                        layers.push(l)
                    }
                })
            })
        }
        this.state = {
            joinField: "",
            joinFields: [],
            joinLayer: "",
            joinLayers: layers,
            joinLayerName: props.layer.name,
            sourceField: "",
            sourceFields: Object.entries(props.layer.schema.properties).map(
                ([k, v]) => k,
            ),
        }
    }

    joinTable = () => {
        this.props.onAccept(
            this.state.sourceField,
            this.state.joinLayer,
            this.state.joinField,
            this.state.joinLayerName,
        )
    }

    onChangeJoinLayerName = (e) => {
        this.setState({ joinLayerName: e.target.value })
    }

    onSelectJoinField = (e) => {
        this.setState({ joinField: e.target.value })
    }

    onSelectJoinLayer = (e) => {
        const joinLayer = this.state.joinLayers.find(
            (l) => l.name === e.target.value,
        )
        this.setState({
            joinFields: Object.entries(joinLayer.schema.properties).map(
                ([k, v]) => k,
            ),
            joinLayer: joinLayer.name,
        })
    }

    onSelectSourceField = (e) => {
        this.setState({ sourceField: e.target.value })
    }

    render() {
        return (
            <Dialog
                aria-labelledby="form-dialog-title"
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <DialogTitle id="form-dialog-title">Join</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The Join operation allows you to append data to the
                        attribute table of this layer from another table or the
                        attribute table of another layer.
                    </DialogContentText>
                    <FormControl className={this.props.classes.muiFormControl}>
                        <InputLabel htmlFor={"select-source-field"}>
                            Select field to make the join on
                        </InputLabel>
                        <Select
                            inputProps={{
                                id: "select-source-field",
                            }}
                            onChange={this.onSelectSourceField}
                            value={this.state.sourceField}
                        >
                            {this.state.sourceFields.map((field) => (
                                <MenuItem key={field} value={field}>
                                    {field}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={this.props.classes.muiFormControl}>
                        <InputLabel htmlFor={"select-join-layer"}>
                            Select table to join
                        </InputLabel>
                        <Select
                            inputProps={{
                                id: "select-join-layer",
                            }}
                            onChange={this.onSelectJoinLayer}
                            value={this.state.joinLayer}
                        >
                            {this.state.joinLayers.map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={this.props.classes.muiFormControl}>
                        <InputLabel htmlFor={"select-join-field"}>
                            Select field in table to join on
                        </InputLabel>
                        <Select
                            inputProps={{
                                id: "select-join-field",
                            }}
                            onChange={this.onSelectJoinField}
                            value={this.state.joinField}
                        >
                            {this.state.joinFields.map((field) => (
                                <MenuItem key={field} value={field}>
                                    {field}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={this.props.classes.muiFormControl}>
                        <TextField
                            value={this.state.joinLayerName}
                            fullWidth
                            label="Joined layer name"
                            margin="normal"
                            onChange={this.onChangeJoinLayerName}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.joinTable}>Accept</Button>
                    <Button onClick={this.props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(JoinTable)
