import React from "react"
import ReactDOM from "react-dom"
import SSRContextProvider from "./components/SSRContextProvider"
import App from "./components/App"

ReactDOM.render(
    <SSRContextProvider>
        <App />
    </SSRContextProvider>,
    document.getElementById("root")
)
