import { storyNameFromExport, toId } from "@componentdriven/csf"
import addons, { Channel, Loadable } from "@storybook/addons"
import { defaultDecorateStory, StoryStore } from "@storybook/client-api"

import StoryRenderer from "./StoryRenderer"
import type {
    Args,
    BaseStory,
    Gathering,
    KindMeta,
    Parameters,
    DecoratorFunction,
    StoryFn,
    DecorateStoryFunction,
} from "./_types"

type EagerFrom = Record<string, { default: any; [key: string]: any }>
type Frommable = EagerFrom | Loadable

const quickId = () => btoa(Math.random().toString()).substr(5, 5) // the first 5 characters of a pseudo-random base64 string

const ensureAddonsChannel = () => {
    let channel
    try {
        channel = addons.getChannel()
    } catch (e) {
        channel = new Channel()
        addons.setChannel(channel)
    }

    return channel
}

const createStore = () => {
    const channel = ensureAddonsChannel()
    const store = new StoryStore({ channel })

    return store
}

export function createGathering<A extends Args = Args>(
    config: {
        from?: Frommable
        global?: {
            scope?: A
            decorators?: DecoratorFunction[]
            parameters?: Parameters
        }
    },
    settings: { applyDecorators?: DecorateStoryFunction } = {}
): Gathering {
    const { from, global = {} } = config
    const {
        applyDecorators = defaultDecorateStory as DecorateStoryFunction,
    } = settings

    const {
        scope: globals,
        parameters: globalParameters,
        decorators: globalDecorators,
    } = global

    if (typeof from !== "object" && typeof from !== "function") {
        throw new Error(
            "The 'from' option must be a map of imported modules or a require context"
        )
    }

    if (Object.keys(from).length < 1) {
        console.warn(`
            No kinds were found in a gathering's 'from' option.
            
            This might be because your glob doesn't match any files, or there is no loader registered for the extension.
        `)
        throw new Error("Empty source")
    }

    const store = createStore()

    // Include Gathering globals
    if (globals) {
        store.updateGlobals(globals)
    }

    if (globalDecorators || globalParameters) {
        store.addGlobalMetadata({
            decorators: globalDecorators,
            parameters: globalParameters,
        })
    }

    gatherStories(store, from, {
        applyDecorators,
    })

    const gatheringId = quickId()
    const sortedStories = store.sortedStories()

    const gathering: Gathering = {
        get store() {
            return store
        },
        get id() {
            return gatheringId
        },
        get kinds() {
            return store._kinds
        },
        get stories() {
            return sortedStories
        },
        provideComponent(kind) {
            return ({ variant, args }) => {
                const story = store.getRawStory(
                    kind,
                    storyNameFromExport(variant)
                )
                return <StoryRenderer story={story} args={args} />
            }
        },
        provideAllComponents(kind) {
            const kindStories = store.getStoriesForKind(kind)

            return kindStories.map((story) => ({ args }) => (
                <StoryRenderer story={story} args={args} />
            ))
        },
    }

    return gathering
}

function gatherStories(store: StoryStore, from: Frommable, storyOpts?: any) {
    const storySources = getStorySources(from)

    for (const source of storySources) {
        const { id, meta, stories } = source

        const fileName = process.env.PROD ? quickId() : `${id}`

        if (!meta) {
            throw new Error(`No default export for story: ${fileName}`)
        }

        const kind = meta.title

        store.addKindMetadata(kind, meta)

        for (const [storyKey, story] of Object.entries(stories)) {
            const storyName = story.storyName || storyNameFromExport(storyKey)

            const id = toId(kind, storyName)

            store.addStory(
                {
                    id,
                    kind,
                    name: storyName,
                    storyFn: story as StoryFn,
                    parameters: {
                        fileName,
                        args: story.args,
                        ...story.parameters,
                    },
                    decorators: story.decorators,
                },
                storyOpts
            )
        }
    }
}

type Source = {
    id: string
    meta: KindMeta
    stories: { [key: string]: BaseStory }
}

function getStorySources(from: Frommable): Source[] {
    if (typeof from === "function") {
        if (from.keys && from.resolve) {
            return gatherRequireContext(from)
        }

        return gatherEagerObject(from())
    }

    return gatherEagerObject(from)

    function gatherRequireContext(context: Loadable): Source[] {
        const sources = [] as Source[]

        for (const key of context.keys()) {
            const { default: meta, id = from.resolve(key), ...rest } = from(key)

            sources.push({
                id,
                meta,
                stories: rest,
            })
        }

        return sources
    }

    function gatherEagerObject(obj: EagerFrom): Source[] {
        const sources = [] as Source[]

        for (const key of Object.keys(obj)) {
            const { default: meta, id = key, ...rest } = obj[key]

            sources.push({
                id,
                meta,
                stories: rest,
            })
        }

        return sources
    }
}

export default createGathering
