import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Speed as SpeedIcon } from "@material-ui/icons"
import { ReactComponent as MRRPIcon } from "assets/ppt_logo_sm.svg"

export const Docs = <FontAwesomeIcon icon="home" />

// export const Geo = <FontAwesomeIcon icon="map-marked-alt" />
export const Geo = <FontAwesomeIcon icon="globe-americas" />

export const Hydro = <FontAwesomeIcon icon="tint" />

export const Mitigation = <FontAwesomeIcon icon="hard-hat" />

export const Tools = <FontAwesomeIcon icon="tools" />

export const Lab = <FontAwesomeIcon icon="flask" />

export const Mail = <FontAwesomeIcon icon="envelope" />

// export const Overview = <SpeedIcon />
export const Overview = <FontAwesomeIcon icon={"eye"} />

export const Search = <FontAwesomeIcon icon="search" title="Search" />

const imageStyle = {
    width: 24,
    height: 24,
}

const smallImageStyle = {
    width: 24,
    height: 24,
    padding: 4,
}

const bigImageStyle = {
    width: 30,
    height: 30,
}

const activeImageStyle = {
    ...imageStyle,
    filter: "brightness(0)",
}

export const Fish = (
    <img
        alt="Pallid Sturgeon Icon"
        src={require("assets/icons/ps_large_hollow_5_grey.png").default}
        style={imageStyle}
    />
)

export const BigFish = (
    <img
        alt="Pallid Sturgeon Icon"
        src={require("assets/icons/ps_large_hollow_5_grey.png").default}
        style={bigImageStyle}
    />
)

export const SmallFish = (
    <img
        alt={"Pallid Sturgeon"}
        src={require("assets/icons/ps_large_hollow_5_grey.png").default}
        style={smallImageStyle}
    />
)

export const FishActive = (
    <img
        alt="Pallid Sturgeon Icon"
        src={require("assets/icons/ps_large_hollow_5_grey.png").default}
        style={activeImageStyle}
    />
)

export const Birds = (
    <img
        alt="Least Tern Icon"
        src={require("assets/icons/Piping_Plover_5_grey.png").default}
        style={imageStyle}
    />
)

export const SmallBirds = (
    <img
        alt="Piping Plover & Least Tern"
        src={require("assets/icons/Piping_Plover_5_grey.png").default}
        style={smallImageStyle}
    />
)

export const BigBirds = (
    <img
        alt="Least Tern Icon"
        src={require("assets/icons/Piping_Plover_5_grey.png").default}
        style={bigImageStyle}
    />
)

export const BirdsActive = (
    <img
        alt="Least Tern Icon"
        src={require("assets/icons/Piping_Plover_5_grey.png").default}
        style={activeImageStyle}
    />
)

export const MRRP = (
    <MRRPIcon
        title="Missouri River Recovery Program Logo"
        style={{ opacity: 0.6 }}
    />
)

export const SmallRiver = (
    <img
        alt="Physical Data"
        src={require("assets/icons/waves.png").default}
        style={smallImageStyle}
    />
)

export const River = (
    <img
        alt="Physical Data"
        src={require("assets/icons/waves.png").default}
        style={bigImageStyle}
    />
)
