// @flow
import numeral from "numeral"
import React from "react"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import RenderNotifications from "@owsi/catena/er2_ui/components/tool_notifications"
import {
    getRasterLayers,
    getUniqueLayerName,
    splitExt,
} from "@owsi/catena/er2_map_userlayers/js/utils"
import type { MapsourcesType } from "@owsi/catena/er2_map_userlayers/js/reducers/mapsources"
import * as ids from "../constants/ids"
import * as actionTypes from "../constants/action_types"
import { getActiveTool } from "../reducers/geo_bar"
import { validationError } from "../actions/form"

type Props = {
    actions: {
        onChangeInput: Function,
        onGeoBarFetchClassifications: Function,
        onGeoBarFetchErrorRendered: Function,
        onGeoBarResultRendered: Function,
        onGeoBarSubmitReclassify: Function,
        onGeoBarSelectLayer: Function,
    },
    mapsources: MapsourcesType,
    theme: object,
}

function isLayerSelected(tool) {
    return tool.targetLayer.length > 0
}

function isValid(tool) {
    return tool.targetLayer.length > 0 && !!tool.classifications
}

function isValidDistance(distance: number, dispatch, getState) {
    const castDistance = parseInt(distance)
    if (castDistance === "NaN" || castDistance < 0) {
        const error = {
            id: ids.GEO_BAR_RECLASS,
            type: actionTypes.VALIDATION_ERROR_INVALID_FIELD,
            message: "Please provide a valid number of classifications",
        }
        dispatch(validationError(error))
        return false
    }
    return true
}

function formatClassifications(rawClassifications) {
    const result = []
    rawClassifications.forEach((cls, idx) => {
        if (idx < rawClassifications.length - 1) {
            result.push([
                `${numeral(cls).format("0,0.0")} - ${numeral(
                    rawClassifications[idx + 1],
                ).format("0,0.0")}`,
                idx + 1,
            ])
        }
    })
    return result
}

function GeoBarReclass(props: Props) {
    const tool = getActiveTool(props)
    const canFetchClassifications =
        isLayerSelected(tool) &&
        tool.classificationType.length &&
        tool.classificationCount > 0
    const layers = getRasterLayers(props.mapsources)
    const { theme } = props

    const [classifications, setClassifications] = React.useState([])
    const [outputName, setOutputName] = React.useState("")

    const onFocus = (e) => e.target.select()

    React.useEffect(() => {
        if (tool.classifications) {
            setClassifications(formatClassifications(tool.classifications))
        }
    }, [tool.classifications])

    const setLayer = (e) => {
        const layer = layers.find((l) => l.name === e.target.value)
        props.actions.onGeoBarSelectLayer(true, layer.name, tool, {
            kind: "targetLayer",
        })

        // Create an output name based on the input
        if (!outputName) {
            const [basename, ext] = splitExt(layer.name)
            const addExt = ext || ".tif"
            const rasterOutputName =
                getUniqueLayerName(
                    props.mapsources,
                    `${basename} (reclassed)`,
                ) + addExt
            setOutputName(rasterOutputName)
        }
    }

    function reclassify() {
        const [basename, ext] = splitExt(outputName)
        const reclassOutputName = getUniqueLayerName(props.mapsources, basename)
        let theExt = ext
        if (!ext) theExt = ".tif"
        props.actions.onGeoBarSubmitReclassify(
            tool.targetLayer,
            classifications,
            reclassOutputName + theExt,
        )
    }

    function fetchClassifictions() {
        const targetLayer = layers.find((lyr) => lyr.name === tool.targetLayer)
        props.actions.onGeoBarFetchClassifications(
            targetLayer,
            tool.classificationType,
            tool.classificationCount,
        )
    }

    function renderButtons() {
        return (
            <React.Fragment>
                <div className={theme.toolRow}>
                    {tool.classifications && (
                        <Button
                            color={"primary"}
                            disabled={!isValid(tool)}
                            onClick={() => reclassify(props)}
                            variant={"contained"}
                        >
                            {!tool.isFetching && <span>Reclassify</span>}
                            {tool.isFetching && (
                                <span>
                                    Reclassifying&nbsp;
                                    <i className="fas fa-spinner fa-spin" />
                                </span>
                            )}
                        </Button>
                    )}
                </div>
            </React.Fragment>
        )
    }

    function renderClassificationsForm() {
        if (isLayerSelected(tool)) {
            return (
                <React.Fragment>
                    <div className={theme.toolRow}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor={ids.GEO_BAR_RECLASS_TYPE}>
                                Classify By
                            </InputLabel>
                            <Select
                                value={tool.classificationType}
                                inputProps={{
                                    id: ids.GEO_BAR_RECLASS_TYPE,
                                }}
                                onChange={(evt) =>
                                    props.actions.onChangeInput(
                                        evt.target.value,
                                        ids.GEO_BAR_RECLASS_TYPE,
                                    )
                                }
                            >
                                <MenuItem
                                    key={ids.GEO_BAR_EQUAL_DISTANCE}
                                    value={ids.GEO_BAR_EQUAL_DISTANCE}
                                >
                                    Equal Distance
                                </MenuItem>
                                <MenuItem
                                    key={ids.GEO_BAR_NATURAL_BREAKS}
                                    value={ids.GEO_BAR_NATURAL_BREAKS}
                                >
                                    Natural Breaks
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={theme.toolRow}>
                        <FormControl fullWidth>
                            <TextField
                                id={ids.GEO_BAR_RECLASS_COUNT}
                                label="Classifications"
                                value={tool.classificationCount}
                                onChange={(evt) =>
                                    props.actions.onChangeInput(
                                        evt.target.value,
                                        ids.GEO_BAR_RECLASS_COUNT,
                                        isValidDistance,
                                    )
                                }
                                type="number"
                                margin="normal"
                            />
                        </FormControl>
                    </div>
                    {isLayerSelected(tool) && (
                        <div className={theme.toolRow}>
                            <Button
                                color={"primary"}
                                disabled={!canFetchClassifications}
                                onClick={() => fetchClassifictions(props)}
                                variant={"contained"}
                            >
                                {!tool.isFetchingClassifications && (
                                    <span>Fetch Classifications</span>
                                )}
                                {tool.isFetchingClassifications && (
                                    <span>
                                        <i className="fas fa-spinner fa-spin" />
                                        &nbsp;Fetching...
                                    </span>
                                )}
                            </Button>
                        </div>
                    )}
                </React.Fragment>
            )
        }
        return null
    }

    const onChangeRange = (irow, val) => {
        classifications[irow][0] = val
        setClassifications(classifications)
    }

    const onChangeClassification = (irow, val) => {
        classifications[irow][1] = val
        setClassifications(classifications)
    }

    function renderClassifications() {
        return (
            <table className={theme.geobarTable} style={{ marginTop: 20 }}>
                <tbody>
                    <tr className={theme.geobarTableHeaderRow}>
                        <th className={theme.geobarTableHeaderCell}>Range</th>
                        <th className={theme.geobarTableHeaderCell}>
                            New Value
                        </th>
                    </tr>
                    {classifications.map((cls, idx) => (
                        <tr key={cls[0]} className={theme.tableRow}>
                            <td className={theme.geobarTableCell}>
                                <TextField
                                    defaultValue={cls[0]}
                                    onChange={(e) =>
                                        onChangeRange(idx, e.target.value)
                                    }
                                    onFocus={(e) => e.target.select()}
                                />
                            </td>
                            <td className={theme.geobarTableCell}>
                                <TextField
                                    defaultValue={cls[1]}
                                    onChange={(e) =>
                                        onChangeClassification(
                                            idx,
                                            e.target.value,
                                        )
                                    }
                                    onFocus={(e) => e.target.select()}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    function renderTool() {
        return (
            <React.Fragment>
                <div className={theme.toolRow}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor={ids.GEO_BAR_TARGET_LAYER}>
                            Target Layer
                        </InputLabel>
                        <Select
                            value={tool.targetLayer}
                            inputProps={{
                                id: ids.GEO_BAR_TARGET_LAYER,
                            }}
                            onChange={setLayer}
                        >
                            {layers.map((layer) => (
                                <MenuItem key={layer.name} value={layer.name}>
                                    {layer.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {renderClassificationsForm()}
                {classifications.length > 0 && renderClassifications()}
                <div className={theme.toolRow}>
                    <TextField
                        fullWidth
                        label="Output layer name"
                        value={outputName}
                        onChange={(evt) => setOutputName(evt.target.value)}
                        onFocus={onFocus}
                        margin="normal"
                    />
                </div>
                {renderButtons()}
            </React.Fragment>
        )
    }

    return (
        <div className={theme.geoBarCtr}>
            {renderTool()}
            <RenderNotifications {...props} />
        </div>
    )
}

export default GeoBarReclass
