import * as types from "constants/action_types"
import {
    onFetchMapState,
    setTabbedPanelWindowState,
} from "@owsi/catena/er2_map_userlayers/js/actions"

const setFetchingState = (isFetching: boolean) => ({
    type: types.SET_FETCHING_STATE,
    isFetching,
})

const setFetchedState = (
    isFetched: boolean,
    state,
    meta?: { version: string }
) => ({ type: types.SET_FETCHED_STATE, isFetched, state, meta })

const updateMeta = (name: string, version: string, user: {}) => ({
    type: types.UPDATE_META,
    name,
    version,
    user,
})

// Fetches server state, e.g. to initialize the application.
export const fetchState =
    (location: { params: {} }) => (dispatch, getState) => {
        let fetchError = false
        dispatch(setFetchingState(true))
        fetch(
            `/${getState().meta.name}/api/v1/state/${
                location.params["token"]
                    ? "?token=" + location.params["token"]
                    : ""
            }`
        )
            .then((response) => {
                fetchError = !response.ok
                return response.json()
            })
            .then((json) => {
                dispatch(setFetchingState(false))
                if (fetchError) {
                    dispatch(setFetchedState(true, {}))
                } else {
                    dispatch(setFetchedState(true, json.state, json.meta))
                    dispatch(setTabbedPanelWindowState("maximized"))
                    dispatch(
                        updateMeta(
                            json.meta.name,
                            json.meta.version,
                            json.meta.user
                        )
                    )

                    if (getState().serverState.useMapserver) {
                        // Set up mapserver to display layer data from __MRRP workspace
                        dispatch(prepareMapState())
                    } else {
                        // Original map data downloader
                        dispatch(onFetchMapState())
                        dispatch(getMapData())
                    }
                }
            })
        fetch(`/${getState().meta.name}/api/v1/images/`)
            .then((response) => response.json())
            .then((json) => {
                dispatch({ type: types.SET_IMAGE, images: json.images })
            })

        // Check for url options
        let search = window.location.search
        let params = new URLSearchParams(search)
        let showCatena = params.get("showcatena") || params.get("showCatena")
        dispatch({
            type: types.SET_SHOW_CATENA,
            showCatena: showCatena === "true",
        })
    }

// Since map state can be slow to set up, break up map state into separate call.
export const prepareMapState = () => (dispatch, getState) => {
    let fetchError = false
    const token = getState().token.value
    const query = token ? `?token=${token}` : ""
    fetch(`/${getState().meta.name}/api/v1/prepare_map_state/${query}`)
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                dispatch(
                    setFetchedState(
                        true,
                        { exception: json.exception },
                        { version: "" }
                    )
                )
            } else {
                // Set initial extent.
                dispatch(
                    onFetchMapState([-13269826, 3212541, -9665107, 7678243])
                )
            }
        })
}

export const getMapData = () => (dispatch, getState) => {
    let fetchError = false
    fetch(`/${getState().meta.name}/api/v1/map/`)
        .then((response) => {
            fetchError = !response.ok
            return response.json()
        })
        .then((json) => {
            if (fetchError) {
                console.log("error")
            } else {
                console.log(json)
                dispatch({
                    type: types.SET_MAP_DATA,
                    map_data: json.map_data,
                })
            }
        })
}

export const updateAppState = (which, fnSaved) => (dispatch, getState) => {
    const appState = {
        [which]: getState()[which],
    }
    fetch(`/${getState().meta.name}/api/v1/state/`, {
        body: JSON.stringify({ appState }),
        method: "PATCH",
    }).then((ret) => {
        if (fnSaved) fnSaved()
    })
}

export const onLogout = () => async (dispatch, getState) => {
    fetch(`/${getState().meta.name}/logout/`, {
        method: "POST",
    }).then((ret) => {
        window.location.pathname = "/login/"
    })
}

export default fetchState
