// @flow
const styles = {
    dialogSelect: {
        width: "100%",
        minWidth: "150px",
    },
    dialogTextArea: {
        width: "100%",
        minWidth: "150px",
    },
    xCloseButton: {
        position: "absolute",
        color: "#FFF",
        // bottom: '0',
        right: "8px",
    },
    radio: {
        margin: "5px",
    },
}

export default styles
