import * as types from "constants/action_types"
import over from "lodash/over"
import { getObservations } from "app/store/actions/overview"

const setFetchStatus = (isSuccessful) => ({
    type: types.SET_BIRD_FETCH_STATUS,
    isSuccessful,
})

const setOverviewData = (output) => ({
    type: types.SET_BIRDS_OVERVIEW_DATA,
    output,
})

const fetchBirdsServiceData = (what) => (dispatch, getState) =>
    fetch(`/${getState().meta.name}/api/v1/bird/`, {
        method: "POST",
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify({
            what,
        }),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                dispatch(setFetchStatus(false))
                return {}
            }

            dispatch(setFetchStatus(true))
            return json
        })

const getOverview = () => (dispatch, getState) => {
    const miniThunks = over(
        fetchBirdsServiceData("Overview1"),
        fetchBirdsServiceData("Overview2"),
        fetchBirdsServiceData("Overview3"),
        fetchBirdsServiceData("Overview4")
    )

    return Promise.all(miniThunks(dispatch, getState)).then((jsons) => {
        const combinedData = Object.assign({}, ...jsons)
        dispatch(setOverviewData(combinedData))
    })
}

const setModelProjectionsData = (output) => ({
    type: types.SET_BIRDS_MODELPROJECTIONS_DATA,
    output,
})
export const getModelProjections = () => (dispatch, getState) => {
    const miniThunks = over(
        fetchBirdsServiceData("ModelProjections"),
        fetchBirdsServiceData("ModelProjections2")
    )

    return Promise.all(miniThunks(dispatch, getState)).then((jsons) => {
        const combinedData = Object.assign({}, ...jsons)
        dispatch(setModelProjectionsData(combinedData))
    })
}

const setManagementActionsData = (output) => ({
    type: types.SET_BIRDS_MANAGEMENT_DATA,
    output,
})
const getManagementActions = () => (dispatch, getState) => {
    const miniThunks = over(
        fetchBirdsServiceData("Management1"),
        fetchBirdsServiceData("Management2"),
        fetchBirdsServiceData("Management3"),
        fetchBirdsServiceData("Management4"),
        fetchBirdsServiceData("Management5"),
        fetchBirdsServiceData("Management6")
    )

    return Promise.all(miniThunks(dispatch, getState)).then((jsons) => {
        const combinedData = Object.assign({}, ...jsons)
        dispatch(setManagementActionsData(combinedData))
    })
}

const setStatusAndTrendData = (output) => ({
    type: types.SET_BIRDS_SNT_DATA,
    output,
})

export const getStatusAndTrend = () => (dispatch, getState) => {
    const miniThunks = over(
        fetchBirdsServiceData("Status1"),
        fetchBirdsServiceData("Status2"),
        fetchBirdsServiceData("Status3"),
        fetchBirdsServiceData("Status4"),
        fetchBirdsServiceData("Status5"),
        fetchBirdsServiceData("Status6"),
        fetchBirdsServiceData("MitigationAuthorityLands"),
        fetchBirdsServiceData("MitigationAcquisition"),
        fetchBirdsServiceData("MitigationBiennial")
    )

    return Promise.all(miniThunks(dispatch, getState)).then((jsons) => {
        const combinedData = Object.assign({}, ...jsons)
        dispatch(setStatusAndTrendData(combinedData))
    })
}

export const onBirdsCBStepChange =
    (activeStep: number) => (dispatch, getState) => {
        dispatch({ type: types.SET_BIRDS_ACTIVESTEP, activeStep })
        switch (activeStep) {
            case 0:
                dispatch(getOverview())
                dispatch(getStatusAndTrend())
                return null
            case 1:
                dispatch(getModelProjections())
                dispatch(getObservations())
                return null
            case 2:
                dispatch(getStatusAndTrend())
                return null
            case 3:
                dispatch(getManagementActions())
                return null
            case 4:
                dispatch(getModelProjections())
                return null
            case 5:
                return null
            case 6:
                dispatch(getStatusAndTrend())
                return null
            default:
                return null
        }
    }
