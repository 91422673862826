import React from "react"

import Helmet from "react-helmet"

type HeadProps = {
    title?: string
    description?: string
    browserColor?: string
    helmetProps?: object
}

export const Head: React.FC<HeadProps> = ({
    children,
    title,
    description,
    browserColor,
    helmetProps,
}) => {
    return (
        <Helmet {...helmetProps}>
            {title ? <title>{title}</title> : null}
            {description ? (
                <meta name="description" content={description} />
            ) : null}
            {browserColor ? (
                <meta name="theme-color" content={browserColor} />
            ) : null}
            {children}
        </Helmet>
    )
}
