// @flow

// note: props.colors is a new prop, so I kept the fallbacks.
const header = {
    header: {
        backgroundColor: (props) =>
            props.colors?.primaryOneDarken5 || props.primaryOneDarken5,
        boxShadow: "1px 0 2px 1px rgba(0, 0, 0, 0.44)",
        zIndex: 403,
        "-webkitTransform": "translate3d(0,0,100px)",
        color: (props) => props.colors?.white || props.white,
        gridColumnStart: "left",
        gridColumnEnd: "right",
        gridRowStart: "top",
        gridRowEnd: "subHeader",
        paddingRight: "15px",
        paddingLeft: "15px",
    },
    headerCtr: {
        paddingRight: "15px",
        paddingLeft: "15px",
    },
    headerLink: {
        color: (props) => props.colors?.white || props.white,
        textDecoration: "none",
    },
    headerSection: {
        color: (props) => props.colors?.textColor || props.textColor,
        fontWeight: "bold",
        marginBottom: "0px",
        marginTop: "10px",
        paddingLeft: "15px",
    },
}
export default header
