export const ATTRIBUTE_TABLE_SHOW = "USER_LAYER_ATTRIBUTES_SHOW"
export const ATTRIBUTE_TABLE_HIDE = "USER_LAYER_ATTRIBUTES_HIDE"
export const ATTRIBUTE_TABLE_OPERATION = "ATTRIBUTE_TABLE_OPERATION"
export const CHANGE_BASELAYER = "CHANGE_BASELAYER"
export const CHANGE_BASELAYER_OPACITY = "CHANGE_BASELAYER_OPACITY"
export const CHANGE_MAPSCALE = "CHANGE_MAPSCALE"
export const MAP_DISABLE_ONCHANGE_CALLBACK = "MAP_DISABLE_ONCHANGE_CALLBACK"
export const LOG_APPEND = "LOG_APPEND"
export const LOG_IMPORTANT_HANDLED = "LOG_IMPORTANT_HANDLED"
export const LOG_SET = "LOG_SET"
export const MAPFILE_UPDATE_HANDLED = "MAPFILE_UPDATE_HANDLED"
export const PUBLIC_DATA_ADD_LEGEND_DATA = "PUBLIC_DATA_ADD_LEGEND_DATA"
export const PUBLIC_DATA_ADD_SERVICE_DATA = "PUBLIC_DATA_ADD_SERVICE_DATA"
export const PUBLIC_DATA_DELETE = "PUBLIC_DATA_DELETE"
export const PUBLIC_DATA_PROPS_CHANGE = "PUBLIC_DATA_PROPS_CHANGE"
export const RESIZE_ANALYSIS_PANEL = "RESIZE_ANALYSIS_PANEL"
export const RESIZE_CONTEXT_PANEL = "RESIZE_CONTEXT_PANEL"
export const IDENTIFY_FEATURES_START = "IDENTIFY_FEATURES_START"
export const IDENTIFY_FEATURES_FETCHED = "IDENTIFY_FEATURES_FETCHED"
export const IDENTIFY_FEATURES_HANDLED = "IDENTIFY_FEATURES_HANDLED"
export const MAP_ERROR_CLEAR = "MAP/CLEAR_ERROR"
export const MAP_ERROR_SET = "MAP/SET_ERROR"
export const MAP_MESSAGE_CLEAR = "MAP/CLEAR_MESSAGE"
export const MAP_MESSAGE_SET = "MAP/SET_MESSAGE"
export const SET_FILTERS = "SET_FILTERS"
export const SET_GROUPING = "SET_GROUPING"
export const SET_GROUP_ORDERING = "SET_GROUP_ORDERING"
export const SET_IS_IDENTIFYING = "SET_IS_IDENTIFYING"
export const SET_LAYER_ORDERING = "SET_LAYER_ORDERING"
export const SET_MAP_EXTENT = "SET_MAP_EXTENT"
export const SET_MAP_EXTENT_HANDLED = "SET_MAP_EXTENT_HANDLED"
export const SET_MAP_VIEW_HANDLED = "SET_MAP_VIEW_HANDLED"
export const SET_FETCHED_STATE = "STATE/SET_FETCHED"
export const SET_QUERYING = "SET_QUERYING"
export const SHOW_MAP_TOOLBAR = "SHOW_MAP_TOOLBAR"
export const TABBED_PANEL_KEY = "TABBED_PANEL_KEY"
export const TABBED_PANEL_WINDOW_STATE = "TABBED_PANEL_WINDOW_STATE"
export const UPDATE_FIELD_STATS = "USER_LAYERS/UPDATE_FIELD_STATS"
export const UPDATE_MAP_STATE = "UPDATE_MAP_STATE"
export const UPDATE_MAPSOURCE_LAYERS = "UPDATE_MAPSOURCE_LAYERS"
export const UPDATE_MAPSOURCE_ORDER = "UPDATE_MAPSOURCE_ORDER"
export const UPDATE_MAP_VIEW = "UPDATE_MAP_VIEW"
export const USER_LAYERS_ADDING = "USER_LAYERS_ADDING"
export const USER_LAYERS_SET = "USER_LAYERS_SET"
export const USER_LAYER_DELETE = "USER_LAYER_DELETE"
export const USER_LAYERS_DELETE = "USER_LAYERS_DELETE"
export const USER_LAYERS_SORT = "USER_LAYERS_SORT"
export const USER_LAYER_UPDATE = "USER_LAYER_UPDATE"
export const USER_LAYERS_UPDATE = "USER_LAYERS_UPDATE"
export const USER_LAYERS_BATCH_UPDATE = "USER_LAYERS_BATCH_UPDATE"
export const USER_LAYERS_ZOOM = "USER_LAYERS_ZOOM"
export const USER_LAYER_FETCH_GEOJSON = "USER_LAYER_FETCH_GEOJSON"
export const USER_LAYER_FETCHING_GEOJSON = "USER_LAYER_FETCHING_GEOJSON"
export const USER_LAYER_SET_GEOJSON = "USER_LAYER_SET_GEOJSON"
export const USER_MAPSOURCE_LOADING = "USER_MAPSOURCE_LOADING"
