import type { SeparatorOptions } from "@componentdriven/csf"

import React from "react"

import { parseKind } from "@componentdriven/csf"
import type { StoryContext } from "@storybook/addons"
export { makeDecorator } from "@storybook/addons"

const kindParser: SeparatorOptions = {
    groupSeparator: "/",
    rootSeparator: /\||\//,
}

/**
 * Returns a representation of kind's title split at | and /
 */
export const parseFolioKind = (kind: string) => parseKind(kind, kindParser)

/**
 * Create a React.lazy component that inherits the entire context
 */
export const lazy = (factory: () => any) => {
    const LazyStory = React.lazy(factory)
    return (_: any, context: StoryContext) => <LazyStory {...context} />
}
