// @flow
import * as React from "react"
import { withRouter } from "react-router-dom"
import { nullFunc } from "@owsi/catena/er2_map_userlayers/js/utils"
import Theme from "@owsi/catena/er2_styles"
import type { LocationType } from "@owsi/catena/er2_map_userlayers/js/reducers"
import TokenNavLink from "./token_nav_link"

export type ContextBarItemType = {
    id: string,
    enabled: boolean | Function,
    link: string,
    name: string,
    position?: "bottom" | "spacer",
    title?: string,
}

type Props = {
    idx: number,
    item: ContextBarItemType,
    location: LocationType,
    onClick?: Function,
    render: Function,
    theme: Theme,
    token?: { value: string },
}

/**
 * Returns true if the url matches the item's link.
 *
 * If the item doesn't have a link, check's the item's active status.
 */
export function isActive(location: LocationType, item: ContextBarItemType) {
    return location.pathname === item.link
}

// Represents a button or link in the Context Bar
function ContextBarItem(props: Props) {
    const active = isActive(props.location, props.item)
    const enabled = props.item.enabled !== false
    return (
        <div
            role="button"
            tabIndex={props.idx}
            title={props.item.title}
            onClick={() => props.onClick(props.item.id)}
        >
            {enabled && (
                <TokenNavLink
                    className={
                        active
                            ? props.theme.contextBarItemActive
                            : props.theme.contextBarItem
                    }
                    to={props.item.link}
                    token={props.token}
                >
                    {props.render(props.item)}
                </TokenNavLink>
            )}
            {!enabled && (
                <div className={props.theme.contextBarItemDisabled}>
                    {props.render(props.item)}
                </div>
            )}
        </div>
    )
}

ContextBarItem.defaultProps = {
    isEnabled: true,
    onClick: nullFunc,
    title: "",
    token: undefined,
}

export default withRouter(ContextBarItem)
